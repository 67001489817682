import React from 'react';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Typography,
} from '@mui/material';
const user = {
	avatar: '/static/images/avatars/avatar_6.png',
	city: 'Los Angeles',
	country: 'USA',
	jobTitle: 'Senior Developer',
	name: 'Katarina Smith',
	timezone: 'GTM-7',
};
interface AccountProfileProps {
	user: any;
}
const AccountProfile = (props: AccountProfileProps) => {
	return (
		<Card
			sx={{
				backgroundColor: 'white',
			}}
		>
			<CardContent>
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Avatar
						src={user.avatar}
						sx={{
							height: 64,
							mb: 2,
							width: 64,
						}}
					/>
					<Typography color="black" gutterBottom variant="h4">
						{props.user?.name}
					</Typography>
					<Typography color="black" variant="body2">
						{`${props.user?.province} ${props.user?.region}`}
					</Typography>
					<Typography color="grey" variant="body2">
						{props.user?.id}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default AccountProfile;
