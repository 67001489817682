import request from '../../helpers/myAxios';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../providers/NotifyProvider';
import { useState } from 'react';
import { sessionClear } from '../../helpers/session';
import { useUserDetails } from '../providers/UserDetailsProvider';
import { useAudioRecordingSession } from '../providers/AudioRecordingSessionProvider';
const useRequest = () => {
	const [errors, setErrors] = useState<string>();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const notify = useNotify();
	const { saveUser } = useUserDetails();
	const { resetRecordingSession } = useAudioRecordingSession();
	const req = request() as any;

	const doRequest = async ({ url, method, body, onSuccess, responsType }: any) => {
		try {
			if (!loading) setLoading(true);
			const response: AxiosResponse = await req[method](url, body, responsType);
			setLoading(false);
			if (onSuccess) {
				onSuccess(response.data);
			}
			return response.data;
		} catch (error: any) {
			setLoading(false);
			if (error.response?.status === 403) {
				notify.error('Votre session a expirée.');
				resetRecordingSession();
				saveUser(undefined);
				sessionClear();
				window.location.href = '/login';
			} else if (error.response?.status === 409) {
				setErrors(
					'Ce compte existe déjà. Allez à mot de passe oublié pour réinitialiser votre mot de passe.'
				);
			} else if (error.code && error.code === 'ERR_NETWORK') {
				setErrors('Impossible de se connecter au serveur.');
			} else {
				setErrors(error.message);
			}
		}
	};

	return { doRequest, errors, loading };
};

export default useRequest;
