import {
	Dialog,
	DialogTitle,
	Grid,
	Typography,
	Select,
	MenuItem,
	DialogActions,
	Button,
	Box,
	IconButton,
	InputBase,
	Paper,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAudioRecorder from '../../hooks/useAudioRecorder';
import VoiceMeter from './VoiceMeter';
import useRequest from '../../hooks/useRequest';
import { useAudioRecordingSession } from '../../providers/AudioRecordingSessionProvider';
import { useUserDetails } from '../../providers/UserDetailsProvider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNotify } from '../../providers/NotifyProvider';
import { useCopyToClipboard } from 'usehooks-ts';

import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';

type Props = {
	open: boolean;
	onSelectMicrophone: (recordingDevice: MediaDeviceInfo | null) => void;
};

const MicrophonePickerDialog = (props: Props) => {
	const navigate = useNavigate();
	const { doRequest, errors } = useRequest();
	const userSession = useUserDetails();
	const notify = useNotify();
	const { startRecording, stopRecording, recordingTime, recordingDevices, isRecording, previewAudioStream, isMicInfoLoading } =
		useAudioRecorder();

	const { resetRecordingSession } = useAudioRecordingSession();

	const [value, copy] = useCopyToClipboard();

	const [previewRecordingDeviceId, setPreviewRecordingDeviceId] = React.useState<string>('');
	const handleLogout = async () => {
		await doRequest({
			url: '/auth/logout',
			method: 'get',
			body: null,
			onSuccess: null,
		});
		resetRecordingSession();
		userSession.saveUser(undefined);
		navigate('/login');
	};

	const handleNext = () => {
		const recordingDevice = recordingDevices.find((item) => item.deviceId === previewRecordingDeviceId);
		props.onSelectMicrophone(recordingDevice ? recordingDevice : null);
	};

	React.useEffect(() => {
		try {
			if (previewRecordingDeviceId === '' && recordingDevices.find((item) => item.deviceId === 'default'))
				setPreviewRecordingDeviceId('default');
		} catch (error) {}
	}, [recordingDevices]);

	React.useEffect(() => {
		try {
			if (props.open) startRecording(previewRecordingDeviceId);
			else stopRecording();
		} catch (error) {}
	}, [props.open]);

	React.useEffect(() => {
		try {
			if (isRecording) stopRecording();
		} catch (error) {}
	}, [previewRecordingDeviceId]);

	React.useEffect(() => {
		if (props.open && !isRecording) startRecording(previewRecordingDeviceId);
	}, [isRecording]);

	const micsDetected = recordingDevices.length > 0;

	return (
		<Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
			<DialogTitle style={{ backgroundColor: 'black', textTransform:'uppercase', fontWeight: 900, fontFamily: 'Anton', paddingLeft:'20px',}}>Périphérique d'enregistrement</DialogTitle>
			{isMicInfoLoading ? (
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 2, backgroundColor: '#EDEAE6', color:'black', }}>
					<Typography variant="body1">Chargement en cours...</Typography>
				</Box>
			) : (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
						padding: 2,
						backgroundColor: '#EDEAE6',
						color:'black',
						fontFamily: 'Baskerville',
					}}
				>
					{micsDetected ? (
						<>
							<Box sx={{ mb: 2 }}>
								<Typography variant="body1">
									<LooksOneIcon sx={{width:'30px', height:'30px',}} />
									<Box sx={{mt: -4, ml: 4,}}>Dans le haut de votre navigateur, <span style={{fontWeight: 600,}}>autoriser l'accès</span> au micophone.
									</Box>
								</Typography>
							</Box>
							<Box sx={{ mb: 1 }}>
								<Typography variant="body1">
									<LooksTwoIcon sx={{width:'30px', height:'30px',}} />
									<Box sx={{mt: -4, ml: 4,}}>
										<span style={{fontWeight: 600,}}>Sélectionnez </span> votre périphérique d'enregistrement.
									</Box>
								</Typography>
							</Box>
							<Box sx={{ width: '80%', mb: 2, ml: 3 }}>
								<Select
									fullWidth
									style={{
										backgroundColor: 'black',
										borderRadius: '30px',
										height: '35px'
									}}
									id="previewRecordingDeviceId"
									value={previewRecordingDeviceId}
									onChange={(e) => setPreviewRecordingDeviceId(e.target.value)}
								>
									{recordingDevices &&
										recordingDevices.map((recordingdevice: MediaDeviceInfo) => (
											<MenuItem key={recordingdevice.deviceId} value={recordingdevice.deviceId}>
												{recordingdevice.label}
											</MenuItem>
										))}
								</Select>
							</Box>
							<Box sx={{ mb: 2 }}>
								<Looks3Icon sx={{width:'30px', height:'30px',}} />
								<Typography variant="body1">
									<Box sx={{mt: -4, ml: 4,}}><span style={{fontWeight: 600,}}>Parlez </span> dans votre microphone.
									</Box>
								</Typography>
							</Box>
							<Box sx={{ mb: 1 }}>
								
								<Typography variant="body1">
									<Looks4Icon sx={{width:'30px', height:'30px',}} />
									<Box sx={{mt: -4, ml: 4,}}><span style={{fontWeight: 600,}}>Positionnez le microphone </span> de façon à ce que la barre de volume oscille entre le vert et le orange.
									</Box>
								</Typography>
							</Box>
							<Box sx={{ width: '60%', mb: 1, ml: 3, }}>
								<VoiceMeter previewAudioStream={previewAudioStream} audioState={isRecording ? 'recording' : 'idle'} />
							</Box>
						</>
					) : (
						<Box 
							sx={{
								backgroundColor: '#EDEAE6',
								color:'black',
								fontFamily: 'Baskerville',
							}}	
						>
							<Typography variant="body1" align="justify">
								Aucun microphone n'a pu être détecté sur votre appareil. Sur les appareils mobiles, certains navigateurs
								dont celui de facebook ne permettent pas l'utilisation du microphone. Veuillez utiliser le navigateur de
								apparel et utiliser l'adresse suivante:
							</Typography>
							<Paper
								component="form"
								sx={{ mt: 1, p: '2px 2px', display: 'flex', alignItems: 'center' }}
								onClick={() => {
									copy('https://concours.imagemsoft.com');
								}}
							>
								<InputBase
									readOnly
									sx={{ ml: 1, flex: 1 }}
									defaultValue="https://concours.imagemsoft.com"
									inputProps={{ 'aria-label': 'https://concours.imagemsoft.com' }}
								/>
								<IconButton type="button" sx={{ p: '1px' }} aria-label="copy">
									<ContentCopyIcon />
								</IconButton>
							</Paper>
						</Box>
					)}
				</Box>
			)}
			<DialogActions style={{backgroundColor: '#EDEAE6',}}>
				{isMicInfoLoading ? (
					<></>
				) : micsDetected ? (
					<Button color="secondary" variant='text' onClick={handleNext} sx={{ textTransform: 'uppercase', fontFamily: 'Baskerville', height:'30px', fontWeight: 900,}}>
						Sélectionner
					</Button>
				) : (
					<Button color="secondary" variant="text" onClick={handleLogout} sx={{ textTransform: 'uppercase', fontFamily: 'Baskerville', height:'30px', fontWeight: 900,}}>
						Quitter
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default MicrophonePickerDialog;
