import { IUser } from '../data/IUser';
//import { setToken } from './myAxios';
import moment from 'moment';

export const setUserSession = (user: IUser, token: string, expiresIn: number) => {
	if (expiresIn > 0)
		localStorage.setItem('token_expiresAt', moment().add(expiresIn, 'ms').toISOString());
	localStorage.setItem('token', token);
	//localStorage.setItem('user', JSON.stringify(user));
	//setToken(token);
};

export const isUserLoggedIn = () => {
	/*const expiresAt = localStorage.getItem("token_expiresAt");
  if (expiresAt) {
    if (moment().isAfter(expiresAt)) {
      sessionClear();
      return false;
    }
  }*/

	return localStorage.getItem('token') ? true : false;
};

export const getUserToken = () => {
	return localStorage.getItem('token');
};

export const getLoggedUser = (): IUser | null => {
	let user = null;
	let userText = localStorage.getItem('user');
	if (userText) {
		try {
			user = JSON.parse(userText);
		} catch (err) {}
	}
	return user;
};

export const sessionClear = () => {
	localStorage.removeItem('token_expiresAt');
	localStorage.removeItem('token');
	//setToken("");
};

export const getThemeType = (): 'dark' | 'light' => {
	const type = localStorage.getItem('Theme_type');
	if (type === 'light') return 'light';
	return 'dark';
};

export const setThemeType = (type: 'dark' | 'light') => {
	localStorage.setItem('Theme_type', type);
};
