import React, { useRef } from 'react';

import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	CardActions,
	Typography,
	Chip,
	List,
	Popover,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Checkbox,
	Button,
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const subMenuList = [
	{
		title: 'Critères',
	},
];

const JobSearchPopover = (props: any) => {
	const { anchorEl, popoverList, setSelectedFilter, from, onClose, open, selected, ...other } =
		props;

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: 'left',
				vertical: 'bottom',
			}}
			onClose={onClose}
			open={open}
			PaperProps={{
				sx: { width: '300px' },
			}}
			{...other}
		>
			<Box
				sx={{
					py: 1.5,
					px: 2,
				}}
			>
				<List sx={{ width: '100%', maxWidth: 360 /* bgcolor: 'background.paper'*/ }}>
					{popoverList &&
						popoverList.map((value: any) => {
							return (
								<>
									{value && (
										<ListItem key={value} disablePadding>
											<ListItemButton
												role={undefined}
												onClick={() => setSelectedFilter(value)}
												dense
											>
												<ListItemIcon>
													<Checkbox
														edge="start"
														checked={selected.indexOf(value) !== -1}
														tabIndex={-1}
														disableRipple
														//inputProps={{ "aria-labelledby": labelId }}
													/>
												</ListItemIcon>
												<ListItemText
													//id={labelId}
													primary={`${value.label}`}
												/>
											</ListItemButton>
										</ListItem>
									)}
								</>
							);
						})}
				</List>
			</Box>
		</Popover>
	);
};

const NotificationCriteria = ({
	criteria,
	selectedFilter,
	setSelectedFilter,
	onRemoveCriteria,
}: any) => {
	const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

	const [openPopover, setOpenPopover] = React.useState(false);
	const [currentRefIndex, setCurrentRefIndex] = React.useState(0);
	let currentRef = null;
	const handlePopOverOpened = (indx: number) => {
		//setPopoverGroup(el.title);
		setCurrentRefIndex(indx);
		setOpenPopover(true);
	};

	return (
		<>
			<Card sx={{ backgroundColor: 'white' }}>
				<CardHeader sx={{ color: 'black' }} title="Critères de la notification" />
				<Divider />
				<CardContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							flexWrap: 'wrap',
						}}
					>
						{selectedFilter &&
							selectedFilter.map((el: any, idx: number) => (
								<Chip
									sx={{
										display: 'flex',
										mr: 1,
										mb: 1,
										'& .MuiChip-deleteIcon': {
											color: 'grey',
										},
										'&:hover': {
											'& .MuiChip-deleteIcon': {
												color: 'red',
											},
										},
									}}
									label={
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography sx={{ fontWeight: 'bold', color: 'grey' }} variant="body2">
												{el.label}
											</Typography>
										</Box>
									}
									variant="outlined"
									onDelete={() => {
										onRemoveCriteria(el);
									}}
								/>
							))}
					</Box>
				</CardContent>
				<Divider />
				<CardActions>
					<Box>
						{subMenuList &&
							subMenuList.map((el, indx) => (
								<Button
									onClick={() => handlePopOverOpened(indx)}
									color="inherit"
									sx={{ mr: 2, color: 'grey' }}
									ref={(p) => (buttonRefs.current[indx] = p)}
								>
									{el.title}
									<ArrowDropDownIcon color="inherit" />
								</Button>
							))}
					</Box>
					<Box flexGrow={1} />
				</CardActions>
			</Card>
			<JobSearchPopover
				setSelectedFilter={(item: any) => {
					setSelectedFilter(item);
				}}
				selected={selectedFilter}
				popoverList={criteria}
				anchorEl={buttonRefs.current[currentRefIndex]}
				open={openPopover}
				onClose={() => setOpenPopover(false)}
			/>
		</>
	);
};

export default NotificationCriteria;
