import React from 'react';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavItem from './NavItem';
import AudioIcon from '@mui/icons-material/AudioFileOutlined';
import { FaBell } from 'react-icons/fa';
import { useConfirm } from '../providers/ConfirmModal';
import { useLocation } from 'react-router-dom';
import { useUserDetails } from '../providers/UserDetailsProvider';
import { FaHome } from 'react-icons/fa';
export const ChartBarIcon = createSvgIcon(
	<svg viewBox="0 0 20 18" fill="white" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.399902 10.2C0.399902 9.88175 0.52633 9.57652 0.751374 9.35148C0.976418 9.12644 1.28164 9.00001 1.5999 9.00001H3.9999C4.31816 9.00001 4.62339 9.12644 4.84843 9.35148C5.07347 9.57652 5.1999 9.88175 5.1999 10.2V16.2C5.1999 16.5183 5.07347 16.8235 4.84843 17.0485C4.62339 17.2736 4.31816 17.4 3.9999 17.4H1.5999C1.28164 17.4 0.976418 17.2736 0.751374 17.0485C0.52633 16.8235 0.399902 16.5183 0.399902 16.2V10.2ZM7.5999 5.40001C7.5999 5.08175 7.72633 4.77652 7.95137 4.55148C8.17642 4.32643 8.48164 4.20001 8.7999 4.20001H11.1999C11.5182 4.20001 11.8234 4.32643 12.0484 4.55148C12.2735 4.77652 12.3999 5.08175 12.3999 5.40001V16.2C12.3999 16.5183 12.2735 16.8235 12.0484 17.0485C11.8234 17.2736 11.5182 17.4 11.1999 17.4H8.7999C8.48164 17.4 8.17642 17.2736 7.95137 17.0485C7.72633 16.8235 7.5999 16.5183 7.5999 16.2V5.40001ZM14.7999 1.80001C14.7999 1.48175 14.9263 1.17652 15.1514 0.951478C15.3764 0.726434 15.6816 0.600006 15.9999 0.600006H18.3999C18.7182 0.600006 19.0234 0.726434 19.2484 0.951478C19.4735 1.17652 19.5999 1.48175 19.5999 1.80001V16.2C19.5999 16.5183 19.4735 16.8235 19.2484 17.0485C19.0234 17.2736 18.7182 17.4 18.3999 17.4H15.9999C15.6816 17.4 15.3764 17.2736 15.1514 17.0485C14.9263 16.8235 14.7999 16.5183 14.7999 16.2V1.80001Z" />
	</svg>,
	'ChartBar'
);

export const UsersIcon = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white">
		<path
			fillRule="evenodd"
			d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
			clipRule="evenodd"
		/>
	</svg>,
	'User'
);

const items = [
	{
		href: '/admin',
		icon: <FaHome fontSize="small" color="white" />,
		title: 'Acceuil',
	},
	{
		href: '/admin/notification',
		icon: <FaBell fontSize="small" color="white" />,
		title: 'Notificatons',
	},
	/*{
		href: '/admin/audio',
		icon: <AudioIcon sx={{ color: 'white' }} fontSize="small" />,
		title: 'Enregistrements',
	},*/
];

const AdminPageSideBar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const userDetail = useUserDetails();
	const { open, options, onCancel, onConfirm, onClose } = useConfirm();

	const handleBackToDictation = async () => {
		/*options={
			title:
		};*/
		navigate('/textrecorder');
	};
	const lgUp = true; /*useMediaQuery((theme) => theme.breakpoints.up('lg'), {
		defaultMatches: true,
		noSsr: false,
	});*/
	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<div>
				<Box sx={{ p: 2, my: 2 }}></Box>
			</div>
			<Button
				onClick={handleBackToDictation}
				component="a"
				startIcon={<ArrowBackIcon fontSize="small" />}
			>
				Retour
			</Button>
			<Box sx={{ flexGrow: 1 }}>
				{items.map((item) => (
					<NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} />
				))}
			</Box>
		</Box>
	);
	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: '#235A7B',
						color: '#FFFFFF',
						width: 280,
						mt: 6,
					},
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		);
	}

	//no need
	return (
		<Drawer
			anchor="left"
			onClose={() => {}}
			open={true}
			PaperProps={{
				sx: {
					backgroundColor: '#235A7B',
					color: '#FFFFFF',
					width: 2,
					mt: 25,
				},
			}}
			sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
};

export default AdminPageSideBar;
