import React from 'react';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
//import CardCover from '@mui/material/CardCover';
import MainAnimation from '../web/components/MainAnimation';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useUserDetails } from '../providers/UserDetailsProvider';
const RecordCompleted = () => {
	const session = useUserDetails();
	const user = session.userDetails;
	return (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<MainAnimation />
			<Box>
				<Card
					sx={{
						maxWidth: '50rem',
						backgroundColor: 'transparent',
						boxShadow: 'none',
						backgroundImage: 'none',
						flexGrow: 1,
					}}
				>
					<CardContent>
						{' '}
						<Box
							sx={{
								height: '100%',
								maxwidth: '50%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
								color: '#0679FF',
							}}
						>
							<Typography
								textAlign="center"
								component="h3"
								sx={{
									//color: "#f9d13e",
									fontSize: { xs: '1rem', md: '2rem' },
									fontWeight: 600,
									mb: 2,
									letterSpacing: 1,
								}}
							>
								{`Merci ${
									user !== undefined && user !== null
										? user.name !== undefined
											? user.name
											: user.firstName
										: ''!
								}`}
							</Typography>
							<Typography component="h2" sx={{ color: '#e5e7eb', mb: 2 }}>
								{
									"Vous venez de compléter l'ensemble des textes à dicter dans le système. Nous vous remercions de contribuer à l'amélioration de notre système de reconnaissance vocale. Vous courez la chance de gagner 1 000 $ ou l'un des 4 prix de 250 $ . Le/la gagnant(e) du concours sera contactée directement par courriel. Pour avoir plus de chance de gagner, invitez vos amis en cliquant sur 'INVITER UN AMI' ."
								}
							</Typography>
							<Typography component="h2" sx={{ color: '#0679FF', mb: 2 }}>
								{'Bonne chance! - Imagem'}
							</Typography>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
};

export default RecordCompleted;
