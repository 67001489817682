import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Box,
	Grid,
	Typography,
	Select,
	TextField,
	DialogActions,
	Button,
	FormGroup,
	IconButton,
} from '@mui/material';
import { Translate } from '@mui/icons-material';

const ReferralCode = (props: any) => {
	const [referralCode, setReferralCode] = useState('');
	const handleClose = () => {
		props.closeModal();
		/*setNumberOfFields(defaultNumberOfFields);
			setInputFields([{ email: '' }]);*/
	};

	useEffect(() => {
		if (props.value) setReferralCode(props.value);
	}, [props.value]);

	const handleSubmit = () => {
		props.onSubmit(referralCode);
		handleClose();
		setReferralCode('');
	};
	const onChange = (e: any) => {
		setReferralCode(e.target.value.toUpperCase());
	};

	return (
		<Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
			<DialogTitle style={{ backgroundColor: 'black', textTransform:'uppercase', fontWeight: 900, fontFamily: 'Anton'}}>{'Modifier'}</DialogTitle>
			<DialogContent style={{backgroundColor: '#EDEAE6',}}>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt:3, width: '80%', ml: '10%' }}>
					<TextField
						sx={{
							'& fieldset': {
								borderRadius: '30px',
							},
						}}

						InputLabelProps={{
							style: {
								color: '#1E1E1E',
								fontFamily: 'Baskerville',
								textTransform: 'uppercase',
							}
						}}

						inputProps={{
							style: {
								color: '#EDEAE6',
								fontFamily: 'Baskerville',
								borderRadius: '18px',
								backgroundColor: 'black',
								width: '100%',
								border: 'none',
								paddingLeft: '10px',
								fontWeight: 900,
							},
							disableUnderline: true,
							classes: {
							focused: 'Mui-focus'
							}
						}}
						value={referralCode}
						label={'Code de référence'}
						onChange={onChange}
						variant= 'standard'
						fullWidth
					/>
				</Box>
			</DialogContent>
			<DialogActions style={{backgroundColor: '#EDEAE6', display: 'flex', justifyContent:'center'}}>
				<Button color="primary" variant="contained" onClick={handleSubmit} style={{borderRadius: '60px', fontFamily: 'Anton', textTransform: 'uppercase', backgroundColor: 'black', color: 'white', fontWeight: 900, height: '30px'}} >
					{'Modifier'}
				</Button>
				<Button color="secondary" variant="contained" onClick={handleClose} style={{borderRadius: '60px', fontFamily: 'Anton', textTransform: 'uppercase', backgroundColor: '#EDEAE6', border: '1px solid black', color: 'black', fontWeight: 900, height: '30px'}}>
					Annuler
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReferralCode;
