import React from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
	MenuItem,
	useTheme,
} from '@mui/material';

const verificationStatus = [
	{
		value: 'completed',
		label: 'COMPLÉTÉ',
	},
	{
		value: 'verified',
		label: 'VÉRIFIÉ',
	},
	{
		value: 'rejected',
		label: 'REJETÉ',
	},
	{
		value: 'accepted',
		label: 'ACCEPTÉ',
	},
	{
		value: 'in_progress',
		label: 'EN COURS',
	},
	{
		value: 'none',
		label: 'CRÉÉ',
	},
];
interface AccountProfileDetailsProps {
	user: any;
	setUserDetails: (name: any, value: any) => void;

	handleUserInfoChange: (userInfo: any) => void;
}

const MuiItemSx = {
	/*'&.MuiPaper-root': { backgroundColor: 'white' },
	backgroundColor: 'white',
	color: 'grey',*/
};
const AccountProfileDetails = (props: AccountProfileDetailsProps) => {
	const { setUserDetails, handleUserInfoChange } = props;
	const theme = useTheme();

	const textFeildSx = {
		'& .MuiInputBase-input.Mui-disabled': {
			WebkitTextFillColor: '#000000',
		},
		'& .MuiInputLabel-root': {
			color: 'grey',

			'&.Mui-disabled': {
				color: 'grey',
				opacity: 0.5,
			},
		},
		/*'& .MuiOutlinedInput': {
			color: 'red',
			'&.Mui-disabled input': {
				backgroudColor: 'red',
				opacity: 0.5,
			},
		},*/
		'& .MuiOutlinedInput-root': {
			color: 'black',
			'&.Mui-focused fieldset': {
				borderColor: '#235A7B',
				color: '#235A7B',
			},
			'&.Mui-disabled fieldset': {
				borderColor: ' #235A7B',
				opacity: 0.5,
			},
			'&.Mui-disabled': {
				color: '#235A7B',
				opacity: 0.5,
			},
			//'&.Mui-disabled:hover': { background: theme.palette.secondary.main },
			'&:hover fieldset': {
				borderColor: '#238A7B',
			},
		},
	};

	const [values, setValues] = React.useState({
		name: '',
		email: '',
		quality_note: '',
		gender: '',
		province: '',
		region: '',
		referral_code: '',
		dictation_status: '',
	});

	React.useEffect(() => {
		setValues(props.user);
	}, [props.user]);

	const handleChange = (event: any) => {
		//setUserDetails(event.target.name, event.target.value);
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};

	const handleSave = () => {
		handleUserInfoChange({
			...values,
			referral_code: values.referral_code ? values.referral_code.toUpperCase() : '',
			region: values.province !== 'Québec' ? ' ' : values.region,
		});
	};
	return (
		<form autoComplete="off" noValidate {...props}>
			<Card
				sx={{
					backgroundColor: 'white',
				}}
			>
				<CardHeader
					sx={{
						color: 'black',
						'& .MuiCardHeader-subheader': {
							color: 'grey',
						},
					}}
					subheader="ces informations peuvent être modifiées"
					title="Détails utilisateur"
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={12} xs={12}>
							<TextField
								sx={textFeildSx}
								size="small"
								fullWidth
								label="Nom"
								name="name"
								onChange={handleChange}
								required
								value={values.name}
								variant="outlined"
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<TextField
								sx={textFeildSx}
								size="small"
								fullWidth
								label="Courriel"
								name="email"
								onChange={handleChange}
								required
								value={values.email}
								variant="outlined"
								disabled
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								sx={textFeildSx}
								size="small"
								fullWidth
								label="Note QA (0-5)"
								name="quality_note"
								onChange={handleChange}
								type="number"
								value={values.quality_note}
								variant="outlined"
								InputProps={{
									inputProps: {
										max: 5,
										min: 0,
									},
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								sx={textFeildSx}
								size="small"
								fullWidth
								label="Genre"
								name="gender"
								onChange={handleChange}
								required
								select
								value={values.gender}
								variant="outlined"
							>
								{['Autre', 'Homme', 'Femme'].map((option, index) => (
									<MenuItem sx={MuiItemSx} key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								sx={textFeildSx}
								size="small"
								fullWidth
								label="Province"
								name="province"
								onChange={handleChange}
								required
								select
								value={values.province}
								variant="outlined"
							>
								{[
									'Alberta',
									'Colombie-Britanique',
									'Île-du-Prince-Édouard',
									'Manitoba',
									'Nouveau-Brunswick',
									'Nouvelle-Écosse',
									'Nunavut',
									'Ontario',
									'Québec',
									'Saskatchewan',
									'Terre-Neuve-et-Labrador ',
									'Territoires du Nord-Ouest',
									'Yukon',
								].map((option) => (
									<MenuItem sx={MuiItemSx} key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						{(values.province === 'QC' || values.province === 'Québec') && (
							<Grid item md={6} xs={12}>
								<TextField
									sx={textFeildSx}
									size="small"
									fullWidth
									label="Région"
									name="region"
									onChange={handleChange}
									required
									select
									value={values.region}
									variant="outlined"
								>
									{[
										'01 - BAS-ST-LAURENT',
										'02 - SAGUENAY-LAC-SAINT-JEAN',
										'03 - CAPITALE-NATIONALE (QUÉBEC)',
										'04 - MAURICIE',
										'05 - ESTRIE',
										'06 - MONTRÉAL',
										'07 - OUTAOUAIS',
										'08 - ABITIBI-TÉMISCAMINGUE',
										'09 - CÔTE-NORD',
										'10 - NORD-DU-QUEBEC',
										'11 - GASPÉSIE–ÎLES-DE-LA-MADELEINE',
										'12 - CHAUDIÈRE-APPALACHES',
										'13 - LAVAL',
										'14 - LANAUDIÈRE',
										'15 - LAURENTIDES',
										'16 - MONTÉRÉGIE',
										'17 - CENTRE-DU-QUÉBEC',
									].map((option, idx) => (
										<MenuItem sx={MuiItemSx} key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						)}
						<Grid item md={6} xs={12}>
							<TextField
								sx={textFeildSx}
								size="small"
								fullWidth
								label="Code de référence"
								name="referral_code"
								onChange={handleChange}
								value={values.referral_code ? values.referral_code.toUpperCase() : ''}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								sx={textFeildSx}
								size="small"
								fullWidth
								label="Status"
								name="dictation_status"
								onChange={handleChange}
								required
								select
								value={values.dictation_status}
								variant="outlined"
							>
								{verificationStatus.map((option) => (
									<MenuItem sx={MuiItemSx} key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
				</CardContent>
				<Divider />
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						p: 2,
					}}
				>
					<Button
						onClick={() => {
							handleSave();
						}}
						color="primary"
						variant="contained"
					>
						Enregistrer
					</Button>
				</Box>
			</Card>
		</form>
	);
};

export default AccountProfileDetails;
