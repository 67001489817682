import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import { Box, Card, CardContent, CardHeader, Divider, Typography, useTheme } from '@mui/material';

const CompletedByGender = (props: any) => {
	const theme = useTheme();
	const data = {
		datasets: [
			{
				data: props.data ? props.data.map((x: any) => x.total) : [],
				backgroundColor: ['#66bb6a', '#FB8C00', '#3F51B5'],
				borderWidth: 8,
				borderColor: '#FFFFFF',
				hoverBorderColor: '#FFFFFF',
			},
		],
		labels: ['Homme', 'Femme', 'Autre'],
	};

	const options = {
		//animation: false,
		cutoutPercentage: 80,
		layout: { padding: 0 },
		legend: {
			display: false,
		},
		maintainAspectRatio: false,
		responsive: true,
		tooltips: {
			backgroundColor: theme.palette.background.paper,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: 'index',
			titleFontColor: theme.palette.text.primary,
		},
	};

	/*const devices = [
		{
			title: 'Homme',
			value: 63,
			icon: LaptopMacIcon,
			color: '#66bb6a',
		},
		{
			title: 'Femme',
			value: 15,
			icon: PhoneIcon,
			color: '#FB8C00',
		},
		{
			title: 'Autre',
			value: 23,
			icon: TabletIcon,
			color: '#3F51B5',
		},
	];*/
	return (
		<Card {...props}>
			<CardHeader sx={{ color: 'black' }} title="Complété par genre" />
			<Divider />
			<CardContent>
				<Box
					sx={{
						height: 300,
						position: 'relative',
					}}
				>
					<Doughnut data={data} options={options} />
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					{props.data &&
						props.data.map(({ color, icon: Icon, title, value }: any) => (
							<Box
								key={title}
								sx={{
									p: 0.5,
									textAlign: 'center',
								}}
							>
								<Icon sx={{ color: 'black' }} />
								<Typography color="textPrimary" variant="body1">
									{title}
								</Typography>
								<Typography style={{ color }} variant="h4">
									{value}%
								</Typography>
							</Box>
						))}
				</Box>
			</CardContent>
		</Card>
	);
};

export default CompletedByGender;
