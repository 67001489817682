import React from 'react';
import {
	Box,
	MenuItem,
	Grid,
	Card,
	CardContent,
	TextField,
	InputAdornment,
	SvgIcon,
	Typography,
	Button,
} from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import { Download as DownloadIcon } from '@mui/icons-material';

export const SearchIcon = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		<path
			fillRule="evenodd"
			d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
			clipRule="evenodd"
		/>
	</svg>,
	'Search'
);
const verificationStatus = [
	{
		value: 'all',
		label: 'Tous les status',
	},
	{
		value: 'completed',
		label: 'COMPLÉTÉ',
	},
	{
		value: 'verified',
		label: 'VÉRIFIÉ',
	},
	{
		value: 'rejected',
		label: 'REJETÉ',
	},
	{
		value: 'accepted',
		label: 'ACCEPTÉ',
	},
	{
		value: 'in_progress',
		label: 'EN COURS',
	},
	{
		value: 'none',
		label: 'CRÉÉ',
	},
];

const textFeildSx = {
	'& .MuiInputBase-input.Mui-disabled': {
		WebkitTextFillColor: '#000000',
	},
	'& .MuiInputLabel-root': {
		color: 'grey',

		'&.Mui-disabled': {
			color: 'grey',
			opacity: 0.5,
		},
	},
	/*'& .MuiOutlinedInput': {
			color: 'red',
			'&.Mui-disabled input': {
				backgroudColor: 'red',
				opacity: 0.5,
			},
		},*/
	'& .MuiOutlinedInput-root': {
		color: 'black',
		'&.Mui-focused fieldset': {
			borderColor: '#235A7B',
			color: '#235A7B',
		},
		'&.Mui-disabled fieldset': {
			borderColor: ' #235A7B',
			opacity: 0.5,
		},
		'&.Mui-disabled': {
			color: '#235A7B',
			opacity: 0.5,
		},
		//'&.Mui-disabled:hover': { background: theme.palette.secondary.main },
		'&:hover fieldset': {
			borderColor: '#238A7B',
		},
	},
};
const UserListToolbar = (props: any) => {
	const handleChange = (e: any) => {
		props.handleStatusFilterChange(e.target.value);
	};

	return (
		<Box {...props}>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
					m: -1,
					mt: 3,
				}}
			>
				<Typography sx={{ m: 1, color: 'black' }} variant="h4">
					{props.title ? props.title : ''}
				</Typography>
				<Button
					onClick={props.onDownloadData}
					startIcon={<DownloadIcon fontSize="small" />}
					sx={{ mr: 1 }}
				>
					Exporter en CSV
				</Button>
			</Box>
			<Box sx={{ mt: 3 }}>
				<Card sx={{ backgroundColor: 'white' }}>
					<CardContent>
						<Grid container spacing={3}>
							<Grid item md={6} xs={12}>
								<Box>
									<TextField
										fullWidth
										sx={{
											'& .MuiOutlinedInput-root': {
												//borderColor: 'white',
												'&.Mui-focused fieldset': {
													borderColor: '#235A7B',
												},
												'&:hover fieldset': {
													borderColor: '#235A7B',
												},
											},
											'& .MuiOutlinedInput-input': {
												color: 'grey',
											},
											'& .MuiSvgIcon-root': {
												color: 'grey',
											},
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SvgIcon color="action" fontSize="small">
														<SearchIcon />
													</SvgIcon>
												</InputAdornment>
											),
										}}
										placeholder="Recherche d'utilisateur..."
										variant="outlined"
										onChange={(e: any) => {
											props.handleFilterUserArray(e);
										}}
									/>
								</Box>
							</Grid>
							<Grid item md={6} xs={12}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'row',
									}}
								>
									<TextField
										sx={textFeildSx}
										fullWidth
										label="Status"
										name="dictation_status"
										onChange={handleChange}
										select
										value={props.statusFilterValue}
										variant="outlined"
									>
										{verificationStatus.map((option) => (
											<MenuItem /*sx={MuiItemSx}*/ key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>

									{/*<Button
										sx={{ ml: 2 }}
										onClick={() => {
											props.downloadData();
										}}
										color="secondary"
										variant="contained"
									>
										Télécharger les données
									</Button>*/}
								</Box>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
};

export default UserListToolbar;
