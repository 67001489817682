import { useEffect, useState } from 'react';
import { GoToTopBtnContainer, GoToTopIconBtn } from '../globalStyles';
import { useLocation } from 'react-router-dom';
export default function ScrollToTop() {
	const [showTopBtn, setShowTopBtn] = useState(false);
	const location = useLocation();
	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 400) {
				setShowTopBtn(true);
			} else {
				setShowTopBtn(false);
			}
		});
	}, []);

	const goToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	if (location.pathname !== '/index') return <></>;
	return (
		<GoToTopBtnContainer>{showTopBtn && <GoToTopIconBtn onClick={goToTop} />}</GoToTopBtnContainer>
	);
}
