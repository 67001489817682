import React, { useEffect } from 'react';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';
import {
	FooterContainer,
	FooterSubscription,
	FooterSubHeading,
	Form,
	SocialMedia,
	SocialMediaWrap,
	ImaLogo,
	WebsiteRights,
	SocialIcons,
	SocialIconLink,
} from './Footer.elements';
import { Box } from '@mui/system';

import imalogo from '../images/Imagem_blanc.png';
import { Link } from 'react-router-dom';
let ismounted = false;

function Footer() {
	useEffect(() => {
		const element = document.getElementById('nl_hubspotForm');
		if (element) element.remove();
		const script = document.createElement('script');
		script.id = 'nl_hubspotForm';
		script.src = 'https://js.hsforms.net/forms/v2.js';
		document.body.appendChild(script);

		script.addEventListener('load', () => {
			// @TS-ignore
			if (window.hbspt) {
				// @TS-ignore
				window.hbspt.forms.create({
					portalId: '23267409',
					formId: 'ca304aa8-eb57-46dd-a240-5b28b6e4e44e',
					target: '#hubspotFormNewsLetter',
				});
			}
		});
	}, []);
	return (
		<FooterContainer data-aos="fade-up">
			<FooterSubscription>
				<FooterSubHeading>
					Joignez-vous à l'infolettre d'Imagem pour ne rien manquer de nos offres!
				</FooterSubHeading>
				<Form>
					<div style={{ width: '100%', color: 'yellow' }}>
						<div style={{ color: 'yellow' }} id="hubspotFormNewsLetter"></div>
					</div>
				</Form>
			</FooterSubscription>
			<SocialMedia>
				<SocialMediaWrap>
					<WebsiteRights> © 2022 Tout droits réservés-Imagem. </WebsiteRights>
					<ImaLogo href="https://www.imagemsoft.com/" target="_blank">
						<img src={imalogo} height="70px" alt="" />
					</ImaLogo>
					<Box>
						<SocialIcons>
							<SocialIconLink
								href="https://fr-fr.facebook.com/Imagemsoft/"
								target="_blank"
								aria-label="Facebook"
							>
								<FaFacebook />
							</SocialIconLink>
							<SocialIconLink
								href="https://ca.linkedin.com/company/imagem-solutions-ti-sant-"
								target="_blank"
								aria-label="Instagram"
							>
								<FaLinkedin />
							</SocialIconLink>
						</SocialIcons>
						<WebsiteRights>
							Consultez nos mentions légales{' '}
							<Link to={'/legal'} style={{ color: 'inherit' }}>
								ici
							</Link>
						</WebsiteRights>
					</Box>
				</SocialMediaWrap>
			</SocialMedia>
		</FooterContainer>
	);
}

export default Footer;
