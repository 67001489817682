import React, { createContext, useContext, useState } from 'react';
//create a context, with createContext api
export const userDetailsContext = createContext({} as any);

const UserDetailsProvider = (props: any) => {
	// this state will be shared with all components
	let [userDetails, setUserDetails] = useState<any>();

	const saveUser = (user: any) => {
		setUserDetails(user);
	};

	const isAdmin = () => {
		if (userDetails && userDetails?.role === 'admin') return true;
		return false;
	};
	return (
		// this is the provider providing state
		<userDetailsContext.Provider value={{ userDetails, saveUser, isAdmin }}>
			{props.children}
		</userDetailsContext.Provider>
	);
};

export default UserDetailsProvider;

export const useUserDetails = () => {
	return useContext(userDetailsContext);
};
