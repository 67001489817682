import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { setUserSession, isUserLoggedIn, setThemeType } from './helpers/session';
import Register from './components/views/Register';
import Login from './components/views/Login';
import Layout from './components/web/Layout';
import ForgotPassword from './components/views/ForgotPassword';
import TextRecorder from './components/views/TextRecordView/TextRecorder';
import { IUser } from './data/IUser';
import RecordCompleted from './components/views/RecordCompleted';
import ScrollToTop from './components/web/components/ScrollTop';
import GlobalStyle from './components/web/globalStyles';
import {
	createTheme,
	ThemeProvider,
	CssBaseline,
	Button,
	Container,
	useScrollTrigger,
	Toolbar,
	Box,
} from '@mui/material';
import Audio from './components/Audio';
import UserAudio from './components/UserAudio';
import HomeWeb from './components/web/pages/HomeWeb';
import PrimaryAppBar from './components/PrimaryAppBar';
import { NotifyProvider } from './components/providers/NotifyProvider';
import { Navigate } from 'react-router-dom';
import RequireUser from './components/RequireUser';
import { ConfirmProvider } from './components/providers/ConfirmModal';
import { HelpProvider } from './components/providers/HelpModal';
import Legal from './components/views/Legal';
import LegalTerm from './components/web/pages/Legal';
import { AudioRecordingBookProvider } from './components/providers/AudioRecordingSessionProvider';
import UserDetailsProvider from './components/providers/UserDetailsProvider';
import Admin from './components/Admin';
import Notification from './components/Notification';
import CreateNotification from './components/CreateNotification';
import AdminLayout from './components/AdminLayout';
import ResetPassword from './components/views/ResetPassword';
import UnauthorizePage from './components/views/UnAuthorized';

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
	children?: React.ReactElement;
}

function ElevationScroll(props: any) {
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? props.window() : undefined,
	});

	return React.cloneElement(props.children, {
		elevation: trigger ? 4 : 0,
	});
}

let theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1000,
			lg: 1200,
			xl: 1920,
		},
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
				sizeSmall: {
					padding: '6px 16px',
				},
				sizeMedium: {
					padding: '8px 20px',
				},
				sizeLarge: {
					padding: '11px 24px',
				},
				textSizeSmall: {
					padding: '7px 12px',
				},
				textSizeMedium: {
					padding: '9px 16px',
				},
				textSizeLarge: {
					padding: '12px 16px',
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '32px 24px',
					'&:last-child': {
						paddingBottom: '32px',
					},
				},
			},
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: {
					variant: 'h6',
				},
				subheaderTypographyProps: {
					variant: 'body2',
				},
			},
			styleOverrides: {
				root: {
					padding: '32px 24px',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					boxSizing: 'border-box',
					margin: 0,
					padding: 0,
				},
				html: {
					MozOsxFontSmoothing: 'grayscale',
					WebkitFontSmoothing: 'antialiased',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
				},
				body: {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
				},
				'#__next': {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: '#E6E8F0',
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: '#F3F4F6',
					'.MuiTableCell-root': {
						color: '#374151',
					},
					borderBottom: 'none',
					'& .MuiTableCell-root': {
						borderBottom: 'none',
						fontSize: '12px',
						fontWeight: 600,
						lineHeight: 1,
						letterSpacing: 0.5,
						textTransform: 'uppercase',
					},
					'& .MuiTableCell-paddingCheckbox': {
						paddingTop: 4,
						paddingBottom: 4,
					},
				},
			},
		},
	},
	palette: {
		mode: 'dark',
		primary: {
			main: '#2F3D4C'
		},
		secondary: {
			main: '#1E1E1E',
		},
		background: {
			default: 'black',
			paper: 'black',
		},
	},
	typography: {
		button: {
			fontWeight: 600,
		},
		fontFamily:
			'"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Anton", "Baskerville"',
		body1: {
			fontSize: '1rem',
			fontWeight: 400,
			lineHeight: 1.5,
		},
		body2: {
			fontSize: '0.6rem',
			fontWeight: 400,
			lineHeight: 1.57,
		},
		subtitle1: {
			fontSize: '1rem',
			fontWeight: 500,
			lineHeight: 1.75,
		},
		subtitle2: {
			fontSize: '0.8rem',
			fontWeight: 500,
			lineHeight: 1.57,
		},
		overline: {
			fontSize: '0.75rem',
			fontWeight: 600,
			letterSpacing: '0.5px',
			lineHeight: 2.5,
			textTransform: 'uppercase',
		},
		caption: {
			fontSize: '0.75rem',
			fontWeight: 400,
			lineHeight: 1.66,
		},
		h1: {
			fontWeight: 700,
			fontSize: '3.5rem',
			lineHeight: 1.375,
		},
		h2: {
			fontWeight: 700,
			fontSize: '3rem',
			lineHeight: 1.375,
		},
		h3: {
			fontWeight: 700,
			fontSize: '2.25rem',
			lineHeight: 1.375,
		},
		h4: {
			fontWeight: 700,
			fontSize: '2rem',
			lineHeight: 1.375,
		},
		h5: {
			fontWeight: 600,
			fontSize: '1.5rem',
			lineHeight: 1.375,
		},
		h6: {
			fontWeight: 600,
			fontSize: '1.125rem',
			lineHeight: 1.375,
		},
	},
	shape: {
		borderRadius: 8,
	},
	shadows: [
		'none',
		'0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
		'0px 1px 2px rgba(100, 116, 139, 0.12)',
		'0px 1px 4px rgba(100, 116, 139, 0.12)',
		'0px 1px 5px rgba(100, 116, 139, 0.12)',
		'0px 1px 6px rgba(100, 116, 139, 0.12)',
		'0px 2px 6px rgba(100, 116, 139, 0.12)',
		'0px 3px 6px rgba(100, 116, 139, 0.12)',
		'0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
		'0px 5px 12px rgba(100, 116, 139, 0.12)',
		'0px 5px 14px rgba(100, 116, 139, 0.12)',
		'0px 5px 15px rgba(100, 116, 139, 0.12)',
		'0px 6px 15px rgba(100, 116, 139, 0.12)',
		'0px 7px 15px rgba(100, 116, 139, 0.12)',
		'0px 8px 15px rgba(100, 116, 139, 0.12)',
		'0px 9px 15px rgba(100, 116, 139, 0.12)',
		'0px 10px 15px rgba(100, 116, 139, 0.12)',
		'0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
		'0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
		'0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
		'0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
		'0px 25px 50px rgba(100, 116, 139, 0.25)',
		'0px 25px 50px rgba(100, 116, 139, 0.25)',
		'0px 25px 50px rgba(100, 116, 139, 0.25)',
		'0px 25px 50px rgba(100, 116, 139, 0.25)',
	],
	/*components: {
		MuiTab: {
			defaultProps: {
				disableRipple: true,
			},
		},
	},*/
	mixins: {
		toolbar: {
			minHeight: 48,
		},
	},
});

theme = {
	...theme,
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
				sizeSmall: {
					padding: '6px 16px',
				},
				sizeMedium: {
					padding: '8px 20px',
				},
				sizeLarge: {
					padding: '11px 24px',
				},
				textSizeSmall: {
					padding: '7px 12px',
				},
				textSizeMedium: {
					padding: '9px 16px',
				},
				textSizeLarge: {
					padding: '12px 16px',
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '32px 24px',
					'&:last-child': {
						paddingBottom: '32px',
					},
				},
			},
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: {
					variant: 'h6',
				},
				subheaderTypographyProps: {
					variant: 'body2',
				},
			},
			styleOverrides: {
				root: {
					padding: '32px 24px',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					boxSizing: 'border-box',
					margin: 0,
					padding: 0,
				},
				html: {
					MozOsxFontSmoothing: 'grayscale',
					WebkitFontSmoothing: 'antialiased',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
				},
				body: {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
				},
				'#__next': {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: '#F3F4F6',
					'.MuiTableCell-root': {
						color: '#374151',
					},
					borderBottom: 'none',
					'& .MuiTableCell-root': {
						borderBottom: 'none',
						fontSize: '12px',
						fontWeight: 600,
						lineHeight: 1,
						letterSpacing: 0.5,
						textTransform: 'uppercase',
					},
					'& .MuiTableCell-paddingCheckbox': {
						paddingTop: 4,
						paddingBottom: 4,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: '#1E1E1E',
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: 8,
					color: 'white',
					backgroundColor: '#113552',
					height: '45px',
					'& h2': {
						fontSize: 16,
					},
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: '#2F3D4C',
				},
			},
		},
		/*MuiButton: {
			styleOverrides: {
				root: {
					size: 'small',
					textTransform: 'none',
				},
				contained: {
					boxShadow: 'none',
					'&:active': {
						boxShadow: 'none',
					},
				},
			},
		},*/
		MuiTabs: {
			styleOverrides: {
				root: {
					marginLeft: theme.spacing(1),
				},
				indicator: {
					height: 3,
					borderTopLeftRadius: 3,
					borderTopRightRadius: 3,
					backgroundColor: theme.palette.common.white,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					margin: '0 16px',
					minWidth: 0,
					padding: 0,
					[theme.breakpoints.up('md')]: {
						padding: 0,
						minWidth: 0,
					},
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: theme.spacing(1),
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 4,
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: '#E6E8F0',
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						color: '#21A651',
					},
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					fontSize: 14,
					fontWeight: theme.typography.fontWeightMedium,
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: 'inherit',
					minWidth: 'auto',
					marginRight: theme.spacing(2),
					'& svg': {
						fontSize: 20,
					},
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					width: 32,
					height: 32,
				},
			},
		},
	},
};

const App = (props: Props) => {
	const drawerWidth = 256;

	//Force render on login/logout
	const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

	const onLogin = (user: IUser, token: string, expiresIn: number) => {
		setUserSession(user, token, expiresIn);
		forceUpdate();
	};

	const onLogout = () => {
		localStorage.clear();
		forceUpdate();
	};

	const PrivateRoute = ({ children }: any) => {
		const auth = isUserLoggedIn();
		return auth ? children : <Navigate to="/login" />;
	};

	const HomeRoute = () => {
		const auth = isUserLoggedIn();
		return auth ? <Navigate to="/textrecorder" /> : <Navigate to="/index" />;
	};

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ display: 'flex', minHeight: '100vh' }}>
				<CssBaseline />
				<ConfirmProvider>
					<UserDetailsProvider>
						<HelpProvider>
							<BrowserRouter>
								<GlobalStyle />
								<ScrollToTop />
								<NotifyProvider>
									<AudioRecordingBookProvider>
										<Box
											sx={{
												flex: 1,
												display: 'flex',
												flexDirection: 'column',
												width: { sm: `calc(100% - ${drawerWidth}px)` },
											}}
										>
											<Box component="main" sx={{ flex: 1 }}>
												<Routes>
													<Route path="/index" element={<Layout />}>
														<Route index element={<HomeWeb />} />
													</Route>
													<Route path="/legal" element={<Layout />}>
														<Route index element={<LegalTerm />} />
													</Route>

													<Route
														path="/login"
														element={
															<>
																<PrimaryAppBar onLogout={onLogout} />
																<Login onLogin={onLogin} />
															</>
														}
													/>
													<Route
														path="/register"
														element={
															<>
																<PrimaryAppBar onLogout={onLogout} />
																<Register onRegister={onLogin} />
															</>
														}
													/>
													<Route
														path="/forgotpass"
														element={
															<>
																<PrimaryAppBar onLogout={onLogout} />
																<ForgotPassword />
															</>
														}
													/>
													<Route element={<RequireUser allowedRoles={['user', 'admin']} />}>
														<Route
															path="/legal/terms"
															element={
																<>
																	<PrimaryAppBar onLogout={onLogout} />
																	<Legal />
																</>
															}
														/>
													</Route>

													<Route element={<RequireUser allowedRoles={['user', 'admin']} />}>
														<Route
															path="/textrecorder"
															element={
																<>
																	<PrimaryAppBar onLogout={onLogout} />
																	<TextRecorder />
																</>
															}
														/>
													</Route>
													<Route element={<RequireUser allowedRoles={['user', 'admin']} />}>
														<Route
															path="/completed"
															element={
																<>
																	<PrimaryAppBar onLogout={onLogout} />
																	<RecordCompleted />
																</>
															}
														/>
													</Route>

													<Route element={<RequireUser allowedRoles={['admin']} />}>
														<Route
															path="/admin"
															element={
																<AdminLayout onLogout={onLogout}>
																	<Admin />
																</AdminLayout>
															}
														></Route>
													</Route>
													<Route
														path="unauthorized"
														element={
															<>
																<PrimaryAppBar onLogout={onLogout} />
																<UnauthorizePage />{' '}
															</>
														}
													/>
													<Route
														path="/resetpassword"
														element={
															<>
																<PrimaryAppBar onLogout={onLogout} />
																<ResetPassword />
															</>
														}
													>
														<Route
															path=":resetToken"
															element={
																<>
																	<PrimaryAppBar onLogout={onLogout} />
																	<ResetPassword />
																</>
															}
														/>
													</Route>

													<Route element={<RequireUser allowedRoles={['admin']} />}>
														<Route
															path="/admin/notification"
															element={
																<AdminLayout onLogout={onLogout}>
																	<Notification />
																</AdminLayout>
															}
														></Route>
													</Route>

													<Route element={<RequireUser allowedRoles={['admin']} />}>
														<Route
															path="/admin/notification/:notificationId"
															element={
																<AdminLayout onLogout={onLogout}>
																	<CreateNotification />
																</AdminLayout>
															}
														></Route>
													</Route>

													<Route element={<RequireUser allowedRoles={['admin']} />}>
														<Route
															path="/admin/audio/:sessionId"
															element={
																<AdminLayout onLogout={onLogout}>
																	<UserAudio />
																</AdminLayout>
															}
														></Route>
													</Route>

													<Route path="/" element={<HomeRoute />} />
												</Routes>
											</Box>
										</Box>
									</AudioRecordingBookProvider>
								</NotifyProvider>
							</BrowserRouter>
						</HelpProvider>
					</UserDetailsProvider>
				</ConfirmProvider>
			</Box>
		</ThemeProvider>
	);
};

export default App;
