import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '../hooks/makeStyles';
import { useNavigate } from 'react-router-dom';
import request from '../../helpers/myAxios';
import { AxiosResponse } from 'axios';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { useParams, useLocation } from 'react-router-dom';
import useRequest from '../hooks/useRequest';
import {
	Box,
	Stack,
	Container,
	Paper,
	Grid,
	TextField,
	Checkbox,
	FormControlLabel,
	Button,
	MenuItem,
	Typography,
	MenuList,
} from '@mui/material';
import background from '../../images/bg-animé.gif'; // Import using relative path
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNotify } from '../providers/NotifyProvider';
import { generate } from 'referral-codes';
import { blue } from '@mui/material/colors';
import Color from 'color';
import { color } from '@mui/system';
import { Background, Theme } from 'tsparticles-engine';
import zIndex from '@mui/material/styles/zIndex';

const useStyles = makeStyles()((theme) => ({
	margin: {
		width: '100%',
		margin: 'auto',
		padding: '40px',
		borderRadius: theme.shape.borderRadius,
	},
	padding: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
	view: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundImage: `url(${background})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
}));

const initialValues = {
	name: '',
	email: '', //email
	first_name: '',
	last_name: '',
	password: '',
	pass_confirm: '',
	phone_number: '',
	gender: '',
	province: '',
	is_canadian: '',
	french_as_first_language: '',
	browser_localisation: '',
	referral_code: '',
	used_referral_code: '',
	region: '',
	role: 'user',
};

interface RegisterProps extends ReactCookieProps {
	onRegister: (user: any, token: string, expiresIn: number) => void;
}
const Register = (props: RegisterProps) => {
	const notify = useNotify();
	const { doRequest, errors, loading } = useRequest();
	const navigate = useNavigate();
	const [userData, setUserData] = useState(initialValues);
	const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
	const params = useParams();
	const location = useLocation();
	const search = location.search;
	let referral: string = '';
	if (search) referral = search.split('=')[1];

	const [checked, setChecked] = React.useState(false);
	const [consentChecked, setConsentCheckedChecked] = React.useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setConsentCheckedChecked(event.target.checked);
	};

	const onChange = (e: any) => {
		e.preventDefault();
		let { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};
	const reset = () => {
		setUserData(initialValues);
		setIsSubmitSuccessful(false);
	};
	useEffect(() => {
		notify.error(errors);
		//reset();
	}, [errors]);
	useEffect(() => {
		if (isSubmitSuccessful) {
			reset();
		}
	}, [isSubmitSuccessful]);

	const handleRegister = async () => {
		try {
			let canSubmit = true;
			if (userData.first_name === '' || userData.last_name === '' || userData.email === '' || userData.gender === '') {
				notify.error("Les champs marqués d'un (*) sont obligatoires.");
				canSubmit = false;
			}
			if (userData.password !== userData.pass_confirm) {
				notify.error('Les mots de passe ne sont pas identiques.');
				canSubmit = false;
			}
			if (!checked) {
				notify.error('Veuillez cocher toutes les cases pour donner votre consentement.');
				return;
			}
			if (canSubmit) {
				await doRequest({
					url: '/auth/register',
					method: 'post',
					body: {
						...userData,
						name: userData.first_name + ' ' + userData.last_name,
						used_referral_code: referral ? referral : '',
						referral_code: referral
							? referral
							: generate({
									length: 8,
									count: 5,
							  })[0].toUpperCase(),
					},
					onSuccess: (res: any) => {
						if (res) {
							props.onRegister(res.user, res.accessToken, 0);
							navigate('/legal/terms');
						} else {
							notify.error('Erreur lors de la Requête au serveur.');
						}
					},
				});
			}
		} catch (error) {}
	};

	return (
		<>
			<Box
				component="main"
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexGrow: 1,
					minHeight: '100%',
					//backgroundImage: `url(${background})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					zIndex: 100,
					padding: 2,
					'& fieldset': {
						paddingLeft: (theme) => theme.spacing(2.5),
						borderRadius: '30px',
					},
				}}
			>
				<Container sx={{ backgroundColor: '#EDEAE6', width: '100%', borderRadius: '30px' }} maxWidth={'sm'}>
					<Stack
						spacing={1}
						sx={{
							my: 3,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
					>
						<Typography
							textAlign="center"
							component="h1"
							sx={{
								color: '#1E1E1E',
								fontSize: { xs: '2rem', md: '3rem' },
								fontWeight: 800,

								letterSpacing: -5,
								textTransform: 'uppercase',
								fontFamily: 'Anton',
							}}
						>
							{'Bienvenue!'}
						</Typography>
						<Typography
							component="h2"
							sx={{ color: '#1E1E1E', fontWeight: 600, textTransform: 'uppercase', fontFamily: 'Baskerville' }}
						>
							{"S'inscrire pour commencer"}
						</Typography>
						<Typography sx={{ color: '#1E1E1E' }} variant="subtitle2">
							{
								'Un MacBook Air sera attribué par tirage au sort parmi les 500 participants, et ce concours est réservé uniquement aux étudiants du Cégep de Jonquière. Toutes les informations personnelles seront détruites au plus tard le 15 janvier 2025, et le tirage au sort se déroulera le mardi 15 octobre 2024.'
							}
						</Typography>

						<Grid container sx={{ width: '100%' }} spacing={1}>
							<Grid item xs={12} sm={6}>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									id="last_name"
									name="last_name"
									label={'Nom'}
									type="text"
									color="secondary"
									size="small"
									fullWidth
									required
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									id="first_name"
									name="first_name"
									label={'Prénom'}
									type="text"
									color="secondary"
									size="small"
									fullWidth
									required
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									id="register_email"
									label={'Courriel'}
									name={'email'}
									type="email"
									color="secondary"
									size="small"
									fullWidth
									required
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									id="register_password"
									label={'Mot de passe'}
									name={'password'}
									type="password"
									color="secondary"
									size="small"
									fullWidth
									required
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									id="pass_confirm"
									label={'Confirmer le mot de passe'}
									name={'pass_confirm'}
									type="password"
									color="secondary"
									size="small"
									fullWidth
									required
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6} sx={{ display: 'flex', position: 'relative' }}>
								<ExpandMoreIcon sx={{ color: 'black', right: 10, top: 15, position: 'absolute' }} />
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: 'white',
											fontFamily: 'Baskerville',
										},
									}}
									name="gender"
									fullWidth
									select
									label="Genre"
									color="secondary"
									size="small"
									SelectProps={{
										style: {
											color: 'black',
										},
										IconComponent: () => null,
									}}
									required
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								>
									{[
										'Autre',
										'Homme',
										'Femme',
										'Fluide dans le genre',
										'Non binaire',
										'Bispirituel',
										'Préfère ne pas répondre',
									].map((item, index) => {
										return (
											<MenuItem sx={{ color: 'white' }} value={item}>
												{item}
											</MenuItem>
										);
									})}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									id="phone_number"
									label={'Téléphone'}
									name={'phone_number'}
									color="secondary"
									type="text"
									size="small"
									fullWidth
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} sx={{ display: 'flex', position: 'relative' }}>
								<ExpandMoreIcon sx={{ color: 'black', right: 10, top: 15, position: 'absolute' }} />
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									name="is_canadian"
									fullWidth
									select
									size="small"
									color="secondary"
									label="Êtes-vous né(e) au Canada?"
									SelectProps={{
										style: {
											color: 'black',
										},
										IconComponent: () => null,
									}}
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								>
									{['Oui', 'Non'].map((item, index) => {
										return <MenuItem value={index === 0 ? 'true' : 'false'}>{item}</MenuItem>;
									})}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={12} sx={{ display: 'flex', position: 'relative' }}>
								<ExpandMoreIcon sx={{ color: 'black', right: 10, top: 15, position: 'absolute' }} />
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									name="province"
									fullWidth
									select
									color="secondary"
									size="small"
									label={'Dans quelle province habitez-vous?'}
									SelectProps={{
										style: {
											color: 'black',
										},
										IconComponent: () => null,
									}}
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								>
									{[
										'Alberta',
										'Colombie-Britanique',
										'Île-du-Prince-Édouard',
										'Manitoba',
										'Nouveau-Brunswick',
										'Nouvelle-Écosse',
										'Nunavut',
										'Ontario',
										'Québec',
										'Saskatchewan',
										'Terre-Neuve-et-Labrador ',
										'Territoires du Nord-Ouest',
										'Yukon',
									].map((item, index) => {
										return (
											<MenuItem key={item} value={item}>
												{item}
											</MenuItem>
										);
									})}
								</TextField>
							</Grid>
							{(userData.province === 'QC' || userData.province === 'Québec') && (
								<Grid item xs={12} sm={12} sx={{ display: 'flex', position: 'relative' }}>
									<ExpandMoreIcon sx={{ color: 'black', right: 10, top: 15, position: 'absolute' }} />
									<TextField
										InputLabelProps={{
											style: {
												color: '#1E1E1E',
												fontFamily: 'Baskerville',
												textTransform: 'uppercase',
											},
										}}
										inputProps={{
											style: {
												color: '#1E1E1E',
												fontFamily: 'Baskerville',
											},
										}}
										name="region"
										fullWidth
										select
										color="secondary"
										size="small"
										label="Dans quelle région du Québec habitez-vous ?"
										SelectProps={{
											style: {
												color: 'black',
											},
											IconComponent: () => null,
										}}
										onChange={(e) => {
											e.preventDefault();
											onChange(e);
										}}
									>
										{[
											'01 - BAS-ST-LAURENT',
											'03 - CAPITALE-NATIONALE (QUÉBEC)',
											'02 - SAGUENAY-LAC-SAINT-JEAN',
											'04 - MAURICIE',
											'05 - ESTRIE',
											'06 - MONTRÉAL',
											'07 - OUTAOUAIS',
											'08 - ABITIBI-TÉMISCAMINGUE',
											'09 - CÔTE-NORD',
											'10 - NORD-DU-QUEBEC',
											'11 - GASPÉSIE–ÎLES-DE-LA-MADELEINE',
											'12 - CHAUDIÈRE-APPALACHES',
											'13 - LAVAL',
											'14 - LANAUDIÈRE',
											'15 - LAURENTIDES',
											'16 - MONTÉRÉGIE',
											'17 - CENTRE-DU-QUÉBEC',
										].map((item, index) => {
											return (
												<MenuItem key={item} value={item}>
													{item}
												</MenuItem>
											);
										})}
									</TextField>
								</Grid>
							)}
							<Grid item xs={12} sm={12} sx={{ display: 'flex', position: 'relative' }}>
								<ExpandMoreIcon sx={{ color: 'black', right: 10, top: 15, position: 'absolute' }} />
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									name="french_as_first_language"
									select
									fullWidth
									size="small"
									color="secondary"
									label="Est-ce que le français est votre langue maternelle ?"
									SelectProps={{
										style: {
											color: 'black',
										},
										IconComponent: () => null,
									}}
									onChange={(e) => {
										e.preventDefault();
										onChange(e);
									}}
								>
									{['Oui', 'Non'].map((item, index) => {
										return (
											<MenuItem sx={{ color: 'white' }} value={index === 0 ? 'true' : 'false'}>
												{item}
											</MenuItem>
										);
									})}
								</TextField>
							</Grid>
						</Grid>
						<FormControlLabel
							sx={{
								mt: 1,
								ml: 0.5,
								color: 'black',
								fontFamily: 'Baskerville',
								'&.MuiFormControlLabel-label': {
									fontSize: 1,
									lineHeight: '1.5rem',
								},
							}}
							label="Je consens à ce que mes données soient utilisées pour l’update vocale."
							control={
								<Checkbox
									color="secondary"
									checked={checked}
									onChange={handleChange}
									inputProps={{ 'aria-label': 'controlled' }}
									sx={{
										color: 'black',
										'& .MuiSvgIcon-root': { fontSize: 18 },
									}}
								/>
							}
						/>
						{/*<FormControlLabel
								sx={{
									mt: 1,
									ml: 0.5,
									color: 'black',
									fontFamily: 'Baskerville',
									'&.MuiFormControlLabel-label': {
										fontSize: 1,
										lineHeight: '1.5rem',
									},
								}}
								label="Je confirme avoir 18 ans ou, je suis mineur, participer avec l'autorisation de mon tuteur légal"
								control={
									<Checkbox
										color="secondary"
										checked={consentChecked}
										onChange={handleConsentChange}
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{
											color: 'black',
											'& .MuiSvgIcon-root': { fontSize: 18 },
										}}
									/>
								}
							/>*/}
						{referral && (
							<Grid item xs={12} sm={12}>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									disabled
									name="referral"
									color="secondary"
									size="small"
									label="Code de référence"
									value={referral}
								/>
							</Grid>
						)}
						<Button
							disabled={!loading ? loading : !loading}
							fullWidth
							variant="contained"
							style={{
								textTransform: 'uppercase',

								color: 'black',
								background: 'none',
								border: 'none',
								fontWeight: 900,
								fontSize: '1.5rem',
								lineHeight: 0.5,
								fontFamily: 'Anton',
								letterSpacing: '-2px',
							}}
							onClick={handleRegister}
						>
							{"S'inscrire"}
						</Button>

						<Typography
							sx={{
								fontSize: '0.9rem',

								textTransform: 'uppercase',
								color: 'black',
								fontFamily: 'baskerville',
							}}
						>
							{' déjà un compte? '}
							<Link
								style={{
									textDecoration: 'none',
									color: 'inherit',
									fontWeight: 'bold',
								}}
								to="/login"
							>
								{" S'identifier"}
							</Link>
						</Typography>
					</Stack>
				</Container>
			</Box>
			{/*<div className={classes.view}>
			<div className={classes.padding}>
				<Paper className={classes.margin}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
					>
						<Typography
							textAlign="center"
							component="h1"
							sx={{
								//color: "#f9d13e",
								fontSize: { xs: '2rem', md: '3rem' },
								fontWeight: 600,
								mb: 2,
								letterSpacing: 1,
							}}
						>
							{'Bienvenue !'}
						</Typography>
						<Typography component="h2" sx={{ color: '#e5e7eb', mb: 2 }}>
							{"S'inscrire pour commencer"}
						</Typography>
						
					</Box>
				</Paper>
			</div>
									</div>*/}
		</>
	);
};

export default withCookies(Register);
