import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from '../globalStyles';
import {
	InfoSec,
	InfoRow,
	InfoColumn,
	TextWrapper,
	TopLine,
	Heading,
	Subtitle,
	ImgWrapper,
	Img,
} from './InfoSection.elements';
import { FooterSubText } from './Footer.elements';
import MainAnimation from './MainAnimation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPlayer from 'react-player';
let ismounted = false;

function InfoSection({
	primary,
	lightBg,
	topLine,
	lightTopLine,
	lightText,
	lightTextDesc,
	headline,
	description,
	buttonLabel,
	img,
	alt,
	imgStart,
	start,
	path,
	addBtn,
	isForm,
}) {
	useEffect(() => {
		AOS.init();
	}, []);
	useEffect(() => {
		const element = document.getElementById('app_hubspotForm');
		if (element) element.remove();

		const script = document.createElement('script');
		script.src = 'https://js.hsforms.net/forms/v2.js';
		script.id = 'app_hubspotForm';
		document.body.appendChild(script);

		script.addEventListener('load', () => {
			// @TS-ignore
			if (window.hbspt) {
				// @TS-ignore
				window.hbspt.forms.create({
					portalId: '23267409',
					formId: 'fb5cb7fe-fb6a-4283-8bd7-5cac12ba6dc0',
					target: '#hubspotForm',
				});
			}
		});
		ismounted = true;
	}, []);
	return (
		<>
			<InfoSec id="container" lightBg={lightBg}>
				<MainAnimation />
				<Container>
					{!isForm ? (
						<InfoRow imgStart={imgStart}>
							<InfoColumn data-aos="slide-right">
								<TextWrapper>
									<TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
									<Heading lightText={lightText}>{headline}</Heading>
									<Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
									{addBtn && (
										<Link to={path}>
											<Button big fontBig primary={primary}>
												{buttonLabel}
											</Button>
										</Link>
									)}
								</TextWrapper>
							</InfoColumn>
							<InfoColumn data-aos="fade-up-left">
								<ImgWrapper start={start}>
									{alt === 'video' ? (
										<ReactPlayer url="https://youtu.be/8WyDNLCqukE" width="100%" height="25rem" />
									) : (
										<Img src={img} alt={alt} />
									)}
								</ImgWrapper>
							</InfoColumn>
						</InfoRow>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<FooterSubText style={{ color: 'black' }}>Contactez-nous</FooterSubText>
							<div style={{ width: '100%', color: 'yellow' }}>
								<div style={{ color: 'yellow' }} id="hubspotForm"></div>
							</div>
						</div>
					)}
				</Container>
			</InfoSec>
		</>
	);
}

export default InfoSection;
