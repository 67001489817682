import React, { useState, useEffect } from 'react';
import {
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Checkbox,
} from '@mui/material';
import { IsAny } from 'react-hook-form';

const CTable = ({
	selectedDataIds = [],
	setSelectedDataIds,
	columns,
	data = [],
	limit,
	selectable = false,
}: any) => {
	const [defaultLimit, setDefaultLimit] = useState(5);
	const [page, setPage] = useState(0);
	const handleLimitChange = (event: any) => {
		setDefaultLimit(event.target.value);
	};
	const handlePageChange = (event: any, newPage: any) => {
		setPage(newPage);
		//setForceRedraw(!forceRedraw);
	};
	useEffect(() => {
		setPage(0);
	}, [data]);
	useEffect(() => {
		if (limit) setDefaultLimit(limit);
	}, [limit]);

	const handleSelectAll = (event: any) => {
		let userIds = [];

		if (event.target.checked) {
			userIds = data.map((el: any) => el.id);
			if (selectedDataIds.length > 0) {
				let concatainedArrays = userIds.concat(selectedDataIds);
				//remove duplicate values if any
				userIds = concatainedArrays.filter(
					(item: string, pos: number) => concatainedArrays.indexOf(item) === pos
				);
			}
		}
		/*if (selectedDataIds.length > 0) 
		setSelectedDataIds(selectedDataIds.concat(userIds));
		else */
		setSelectedDataIds(userIds);
	};

	const handleSelectOne = (event: any, id: any) => {
		const selectedIndex = selectedDataIds.indexOf(id);
		let userIds: any[] = [];

		if (selectedIndex === -1) {
			userIds = userIds.concat(selectedDataIds, id);
		} else if (selectedIndex === 0) {
			userIds = userIds.concat(selectedDataIds.slice(1));
		} else if (selectedIndex === selectedDataIds.length - 1) {
			userIds = userIds.concat(selectedDataIds.slice(0, -1));
		} else if (selectedIndex > 0) {
			userIds = userIds.concat(
				selectedDataIds.slice(0, selectedIndex),
				selectedDataIds.slice(selectedIndex + 1)
			);
		}

		setSelectedDataIds(userIds);
	};
	return (
		<TableContainer style={{ maxHeight: '100%' }}>
			<Table>
				<TableHead>
					{' '}
					<TableRow>
						{selectable && (
							<TableCell padding="checkbox">
								<Checkbox
									sx={{ color: 'grey' }}
									checked={selectedDataIds.length === data.length}
									color="primary"
									/*indeterminate={
									selectedCustomerIds.length > 0 && selectedCustomerIds.length < customers.length
								}*/
									onChange={handleSelectAll}
								/>
							</TableCell>
						)}
						{columns &&
							columns.map((el: any, index: number) => (
								<TableCell sx={{ color: 'black' }}>{el.name}</TableCell>
							))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data
						.slice(page * defaultLimit, (page + 1) * defaultLimit)
						.map((element: any, idx: number) => {
							return (
								<TableRow
									hover
									key={element.id}
									selected={selectedDataIds.indexOf(element.id) !== -1}
								>
									{selectable && (
										<TableCell padding="checkbox">
											<Checkbox
												sx={{ color: 'grey' }}
												checked={selectedDataIds.indexOf(element.id) !== -1}
												onChange={(event) => handleSelectOne(event, element.id)}
												value="true"
											/>
										</TableCell>
									)}

									{columns &&
										columns.map((col: any, cidx: number) => (
											<TableCell sx={{ color: 'black' }}>
												{columns[cidx].renderCell
													? columns[cidx].renderCell(element, element[columns[cidx].field])
													: element[columns[cidx].field]}
											</TableCell>
										))}
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			<TablePagination
				sx={{
					color: 'black',

					'&.MuiTablePagination-root': {
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
					},
				}}
				component="div"
				count={data.length}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleLimitChange}
				page={page}
				rowsPerPage={defaultLimit}
				rowsPerPageOptions={[5, 10, 25]}
			/>
		</TableContainer>
	);
};

export default CTable;
