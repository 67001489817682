import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import AdminPageSideBar from './admin/AdminPageSideBar';
import PrimaryAppBar from './PrimaryAppBar';
const DashboardLayoutRoot = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: '1 1 auto',
	maxWidth: '100%',
	minHeight: '100%',
	/*backgroundColor: 'white',*/
	//paddingTop: 64,
	[theme.breakpoints.up('lg')]: {
		paddingLeft: 280,
	},
}));

const AdminLayout = ({ onLogout, children }: any) => {
	const theme = useTheme();
	const isMatch = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<>
			<DashboardLayoutRoot>
				<PrimaryAppBar onLogout={onLogout} />
				<Box
					sx={{
						display: 'flex',
						flex: '1 1 auto',
						flexDirection: 'column',
						width: '100%',
						//minHeight: '100%',
					}}
				>
					{children}
				</Box>
			</DashboardLayoutRoot>
			{!isMatch && <AdminPageSideBar />}
		</>
	);
};

export default AdminLayout;
