import React, { useContext, useRef, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
} from '@mui/material';

type UseModalShowReturnType = {
	show: boolean;
	setShow: (value: boolean) => void;
	onHide: (event: any, reason: any) => void;
};

const useModalShow = (): UseModalShowReturnType => {
	const [show, setShow] = useState(false);

	const handleOnHide = (event: any, reason: any) => {
		if (reason && reason === 'backdropClick') return;
		setShow(false);
	};

	return {
		show,
		setShow,
		onHide: handleOnHide,
	};
};

type ModalContextType = {
	showConfirmation: (
		title: string,
		message: string | JSX.Element,
		okBtnText?: string,
		cancelBtnText?: string
	) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
	children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (props) => {
	const { setShow, show, onHide } = useModalShow();
	const [content, setContent] = useState<{
		title: string;
		message: string | JSX.Element;
		okBtnText?: string;
		cancelBtnText?: string;
	} | null>();
	const resolver = useRef<Function>();
	const handleShow = (
		title: string,
		message: string | JSX.Element,
		okBtnText?: string,
		cancelBtnText?: string
	): Promise<boolean> => {
		setContent({
			title,
			message,
			okBtnText,
			cancelBtnText,
		});
		setShow(true);
		return new Promise(function (resolve) {
			resolver.current = resolve;
		});
	};

	const modalContext: ModalContextType = {
		showConfirmation: handleShow,
	};

	const handleOk = () => {
		resolver.current && resolver.current(true);
		onHide(null, null);
	};

	const handleCancel = () => {
		resolver.current && resolver.current(false);
		onHide(null, null);
	};
	return (
		<ConfirmationModalContext.Provider value={modalContext}>
			{props.children}

			{content && (
				<Dialog
					/*sx={{
						'& .MuiDialogTitle-root': {
							backgroundColor: '#113552',
							color: 'white',
							height: '45px',
							padding: '8px',
							fontWeight: 500,
							fontSize: '1.5rem',
						},
					}}*/
					fullWidth
					open={show}
					onClose={onHide}
					maxWidth={'sm'}
				>
					<DialogTitle>{content.title}</DialogTitle>
					<DialogContent>{content.message}</DialogContent>

					<DialogActions>
						<Button color="primary" variant="contained" onClick={handleOk}>
							{content.okBtnText ? content.okBtnText : 'OK'}
						</Button>
						<Button onClick={handleCancel}>
							{content.cancelBtnText ? content.cancelBtnText : 'Annuler'}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</ConfirmationModalContext.Provider>
	);
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export { useModalShow, useConfirmationModalContext };

export default ConfirmModalContextProvider;
