import React from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	TextField,
	InputAdornment,
	SvgIcon,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
interface NotificationListToolbarProps {
	createNotification: () => void;
}

const NotificationListToolbar = ({ createNotification }: NotificationListToolbarProps) => {
	return (
		<Box>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
					m: -1,
				}}
			>
				<Typography color="black" sx={{ m: 1 }} variant="h4">
					Centre de Notifications
				</Typography>
				<Box sx={{ m: 1 }}>
					<Button
						onClick={createNotification}
						color="primary"
						startIcon={<AddIcon />}
						variant="contained"
					>
						Nouvelle notification
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default NotificationListToolbar;
