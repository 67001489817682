import React from 'react';
import { IAudioRecordingText } from '../../../data/IAudioRecording';
import { VolumeUp, Stop, PlayArrow } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import {blue,red, grey } from '@mui/material/colors';
import usePlayer from '../../hooks/usePlayer';
import request from '../../../helpers/myAxios';
import { AxiosResponse } from 'axios';
import { isSafari } from 'react-device-detect';

interface AudioRecordingTextPlayerProps {
	recordingSessionId: string | undefined;
	data: IAudioRecordingText;
	isSelected: boolean;
	showText: boolean;
	idx: number;
	disablePlaying: boolean;
	onPlay: (audioFileName: string) => void;
	onStop: (audioFileName: string) => void;
}

const AudioRecordingTextPlayer = (props: AudioRecordingTextPlayerProps) => {
	const { playing, playPause, audioDuration, audioPosition, setUrl, url } = usePlayer();

	const [audioTimes, setAudioTimes] = React.useState('');

	React.useEffect(() => {
		let pos = '';
		if (audioPosition)
			pos =
				Math.floor(audioPosition / 60)
					.toString()
					.padStart(2, '0') +
				':' +
				Math.floor(audioPosition % 60)
					.toString()
					.padStart(2, '0');

		let duration = '';
		if (audioDuration)
			duration =
				Math.floor(audioDuration / 60)
					.toString()
					.padStart(2, '0') +
				':' +
				Math.floor(audioDuration % 60)
					.toString()
					.padStart(2, '0');

		if (!duration && props.data.duration)
			duration =
				Math.floor(props.data.duration / 60)
					.toString()
					.padStart(2, '0') +
				':' +
				Math.floor(props.data.duration % 60)
					.toString()
					.padStart(2, '0');

		if (pos) setAudioTimes(pos + '/' + duration);
		else if (duration) setAudioTimes('00:00/' + duration);
		else setAudioTimes('');
	}, [audioDuration, audioPosition, props.data.duration]);

	React.useEffect(() => {
		if (url && props.data.localUrl && !isSafari) {
			setUrl(playing ? props.data.localUrl : '');
		}
	}, [props.data.localUrl]);

	React.useEffect(() => {
		if (props.disablePlaying && playing) {
			playPause();
		}
	}, [props.disablePlaying]);

	React.useEffect(() => {
		if (url) {
			if (playing) props.onPlay(props.data.audioFilename);
			else props.onStop(props.data.audioFilename);
		}
	}, [playing]);

	const handlePlay = () => {
		if (!url) {
			const req = request();
			req
				.get('/audiorecording/' + props.recordingSessionId, {
					params: { audioFileName: props.data.audioFilename, type: isSafari ? 'wav' : 'webm' },
					responseType: 'blob',
				})
				.then((res: AxiosResponse) => {
					setUrl(URL.createObjectURL(res.data));
				});
		} else playPause();
	};

	return (
		<Stack
			direction="row"
			alignItems="center"
			sx={{
				border: 1,
				borderColor: grey[900],
				pl: 1,
				width: '100%',
				backgroundColor: props.isSelected
					? '#1e1e1e29'
					: props.data.deleted && props.showText
					? red[600]
					: props.data.completed
					? 'black'
					: null,
			}}
		>
			<Typography
				variant="caption"
				sx={{ pt: '3px', width: 20, color: props.data.completed ? null : grey[500] }}
			>
				{props.idx + 1}:
			</Typography>
			{props.data.completed && !props.data.deleted ? (
				<IconButton
					disabled={props.disablePlaying}
					onClick={handlePlay}
					size="small"
					sx={{ p: '4px' }}
				>
					{playing ? <Stop fontSize="small" /> : <PlayArrow fontSize="small" />}
				</IconButton>
			) : null}
			{props.data.completed ? (
				<Typography variant="caption" sx={{ pt: '3px' }}>
					{audioTimes}
				</Typography>
			) : null}
			{playing ? <VolumeUp sx={{ ml: '3px' }} /> : null}
			{props.showText ? (
				<Typography variant="caption" sx={{ ml: 3, pt: '3px' }}>
					{props.data.text}
				</Typography>
			) : (
				''
			)}
		</Stack>
	);
};

export default AudioRecordingTextPlayer;
