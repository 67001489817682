import React from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
const FullScreenLoader = () => {
	return (
		<Container sx={{ height: '95vh' }}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				sx={{ height: '100%', backgroundColor: 'none' }}
			>
				<CircularProgress color="primary" size={50} />
			</Box>
		</Container>
	);
};

export default FullScreenLoader;
