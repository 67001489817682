import React, { useEffect, useState } from 'react';
import {
	Box,
	Card,
	Grid,
	CardContent,
	Typography,
	Container,
	Backdrop,
	CircularProgress,
	Button,
	Tooltip,
	IconButton,
	Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CTable from './table/Table';
import NotificationListToolbar from './admin/NotificationListToolbar';
import useRequest from './hooks/useRequest';
import { useNotify } from './providers/NotifyProvider';
import moment from 'moment';
import { Edit, Send } from '@mui/icons-material';
import { useConfirmationModalContext } from './providers/ConfirmModalContext';
const Notification = () => {
	const [notificationList, setNotificationList] = useState([]);
	const [forceRedraw, setForceRedraw] = useState(false);
	const notify = useNotify();
	const { doRequest, errors, loading } = useRequest();
	const navigate = useNavigate();
	const modalContext = useConfirmationModalContext();
	useEffect(() => {
		getNotificationList();
	}, [forceRedraw]);

	const navigateToNewNotification = () => {
		navigate('/admin/notification/new');
	};
	useEffect(() => {
		notify.error(errors);
	}, [errors]);

	const handleDeleteNotif = async (notification: any) => {
		const result = await modalContext.showConfirmation(
			'Confirmation',

			<p>
				Voulez-vous vraiment supprimer la notification :
				<strong>{`${notification.name} ${notification.description ? ' (' + notification.description + ') ' : ''}`}</strong>?
			</p>,

			'Oui',
			'Non'
		);
		if (result) {
			deleteNotification(notification.id);
		}
	};

	const deleteNotification = async (id: string) => {
		await doRequest({
			url: `/notification/${id}`,
			method: 'delete',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					setForceRedraw(!forceRedraw);
				} else {
					//notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	const handleResendNotif = async (notification: any) => {
		const result = await modalContext.showConfirmation(
			'Confirmation',

			<p>
				Voulez-vous vraiment renvoyer un courriel{' '}
				<strong>{`${notification.name} ${notification.description ? ' (' + notification.description + ') ' : ''}`}</strong>? Vous
				pouvez modifier les destinataires du courriel à tout moment.
			</p>,

			'Oui',
			'Non'
		);
		if (result) {
			resendNotification(notification.id);
		}
	};
	const updateNotificationStatus = async (event: any, item: any) => {
		await doRequest({
			url: `/notification/${item.id}`,
			method: 'put',
			body: {
				...item,
				status: event.target.checked ? 'enabled' : 'disabled',
			},
			onSuccess: (res: any) => {
				if (res) {
					setForceRedraw(!forceRedraw);
				} else {
					//notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	const getNotificationList = async () => {
		await doRequest({
			url: '/notification/',
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					setNotificationList(res.notifications);
					//setUserCount(res.result);
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	const resendNotification = async (id: string) => {
		await doRequest({
			url: `/notification/resend/${id}`,
			method: 'post',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					notify.success('Notification envoyé.');
					//setUserCount(res.result);
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};
	return (
		<Box
			component="main"
			sx={{
				backgroundColor: '#F9FAFC',
				flexGrow: 1,
				py: 8,
			}}
		>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
				//onClick={handleClose}
			>
				<Box sx={{ position: 'relative', display: 'inline-flex' }}>
					<CircularProgress variant="determinate" size={30} />
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="caption" component="div" color="text.secondary">
							{'chargement...'}
						</Typography>
					</Box>
				</Box>
			</Backdrop>
			<Container maxWidth={false}>
				<NotificationListToolbar createNotification={navigateToNewNotification} />
				<Grid container spacing={3}>
					<Grid item lg={12} sm={12} xl={12} xs={12}>
						<Card sx={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
							<CardContent sx={{ height: '100%', width: '100%' }}>
								<CTable
									columns={[
										{
											name: '',
											renderCell: (params: any, field: string) => (
												<IconButton
													onClick={() => {
														handleDeleteNotif(params);
													}}
												>
													<DeleteIcon color="error" />
												</IconButton>
											),
										},
										{ name: 'Nom', field: 'name' },
										{ name: 'Description', field: 'description' },
										{
											name: 'Message',
											field: 'message',
											width: 200,
											renderCell: (params: any, field: string) => {
												return (
													<Tooltip arrow title={field}>
														<div
															style={{
																overflow: 'hidden',
																textOverflow: 'ellipsis',
																width: '15rem',
															}}
														>
															<Typography noWrap>{field}</Typography>
														</div>
													</Tooltip>
												);
											},
										},

										{
											name: 'Date de création',
											field: 'created_at',
											renderCell: (params: any, date: string) => {
												return moment(date).format('YYYY-MM-DD HH:MM:SS');
											},
										},
										{
											name: 'Expire le',
											renderCell: (params: any, date: string) => {
												return moment(date).format('YYYY-MM-DD HH:MM:SS');
											},
											field: 'expirationDate',
										},

										{
											name: 'Dernier envoi',
											renderCell: (params: any, date: string) => {
												return date !== null ? moment(date).format('YYYY-MM-DD HH:MM:SS') : '';
											},
											field: 'last_sent_date',
										},
										{
											name: 'Actif',
											field: 'status',
											renderCell: (params: any, field: string) => {
												return (
													<Checkbox
														sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'grey' }}
														checked={field === 'enabled' ? true : false}
														onClick={(e: any) => {
															updateNotificationStatus(e, params);
														}}
													/>
												);
											},
										},
										{
											name: 'Action(s)',
											renderCell: (params: any, field: any) => (
												<Box
													sx={{
														display: 'flex',
														alignItems: 'flex-start',
														width: '100%',
														mr: 1,
													}}
												>
													<Button
														sx={{ mr: 2 }}
														onClick={() => {
															navigate(`${params.id}`);
														}}
														//sx={{ cursor: 'pointer' }}
														color="secondary"
														variant="outlined"
														size="small"
														endIcon={<Edit />}
													>
														Modifier
													</Button>

													<Button
														onClick={() => {
															handleResendNotif(params);
														}}
														//sx={{ cursor: 'pointer' }}
														//color="error"
														variant="outlined"
														size="small"
														endIcon={<Send />}
													>
														Renvoyer
													</Button>
												</Box>
											),
										},
									]}
									data={notificationList}
									limit={25}
								/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Notification;
