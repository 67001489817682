import React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoneyIcon from '@mui/icons-material/Money';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
const User = (props: any) => {
	let message = '';
	if (props.title === 'Utilisateur') {
	}
	return (
		<Card sx={{ height: '100%', backgroundColor: 'white' }} {...props}>
			<CardContent>
				<Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
					<Grid item>
						<Typography sx={{ color: 'black' }} gutterBottom variant="overline">
							{props.title}
						</Typography>
						<Typography sx={{ color: 'black' }} variant="h4">
							{props.count}
						</Typography>
					</Grid>
					<Grid item>
						<Avatar
							sx={{
								backgroundColor: `${props.bgc}`,
								height: 56,
								width: 56,
							}}
						>
							{props.icon}
						</Avatar>
					</Grid>
				</Grid>
				<Box
					sx={{
						pt: 2,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{props.completedCount !== undefined && (
						<>
							{props.completedCount > 0 && <ArrowUpwardIcon color="success" />}
							<Typography
								sx={{
									mr: 1,
									color: 'green',
								}}
								variant="body2"
							>
								{props.completedCount}
							</Typography>
						</>
					)}
					{props.label && (
						<Typography sx={{ color: 'black' }} variant="caption">
							{props.label}
						</Typography>
					)}
				</Box>
			</CardContent>
		</Card>
	);
};

export default User;
