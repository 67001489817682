import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	Typography,
	Select,
	TextField,
	DialogActions,
	Button,
	FormGroup,
	IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import request from '../../../helpers/myAxios';
import { useNotify } from '../../providers/NotifyProvider';
import { AxiosError, AxiosResponse } from 'axios';
import { LinkedinShareButton, FacebookShareButton, FacebookMessengerShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon, FacebookMessengerIcon } from 'react-share';

import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

type Props = {
	open: boolean;
	closeModal: () => void;
};
interface IObjectKeys {
	[key: string]: string | number | undefined;
}

interface InvitInfo extends IObjectKeys {
	email: string;
}
const maxInputFields = 50;
const defaultNumberOfFields = 1;

const InviteFreind = (props: Props) => {
	const [numberOfFields, setNumberOfFields] = useState(defaultNumberOfFields);
	const [inputFields, setInputFields] = useState<Array<InvitInfo>>([{ email: '' }]);
	const [submiting, setSubmiting] = useState(false);
	const notify = useNotify();
	const handleButtonClick = () => {
		if (numberOfFields < maxInputFields) {
			setNumberOfFields(numberOfFields + 1);
			let newfield = { email: '' };
			setInputFields([...inputFields, newfield]);
		} else {
			notify.info(`Maximum ${maxInputFields} invitations en même temps.`);
		}
	};

	const removeFields = (index: number) => {
		if (numberOfFields > defaultNumberOfFields) {
			let data = [...inputFields];
			data.splice(index, 1);
			setInputFields(data);
			setNumberOfFields(numberOfFields - 1);
		}
	};

	const handleClose = () => {
		props.closeModal();
		setNumberOfFields(defaultNumberOfFields);
		setInputFields([{ email: '' }]);
	};

	const handleFormChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		let data = [...inputFields];
		data[index][event.target.name] = event.target.value;
		setInputFields(data);
	};

	const submit = (e: any) => {
		e.preventDefault();
		setSubmiting(true);
		let payload = {
			invitation_list: inputFields,
		};
		const req = request();
		req.post('/invit/', payload)
			.then((res: AxiosResponse) => {
				handleClose();
				notify.success('Invitation(s) envoyée(s).');
				setSubmiting(false);
			})
			.catch((err: AxiosError) => {
				notify.error("Impossible d'envoyer les invitations. Veuillez réessayer plus tard. ");
				setSubmiting(false);
			});
	};
	return (
		<Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
			<DialogTitle
				style={{
					backgroundColor: 'black',
					textTransform: 'uppercase',
					display: 'flex',
					justifyContent: 'center',
					fontWeight: 900,
					fontFamily: 'Anton',
				}}
			>
				{'Inviter des amis'}
			</DialogTitle>
			<DialogContent style={{ backgroundColor: '#EDEAE6' }}>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<div
						style={{
							marginTop: '8px',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'flex-start',
						}}
					>
						<p
							style={{
								fontFamily: 'Baskerville',
								color: 'black',
								textTransform: 'uppercase',
								fontSize: '0.7rem',
								marginTop: 20,
								fontWeight: 900,
							}}
						>
							Partager via :
						</p>
						<div style={{ marginLeft: '20px' }}>
							<FacebookShareButton
								style={{ marginRight: '8px' }}
								url={`https://updatevocal.com/`}
								quote={"Concours d'Imagem : Cours la chance de gagner un MacBook Air"}
								hashtag={'#ImagemConcours'}
								title={"Concours d'Imagem : Cours la chance de gagner un MacBook Air"}
							>
								<FacebookOutlinedIcon style={{ color: 'black', height: '40px', width: '40px' }} />
							</FacebookShareButton>
							<LinkedinShareButton
								style={{ marginRight: '8px' }}
								url={`https://updatevocal.com/`}
								title={"Concours d'Imagem : Cours la chance de gagner un MacBook Air"}
								//hashtag={'#ImagemConcours'}
								summary={"Concours d'Imagem : Cours la chance de gagner un MacBook Air"}
							>
								<LinkedInIcon style={{ color: 'black', height: '40px', width: '40px' }} />
							</LinkedinShareButton>
						</div>
					</div>
					<form onSubmit={submit}>
						{inputFields.map((input, index) => {
							return (
								<div key={index} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
									<TextField
										sx={{
											mt: 2,
											'& fieldset': {
												paddingLeft: (theme) => theme.spacing(2.5),
												borderRadius: '30px',
											},
										}}
										fullWidth
										name="email"
										size="small"
										variant="outlined"
										label={`Courriel ${index + 1}`}
										color="secondary"
										InputLabelProps={{
											style: {
												color: '#1E1E1E',
												fontFamily: 'Baskerville',
												textTransform: 'uppercase',
											},
										}}
										inputProps={{
											style: {
												color: '#1E1E1E',
												fontFamily: 'Baskerville',
												borderRadius: '18px',
											},
										}}
										value={input.courriel}
										onChange={(event) => handleFormChange(index, event)}
									/>
									<IconButton onClick={handleButtonClick} sx={{ mt: 2 }}>
										<AddCircleSharpIcon sx={{ color: 'black', width: '25px', height: '25px' }} />
									</IconButton>
									<IconButton onClick={() => removeFields(index)} sx={{ mt: 2 }}>
										<RemoveCircleOutlineOutlinedIcon sx={{ color: 'black', width: '25px', height: '25px' }} />
									</IconButton>
								</div>
							);
						})}
					</form>
				</div>
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#EDEAE6' }}>
				<Button
					style={{
						borderRadius: '60px',
						fontFamily: 'Anton',
						textTransform: 'uppercase',
						backgroundColor: 'black',
						color: 'white',
						fontWeight: 900,
						height: '30px',
					}}
					disabled={submiting}
					variant="contained"
					onClick={submit}
				>
					{submiting ? 'En cours...' : 'Inviter'}
				</Button>
				<Button
					style={{
						borderRadius: '60px',
						fontFamily: 'Anton',
						textTransform: 'uppercase',
						backgroundColor: '#EDEAE6',
						border: '1px solid black',
						color: 'black',
						fontWeight: 900,
						height: '30px',
					}}
					disabled={submiting}
					variant="contained"
					onClick={handleClose}
				>
					Annuler
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InviteFreind;
