import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme } from '@mui/material';
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js';

export const registerChartJs = () => {
	Chart.register(
		ArcElement,
		BarElement,
		CategoryScale,
		Legend,
		LineElement,
		LinearScale,
		PointElement,
		Title,
		Tooltip
	);
};

registerChartJs();
const UserChart = (props: any) => {
	const theme = useTheme();
	const data = {
		datasets: [
			{
				backgroundColor: '#3F51B5',
				barPercentage: 1,
				barThickness: 12,
				borderRadius: 4,
				categoryPercentage: 1,
				data: props.data ? props.data : [],
				label: "Nombre d'utilisateur",
				maxBarThickness: 10,
			},
		],
		labels: [
			'01 - BAS-ST-LAURENT',
			'02 - SAGUENAY-LAC-SAINT-JEAN',
			'03 - CAPITALE-NATIONALE (QUÉBEC)',
			'04 - MAURICIE',
			'05 - ESTRIE',
			'06 - MONTRÉAL',
			'07 - OUTAOUAIS',
			'08 - ABITIBI-TÉMISCAMINGUE',
			'09 - CÔTE-NORD',
			'10 - NORD-DU-QUEBEC',
			'11 - GASPÉSIE–ÎLES-DE-LA-MADELEINE',
			'12 - CHAUDIÈRE-APPALACHES',
			'13 - LAVAL',
			'14 - LANAUDIÈRE',
			'15 - LAURENTIDES',
			'16 - MONTÉRÉGIE',
			'17 - CENTRE-DU-QUÉBEC',
			'Autre',
		],
	};
	const options = {
		//animation: false,
		cornerRadius: 20,
		layout: { padding: 0 },
		legend: { display: false },
		maintainAspectRatio: false,
		responsive: true,
		xAxes: [
			{
				ticks: {
					fontColor: theme.palette.text.secondary,
				},
				gridLines: {
					display: false,
					drawBorder: false,
				},
			},
		],
		yAxes: [
			{
				ticks: {
					fontColor: theme.palette.text.secondary,
					beginAtZero: true,
					min: 0,
				},
				gridLines: {
					borderDash: [2],
					borderDashOffset: [2],
					color: theme.palette.divider,
					drawBorder: false,
					zeroLineBorderDash: [2],
					zeroLineBorderDashOffset: [2],
					zeroLineColor: theme.palette.divider,
				},
			},
		],
		tooltips: {
			backgroundColor: theme.palette.background.paper,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: 'index',
			titleFontColor: theme.palette.text.primary,
		},
	};
	return (
		<Card sx={{ backgroundColor: 'white' }} {...props}>
			<CardHeader sx={{ color: 'black' }} title="Utilisateurs par région" />
			<Divider />
			<CardContent>
				<Box
					sx={{
						height: 400,
						position: 'relative',
					}}
				>
					<Bar data={data} options={options} />
				</Box>
			</CardContent>
		</Card>
	);
};

export default UserChart;
