import {
	Alert,
	Box,
	FormControlLabel,
	Grid,
	Card,
	CardContent,
	IconButton,
	Tooltip,
	List,
	ListItemButton,
	Switch,
	TextField,
	Typography,
	Container,
	useMediaQuery,
	useTheme,
	Divider,
	CircularProgress,
	Backdrop,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import HelpIcon from '@mui/icons-material/HelpOutline';
import HelpModal, { useHelp } from '../../providers/HelpModal';
import React, { useEffect, useState, useRef } from 'react';
import request from '../../../helpers/myAxios';
import { useNotify } from '../../providers/NotifyProvider';

import background from '../../../images/bg-textrecorder.png';

import { sessionClear } from '../../../helpers/session';
import { useNavigate } from 'react-router-dom';
import { SkipPrevious, SkipNext, FiberManualRecord, ChangeCircle, StopCircle } from '@mui/icons-material';
import MicIcon from '@mui/icons-material/Mic';
import { green, grey, red } from '@mui/material/colors';
import { IAudioRecordingBook } from '../../../data/IAudioRecording';
import useAudioRecorder from '../../hooks/useAudioRecorder';
import Tuto from '../Dialog/Tuto';
import { AxiosError } from 'axios';
import parse from 'html-react-parser';
import VoiceMeter from './VoiceMeter';
import AudioRecordingTextPlayer from './AudioRecordingTextPlayer';
import MicrophonePickerDialog from './MicrophonePickerDialog';
import getBlobDuration from 'get-blob-duration';
import { useAudioRecordingSession } from '../../providers/AudioRecordingSessionProvider';
import { useUserDetails } from '../../providers/UserDetailsProvider';
import { useConfirmationModalContext } from '../../providers/ConfirmModalContext';
import bluetickSrc from '../../../images/Bluetick.png';
import useRequest from '../../hooks/useRequest';
import Slider from '../../Slider';
interface TextRecordProps {}

{
	/*const slides = [
	"Le concours est actif du 15 novembre 2022 au 31 mai 2023 ou peut être extensionné jusqu’à ce que nous ayons atteint le nombre requis d'enregistrements (500 heures)",
	'Nous vous remercions de contribuer à améliorer davantage notre technologie',
	'Dicte et cours la chance de gagner jusqu’à 1000$ !',
];*/
}

const slides = [
	'Merci de soutenir une entreprise d’ici  *  Merci de soutenir une entreprise d’ici  *  Merci de soutenir une entreprise d’ici * Merci de soutenir une entreprise d’ici  *  Merci de soutenir une entreprise d’ici',
];

const shortCutList = [
	{
		title: "Démarrer l'enregistrement",
		label: 'flèche vers le bas',
		listicon: <KeyboardArrowDownIcon sx={{ width: '25px', height: '25px', color: 'white' }} />,
	},
	{
		title: "Arrêter l'enregistrement",
		label: 'flèche vers le bas',
		listicon: <KeyboardArrowDownIcon sx={{ width: '25px', height: '25px', color: 'white' }} />,
	},
	{
		title: "Recommencer l'enregistrement",
		label: 'flèche vers le bas',
		listicon: <KeyboardArrowDownIcon sx={{ width: '25px', height: '25px', color: 'white' }} />,
	},
	{
		title: 'Dictée précédente',
		label: 'flèche vers la gauche',
		listicon: <KeyboardArrowLeftIcon sx={{ width: '25px', height: '25px', color: 'white' }} />,
	},
	{
		title: 'Dictée suivante',
		label: 'flèche vers la droite',
		listicon: <KeyboardArrowRightIcon sx={{ width: '25px', height: '25px', color: 'white' }} />,
	},
];

const TextRecorder = (props: TextRecordProps) => {
	const notify = useNotify();
	const session = useUserDetails();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMatch = useMediaQuery(theme.breakpoints.down('md'));
	const [slideText, setSlideText] = useState(0);
	//const [forceRedraw, setForceRedraw] = useState(false);
	const user = session.userDetails;
	const { doRequest, errors, loading } = useRequest();

	const [width, setWidth] = useState<number>(window.innerWidth);
	const mainRef = useRef<HTMLInputElement>(null);
	const help = useHelp();

	const handleScroll = (ref: any) => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	};

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const { startRecording, stopRecording, recordingTime, isRecording, isRecordingRef, recordingBlob, previewAudioStream } =
		useAudioRecorder();

	const {
		audioRecordingSession,
		setAudioRecordingSession,
		getCurrentAudioRecordingText,
		getCurrentAudioRecordingTextContent,
		nextAudioRecordingText,
		previousAudioRecordingText,
		getTotalAudioDuration,
		getTotalAudioDurationText,
		resetRecordingSession,
	} = useAudioRecordingSession();

	const [playingAudioFileName, setPlayingAudioFileName] = React.useState<string>('');
	const [shouldSave, setShouldSave] = React.useState<'save' | 'save_next' | 'save_previous' | ''>('');
	const [autoRecord, setAutoRecord] = React.useState<boolean>(false);
	const [forceRedraw, setForceRedraw] = React.useState<boolean>(false);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);

	let needToOpenTuto = localStorage.getItem('needToOpenTuto');
	const [tutoOpen, setTutoOpen] = React.useState<boolean>(needToOpenTuto === 'no' ? false : true);
	const [autoRecordDelay, setAutoRecordDelay] = React.useState<number>(2);
	const [recordingDevice, setRecordingDevice] = React.useState<MediaDeviceInfo | null>(null);
	const [audioState, setAudioState] = React.useState<'recording' | 'idle' | 'playing'>('idle');

	const modalContext = useConfirmationModalContext();

	useEffect(() => {
		handleScroll(mainRef);
	}, [forceRedraw]);

	const handleOpenTuto = () => {
		setTutoOpen(true);
	};

	const handleCloseTuto = () => {
		localStorage.setItem('needToOpenTuto', 'no');
		setTutoOpen(false);
	};

	const handleLoadAudioRecordinBook = async () => {
		const recordSessionId = user.recordSessionId;
		setIsSaving(true);
		await doRequest({
			url: `/audiorecording/${recordSessionId}`,
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				setIsSaving(false);
				if (res) {
					const audioRecordingBook = res.audioRecordingBook as IAudioRecordingBook;
					let pos = 0;
					for (let i = 0; i < audioRecordingBook.audioRecordingTexts.length; i++) {
						if (audioRecordingBook.audioRecordingTexts[i].completed === false) {
							if (i > 0) {
								pos = i - 1;
								break;
							}
						}
					}
					if (audioRecordingBook.audioRecordingTexts[audioRecordingBook.audioRecordingTexts.length - 1].completed)
						pos = audioRecordingBook.audioRecordingTexts.length - 1;

					setAudioRecordingSession((oldValue) => {
						return { audioRecordingBook, currentPosition: pos > oldValue.currentPosition ? pos : oldValue.currentPosition };
					});
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	React.useEffect(() => {
		if (isSaving) setIsSaving(false);
		notify.error(errors);
	}, [errors]);

	React.useEffect(() => {
		if (user?.recordSessionId) {
			handleLoadAudioRecordinBook();
		}
	}, [user?.recordSessionId, tutoOpen]);

	const delayStartRecording = (delayInSec: number) => {
		if (isRecordingRef && isRecordingRef.current === true) return;

		notify.infoTopCenter("Début de l'enregistrement dans " + delayInSec + ' secondes');
		if (delayInSec > 0) setTimeout(delayStartRecording, 1000, delayInSec - 1);
		else if (recordingDevice) startRecording(recordingDevice.deviceId);
	};

	React.useEffect(() => {
		if (autoRecord && !isRecording && recordingDevice && !getCurrentAudioRecordingText()?.completed) {
			if (autoRecordDelay > 0) delayStartRecording(autoRecordDelay);
			else startRecording(recordingDevice.deviceId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [audioRecordingSession.currentPosition]);

	React.useEffect(() => {
		if (isRecording) setAudioState('recording');
		else setAudioState('idle');
	}, [isRecording]);

	React.useEffect(() => {
		setIsSaving(true);
		if (shouldSave !== '' && recordingBlob != null) {
			const currentAudioRecordingText = getCurrentAudioRecordingText();
			if (user && currentAudioRecordingText) {
				getBlobDuration(recordingBlob).then((duration) => {
					currentAudioRecordingText.localUrl = URL.createObjectURL(recordingBlob);
					currentAudioRecordingText.completed = true;
					currentAudioRecordingText.duration = duration;

					let formData = new FormData();
					formData.append('file', recordingBlob, currentAudioRecordingText.audioFilename);
					formData.append('currentAudioRecordingText', JSON.stringify(currentAudioRecordingText));

					const req = request();
					req.post('/audiorecording/' + user.recordSessionId, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
						.then((res) => {
							setIsSaving(false);
							if (shouldSave === 'save_next') nextAudioRecordingText();
							else if (shouldSave === 'save_previous') previousAudioRecordingText();

							setShouldSave('');
							if (user && user.dictation_status === 'none') {
								handleUpdateUser({ dictation_status: 'in_progress' });
							}
							setForceRedraw(!forceRedraw);
						})
						.catch((err: AxiosError) => {
							if (err.response?.status === 403) {
								notify.error('Votre session a expirée.');
								resetRecordingSession();
								session.saveUser(undefined);
								sessionClear();
								window.location.href = '/login';
							} else {
								setIsSaving(false);
								notify.error(err);
							}
						});
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recordingBlob]);

	const handleRecord = () => {
		if (audioState === 'recording') {
			setShouldSave('save');
			stopRecording();
		} else {
			startRecording(recordingDevice ? recordingDevice.deviceId : '');
		}
	};

	const handlePrevious = () => {
		if (audioState === 'recording') {
			setShouldSave('save_previous');
			stopRecording();
		} else previousAudioRecordingText();
		setForceRedraw(!forceRedraw);
	};

	const handleNext = () => {
		if (audioState === 'recording') {
			setShouldSave('save_next');
			stopRecording();
		} else nextAudioRecordingText();

		setForceRedraw(!forceRedraw);
	};

	const handleKeyPress = (event: any) => {
		if (event.key === 'ArrowDown') handleRecord();
		else if (event.key === 'ArrowRight') handleNext();
		else if (event.key === 'ArrowLeft') {
			if (audioRecordingSession.currentPosition !== 0) handlePrevious();
		}
	};

	const handleAudioRecordingCompleted = async () => {
		if (user && user.dictation_status !== 'completed') {
			setIsSaving(true);
			await doRequest({
				url: `/audiorecording/completed/${user.recordSessionId}`,
				method: 'put',
				body: null,
				onSuccess: (res: any) => {
					setIsSaving(false);
					if (res) {
						session.saveUser(res.user);
						navigate('/completed');
					} else {
						notify.error('Erreur lors de la Requête au serveur.');
					}
				},
			});
		} else navigate('/completed');
	};

	const handleUpdateUser = async (val: any) => {
		if (user && user.role === 'user') {
			await doRequest({
				url: `/user/${user.recordSessionId}`,
				method: 'put',
				body: val,
				onSuccess: (res: any) => {
					if (res) {
						session.saveUser(res.user);
					} else {
						notify.error('Erreur lors de la Requête au serveur.');
					}
				},
			});
		}
	};

	React.useEffect(() => {
		const duration = getTotalAudioDuration();
		const ars = audioRecordingSession;
		//user record more than 30 minutes
		if (duration >= 60) handleAudioRecordingCompleted();
		else if (ars.audioRecordingBook && ars.currentPosition + 10 > ars.audioRecordingBook.audioRecordingTexts.length)
			handleLoadAudioRecordinBook();
	}, [audioRecordingSession, user?.recordSessionId]);

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleKeyPress]);

	const handleAudioPlayerPlaying = (audioFileName: string) => {
		setPlayingAudioFileName(audioFileName);
	};

	const handleAudioPlayerStopped = (audioFileName: string) => {
		if (playingAudioFileName === audioFileName) setPlayingAudioFileName('');
	};

	const getRecordIcon = () => {
		if (audioState === 'recording')
			return (
				<>
					<StopCircle sx={{ color: red[500] }} />
					<Typography variant="subtitle1">Terminer</Typography>
				</>
			);
		else if (getCurrentAudioRecordingText()?.completed)
			return (
				<>
					<ChangeCircle sx={{ color: red[500] }} />
					<Typography variant="subtitle1">Reprendre</Typography>
				</>
			);
		return (
			<>
				<FiberManualRecord sx={{ color: green[500] }} />
				<Typography variant="subtitle1">Enregistrer</Typography>
			</>
		);
	};

	if (user && !user.legal_terms_accepted && user.role === 'user') {
		navigate('/legal/terms');
		return null;
	}

	if (audioRecordingSession.audioRecordingBook && audioRecordingSession.audioRecordingBook.completed === true) {
		navigate('/completed');
		return null;
	}

	const audioRecordings: any[] = [];
	if (audioRecordingSession.audioRecordingBook?.audioRecordingTexts) {
		for (const [idx, data] of audioRecordingSession.audioRecordingBook.audioRecordingTexts.entries()) {
			audioRecordings.push(
				<ListItemButton
					key={data.audioFilename}
					dense
					autoFocus={idx === audioRecordingSession.currentPosition}
					onClick={() => {
						setAudioRecordingSession((oldValue) => {
							return { ...oldValue, currentPosition: idx };
						});
					}}
					disabled={!data.completed}
				>
					<AudioRecordingTextPlayer
						disablePlaying={isRecording || (playingAudioFileName !== '' && playingAudioFileName !== data.audioFilename)}
						recordingSessionId={user?.recordSessionId}
						isSelected={idx === audioRecordingSession.currentPosition}
						data={data}
						idx={idx}
						showText={false}
						onPlay={handleAudioPlayerPlaying}
						onStop={handleAudioPlayerStopped}
					/>
				</ListItemButton>
			);

			//We don't want to show all texts to avoid scaring user
			if (!data.completed) break;
		}
	}

	return (
		<Box
			component="main"
			sx={{
				/*display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',*/
				backgroundImage: `url(${background})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				flexGrow: 1,
				py: 8,
			}}
		>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isSaving}
				//onClick={handleClose}
			>
				<Box sx={{ position: 'relative', display: 'inline-flex' }}>
					<CircularProgress variant="determinate" size={30} />
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="caption" component="div" color="text.secondary">
							{'chargement...'}
						</Typography>
					</Box>
				</Box>
			</Backdrop>
			<Container maxWidth={false}>
				<Grid container spacing={3}>
					{/* Grid shortcut start */}
					{!isMatch && (
						<Grid item lg={2} sm={12} xl={2} xs={12}>
							<Box
								sx={{
									flexDirection: 'column',
									justifyContent: 'center',
									padding: 2,
									backgroundColor: 'black',
									borderRadius: '8px',
									height: '83vh',
									overflow: 'auto',
								}}
							>
								<Box
									sx={{
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										maxWidth: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
										height: 'auto',
										backgroundColor: '#EDEAE6',
										borderRadius: '8px',
									}}
								>
									<Box
										sx={{
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											maxWidth: '100%',
											//mt: `${idx === 0 ? '15px' : ''}`,
											mb: 2,
											color: 'black',
											fontFamily: 'Baskerville',
										}}
									>
										<Typography variant="subtitle2" sx={{ p: 1 }}>
											L'exercice consiste à dicter les phrases (incluant le texte affiché en gras). Il dure environ 35
											minutes.
										</Typography>
									</Box>

									<Divider sx={{ bgcolor: 'black', ml: 1, mr: 1 }} />

									{/*<Box
										sx={{
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											maxWidth: '100%',
											//mt: `${idx === 0 ? '15px' : ''}`,
											color: 'black',
											fontFamily: 'Baskerville',
											mb: 2,
										}}
									>
										<Typography variant="subtitle2" sx={{p: 1,}}>
											<h3>Vos participations:</h3>
											<div>
												<b>1 participation</b> = <span>avoir complété 30 minutes de dictées.</span>
												<br />
												<b>2 participations</b> = <span> avoir complété l'exercice.</span>
											</div>
										</Typography>
									</Box>

									<Divider sx={{bgcolor: 'black', ml: 1, mr: 1}} />*/}

									<Box
										sx={{
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											width: '100%',
											//mt: `${idx === 0 ? '15px' : ''}`,
											color: 'black',
											fontFamily: 'Baskerville',
											mb: 2,
										}}
									>
										<Typography variant="subtitle2" sx={{ p: 1 }}>
											À tout moment vous pouvez quitter et revenir plus tard.
										</Typography>
									</Box>

									<Divider sx={{ bgcolor: 'black', ml: 1, mr: 1 }} />

									<Box
										sx={{
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											width: '100%',
											//mt: `${idx === 0 ? '15px' : ''}`,
											color: 'black',
											mb: 2,
										}}
									>
										<Typography variant="subtitle2" sx={{ p: 1 }}>
											Utiliser l'enregistrement automatique et les raccourcis clavier pour dicter plus rapidement.
										</Typography>
									</Box>
								</Box>
								{/*<Box
									sx={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										maxWidth: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
										
									}}
								>
									<Alert severity="info">
										L'exercice consiste à dicter les phrases (incluant le texte affiché en gras). Il dure environ 35
										minutes.
									</Alert>
								</Box>
								<Box
									sx={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										maxWidth: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
									}}
								>
									<div className="sidecard">
										<h3>Vos participations:</h3>
										<div>
											<b>1 participation</b> = <i>avoir complété 30 minutes de dictées.</i>
											<br />
											<b>2 participations</b> = <i> avoir complété l'exercice.</i>
										</div>
										<span className="sidetop"></span>
										<span className="sideright"></span>
										<span className="sidebottom"></span>
										<span className="sideleft"></span>
									</div>
								</Box>
								<Box
									sx={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										width: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
									}}
								>
									<Alert severity="info">À tout moment vous pouvez quitter et revenir plus tard.</Alert>
								</Box>
								<Box
									sx={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										width: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
									}}
								>
									<Alert severity="info">
										Utiliser l'enregistrement automatique et les raccourcis clavier pour dicter plus rapidement.
									</Alert>
								</Box>*/}
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
										width: '100%',
										mt: 1,
									}}
								>
									<Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }} variant="h6">
										{'Raccourcis clavier'}
									</Typography>
								</Box>

								<Divider />

								{shortCutList.map((item, idx) => (
									<Box
										sx={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											width: '100%',
											mt: `${idx === 0 ? '15px' : ''}`,
											mb: 2,
										}}
									>
										<Box
											sx={{
												flexDirection: 'column',
												maxWidth: '100%',
											}}
										>
											<Box
												sx={{
													border: '1px solid #ECEAE6',
													p: 1,
													borderRadius: '33px',
													width: '100%',
												}}
											>
												<Typography variant="subtitle2" sx={{ color: '#ECEAE6', width: 'auto', ml: 1 }}>
													{item.title}
												</Typography>
												<Typography variant="caption" sx={{ ml: 4, mt: -2 }} color={grey[500]}>
													{item.listicon}
													{`${item.label} `}
												</Typography>
											</Box>
										</Box>
									</Box>
								))}

								{/*{shortCutList.map((item, idx) => (
									<Box
										sx={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
											width: '100%',
											mt: `${idx === 0 ? '15px' : ''}`,
											mb: 2,
										}}
									>
										<Box sx={{ flexDirection: 'column',}}>
											<Alert sx={{ pt: 0, pb: 0 }} icon={item.listicon} variant="outlined" severity="info">
												<Typography variant="subtitle2">{item.title}</Typography>
												<Typography variant="caption" color={grey[500]}>
													{`[ ${item.label} ]`}
												</Typography>
											</Alert>
										</Box>
									</Box>
									))}
								*/}
							</Box>
						</Grid>
					)}

					{/* Grid shortcut end  */}

					{/* Grid text recorder start */}
					<Grid item lg={8} sm={12} xl={8} xs={12}>
						{/* main text display wrapper */}

						<Box
							sx={{
								flexDirection: 'column',
								minHeight: '100%',
								padding: 2,
								backgroundColor: '#EDEAE6',
								justifyContent: 'space-between',
								display: 'flex',
								borderRadius: '8px',
							}}
						>
							{/* text display container */}
							<Card
								ref={mainRef}
								sx={{
									border: 2,
									borderColor: audioState === 'recording' ? red[500] : 'black',
									paddingLeft: 1,
									backgroundColor: '#EDEAE6',
								}}
							>
								<CardContent sx={{ minHeight: isMatch ? '' : '73vh' }}>
									<Typography
										sx={{ color: 'black', fontFamily: 'Anton', fontWeight: 900, fontSize: '1.5rem', lineHeight: 1.375 }}
									>
										{parse(getCurrentAudioRecordingTextContent())}
									</Typography>
								</CardContent>
							</Card>
							{/* player toolbar wrapper */}
							<Box
								sx={{
									mt: 1,
									flexDirection: 'row',
									display: 'flex',
									justifyContent: isMatch ? 'center' : 'space-between',
									alignItems: 'center',
									flexWrap: 'wrap',
								}}
							>
								<Box>
									<IconButton
										disabled={audioRecordingSession.currentPosition === 0}
										aria-label="previous"
										onClick={handlePrevious}
										sx={{ color: 'black' }}
									>
										<KeyboardArrowLeftIcon sx={{ width: '35px', height: '35px', color: 'black' }} />
										<Typography variant="subtitle2" sx={{ color: 'black', textTransform: 'uppercase' }}>
											{isMatch ? 'Préc.' : 'Précédent'}
										</Typography>
									</IconButton>
									<IconButton aria-label="record" onClick={handleRecord} sx={{ color: 'black' }}>
										{getRecordIcon()}
									</IconButton>
									<IconButton
										disabled={!getCurrentAudioRecordingText()?.completed && audioState !== 'recording'}
										aria-label="next"
										onClick={handleNext}
										sx={{ color: 'black' }}
									>
										<Typography variant="subtitle2" sx={{ color: 'black', textTransform: 'uppercase' }}>
											{isMatch ? 'Suiv.' : 'Suivant'}
										</Typography>
										<KeyboardArrowRightIcon sx={{ width: '35px', height: '35px', color: 'black' }} />
									</IconButton>
								</Box>

								<Typography sx={{ color: 'black' }}>
									{isRecording
										? (recordingTime / 60).toFixed(0).toString().padStart(2, '0') +
										  ':' +
										  (recordingTime % 60).toString().padStart(2, '0')
										: '00:00'}
								</Typography>

								<Box sx={{ width: '200px', ml: 1 }}>
									<VoiceMeter previewAudioStream={previewAudioStream} audioState={audioState} />
								</Box>
								<Tooltip title={'Enregistrement automatique sur dictée suivante'}>
									<FormControlLabel
										sx={{ ml: 1, color: 'black' }}
										control={
											<Switch size="small" checked={autoRecord} onChange={(e) => setAutoRecord(e.target.checked)} />
										}
										label={isMatch ? 'Enregistrement auto' : 'Enregistrement automatique sur dictée suivante'}
									/>
								</Tooltip>
								{/*<TextField
									size="small"
									sx={{ width: 100, ml: 1, color: 'black' }}
									id="filled-read-only-input"
									variant="standard"
									label="Délai (secondes)"
									type="number"
									InputLabelProps={{ style: {
										color: '#1E1E1E',
										fontFamily: 'Baskerville',
									} }}
									inputProps={{ readOnly: true ,min: 0, style: { textAlign: 'center', color: '1E1E1E',fontFamily:'Baskerville'} }}
									value={autoRecordDelay}
									onChange={(e) => setAutoRecordDelay(parseInt(e.target.value))}
								/>*/}
								<TextField
									sx={{
										input: { color: 'black', borderBottom: '1px dashed black' },
									}}
									//disabled={!autoRecord}
									label="Délai (secondes)"
									type="text"
									id="outlined-basic"
									variant="standard"
									onChange={(e) => setAutoRecordDelay(parseInt(e.target.value))}
									value={autoRecordDelay}
									inputProps={{
										min: 0,
										style: {
											width: 100,
											color: '1E1E1E',
											fontFamily: 'Baskerville',
											textAlign: 'center',
										},
									}}
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
								/>

								{!isMatch && <> </>}

								<IconButton
									sx={{ ml: 1, color: 'black' }}
									disabled={!getCurrentAudioRecordingText()?.completed && audioState !== 'recording'}
									aria-label="next"
									onClick={() => {
										setRecordingDevice(null);
									}}
								>
									<MicIcon sx={{ color: 'grey' }} />
								</IconButton>
							</Box>
						</Box>
					</Grid>

					{/* Grid audio start */}
					<Grid item lg={2} sm={12} xl={2} xs={12}>
						<Box
							sx={{
								flexDirection: 'column',
								justifyContent: 'space-between',
								padding: 2,
								backgroundColor: '#EDEAE6',
								borderRadius: '8px',
								height: '75vh',
								overflow: 'auto',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
									width: '100%',
								}}
							>
								<Typography
									sx={{
										fontSize: '1rem',
										fontWeight: 900,
										color: 'black',
										fontFamily: 'Anton',
										textTransform: 'uppercase',
									}}
									variant="h6"
								>
									{'Enregistrements'}
								</Typography>
								<Typography
									variant="subtitle2"
									sx={{ color: 'black', fontFamily: 'Baskerville' }}
								>{`Durée: ${getTotalAudioDurationText()}`}</Typography>
							</Box>
							<Divider />
							{/* text display container */}

							{/* player toolbar container */}
							<Box>
								<List sx={{ height: isMatch ? '41vh' : '72vh', overflow: 'auto', borderColor: '#1e1e1e29' }}>
									{audioRecordings}
								</List>
							</Box>
						</Box>
					</Grid>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'row',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<Box
							sx={{
								mt: -5,
							}}
						>
							<Tooltip title={'Aide'}>
								<IconButton
									onClick={() => {
										help.openModal();
									}}
								>
									<HelpIcon sx={{ width: '40px', height: '40px' }} />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>

					{/* Grid audio end */}
				</Grid>
				<Slider text={slides[slideText]} />
			</Container>

			<MicrophonePickerDialog open={!recordingDevice} onSelectMicrophone={setRecordingDevice} />
			<HelpModal open={tutoOpen} closeModal={handleCloseTuto} />
		</Box>
	);
};

export default TextRecorder;
