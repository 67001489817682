import { LinearProgress, Box } from '@mui/material';
import { blue, grey, orange, red } from '@mui/material/colors';
import React from 'react'

interface VoiceMeterProps {
    previewAudioStream: any,
    audioState: 'recording' | 'idle' | 'playing'
}

const VoiceMeter = (props: VoiceMeterProps) => {

    const [audioMeterValue, setAudioMeterValue] = React.useState<number>(0);

    React.useEffect(() => {
        const audioCtx = new AudioContext();
        const analyser = audioCtx.createAnalyser();
        analyser.fftSize = 256;
        analyser.smoothingTimeConstant = 0.2;

        if (props.previewAudioStream) {
            const source = audioCtx.createMediaStreamSource(props.previewAudioStream);
            source.connect(analyser);

            // Here we provide a way for the audioContext to be closed.
            // Closing the audioContext allows the unused audioSource to be garbage collected.
            props.previewAudioStream.addEventListener('cleanup', () => {
                if (audioCtx.state !== 'closed') {
                    audioCtx.close();
                }
            });
        }

        const interval = setInterval(() => {
            if (props.audioState === 'recording') {
                const dataBuffer = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(dataBuffer);
                const volume = Math.floor((Math.max(...dataBuffer) / 255) * 100);
                setAudioMeterValue(volume);
            }
            else if (audioMeterValue !== 0)
                setAudioMeterValue(0);

        }, 100);

        return () => {
            clearInterval(interval);
            audioCtx.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.previewAudioStream, props.audioState]);

    return (
    <Box sx={{color: grey[500]}}>     
        <LinearProgress color='inherit' variant="determinate" value={audioMeterValue} 
            sx={{
                border: 1,
                borderColor: grey[500],
                '& .MuiLinearProgress-bar1Determinate': {
                    backgroundColor: (audioMeterValue < 35) ? blue[500] : (audioMeterValue > 95) ? red[500] : ((audioMeterValue > 75) ? orange[500] : null),
                },
                height: '20px',
                "& .MuiLinearProgress-bar": {
                    transition: "none"
                }
            }} 
        />
     </Box>
    );
}

export default VoiceMeter