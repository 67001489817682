import React from 'react';
import InfoSection from '../components/InfoSection';
import { homeObj1, homeObj2, homeObj3, homeObjForm } from './Data';
const HomeWeb = () => {
	return (
		<>
			<InfoSection {...homeObj1} />
			<InfoSection {...homeObj2} />
			<InfoSection {...homeObj3} />
			<InfoSection {...homeObjForm} />
		</>
	);
};

export default HomeWeb;
