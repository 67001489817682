import React, { useEffect, useState } from 'react';
import request from '../helpers/myAxios';
import { AxiosResponse } from 'axios';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import MaleIcon from '@mui/icons-material/MaleOutlined';
import FemaleIcon from '@mui/icons-material/FemaleOutlined';
import AudioIcon from '@mui/icons-material/AudioFileOutlined';
import { useNotify } from './providers/NotifyProvider';
import CompletedByGender from './admin/CompletedByGender';
import LaptopMacIcon from '@mui/icons-material/Man2Outlined';
import PhoneIcon from '@mui/icons-material/Woman2Outlined';
import TabletIcon from '@mui/icons-material/TransgenderOutlined';
import useRequest from './hooks/useRequest';
import { useConfirmationModalContext } from './providers/ConfirmModalContext';
import { Box, Typography, Container, Grid, Backdrop, CircularProgress } from '@mui/material';
import moment from 'moment';
import User from './admin/User';
import UserList from './admin/UserList';
import UserListToolbar from './admin/UserListToolbar';
import UserChart from './admin/UserChart';
const regionList = [
	'01 - BAS-ST-LAURENT',
	'02 - SAGUENAY-LAC-SAINT-JEAN',
	'03 - CAPITALE-NATIONALE (QUÉBEC)',
	'04 - MAURICIE',
	'05 - ESTRIE',
	'06 - MONTRÉAL',
	'07 - OUTAOUAIS',
	'08 - ABITIBI-TÉMISCAMINGUE',
	'09 - CÔTE-NORD',
	'10 - NORD-DU-QUEBEC',
	'11 - GASPÉSIE–ÎLES-DE-LA-MADELEINE',
	'12 - CHAUDIÈRE-APPALACHES',
	'13 - LAVAL',
	'14 - LANAUDIÈRE',
	'15 - LAURENTIDES',
	'16 - MONTÉRÉGIE',
	'17 - CENTRE-DU-QUÉBEC',
];
interface IStatData {
	users: any[];
	totalUserCount: number;
	femaleUserCount: number;
	maleUserCount: number;
	totalAudio: number;
	totalUserCompleted: number;
	totalMaleUserCompleted: number;
	totalfemaleUserCompleted: number;
	totalOtherUserCompleted: number;
}
const Admin = () => {
	const [statData, setStatData] = useState<IStatData>({
		users: [],
		totalUserCount: 0,
		femaleUserCount: 0,
		maleUserCount: 0,
		totalAudio: 0,
		totalUserCompleted: 0,
		totalMaleUserCompleted: 0,
		totalfemaleUserCompleted: 0,
		totalOtherUserCompleted: 0,
	});
	const [userList, setUserList] = useState([]);
	//const [userAllList, setAllUserList] = useState([]);
	const [chartData, setChartData] = useState<any[]>([]);
	const [userCount, setUserCount] = useState(0);
	const [totalAudio, setTotalAudio] = useState(0);
	const [totalAudioDuration, setTotalAudioDuration] = useState(0);
	const [totalAudioDurationInSec, setTotalAudioDurationInSec] = useState(0);
	//const [userMaleCount, setUserMaleCount] = useState(0);
	//const [userFemaleCount, setUserFemaleCount] = useState(0);
	const [filterValue, setFilerValue] = useState('');
	//const [filteredUser, setSilteredUser] = useState<any[]>();
	const [limit, setLimit] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [forceRedraw, setForceRedraw] = useState(false);
	const [audioRecordingBooks, setAudioRecordingBooks] = useState<any[]>([]);
	const [statusFilterValue, setStatusFilterValue] = useState('all');
	const modalContext = useConfirmationModalContext();
	const { doRequest, errors, loading } = useRequest();

	const notify = useNotify();

	const getPageStats = async () => {
		await doRequest({
			url: `/stat/`,
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					setStatData(res);
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	const handleExportUsers = async () => {
		await doRequest({
			url: `/user/export`,
			method: 'get',
			body: null,
			responseType: 'blob',
			onSuccess: (res: any) => {
				if (res) {
					const url = window.URL.createObjectURL(new Blob([res]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `concours-${moment().format('YYYY-MM-dd-h:mm:ss')}.csv`);
					document.body.appendChild(link);
					link.click();
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};
	/*const getAllUserList = async () => {
		await doRequest({
			url: `/user`,
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					setAllUserList(res.users);
					//setUserCount(res.result);
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};*/

	const getUserList = async () => {
		let filter = `&take=${page}&skip=${limit}`;
		if (statusFilterValue !== 'all') {
			filter += `&status=${statusFilterValue}`;
		}
		if (filterValue) {
			filter += `&name=${filterValue}`;
		}
		await doRequest({
			url: `/user?${filter}`,
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					setUserList(res.users);
					setUserCount(res.result);
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	React.useEffect(() => {
		notify.error(errors);
	}, [errors]);

	const handleUserDeleted = async (userId: any) => {
		await doRequest({
			url: `/user/${userId}`,
			method: 'delete',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					notify.success('Utilisateur supprimé');
					setForceRedraw(!forceRedraw);
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};
	const getChartData = () => {
		let userListData = [] as any[];

		regionList.forEach((el: any) => {
			let count = statData.users.filter((user: any) => user.region === el).length;
			userListData.push(count);
		});

		const otherRegionCount = statData.users.filter((u: any) => !regionList.includes(u.region)).length;
		userListData.push(otherRegionCount);
		setChartData(userListData);
	};

	const getTotalAudioCount = () => {
		const promises = statData.users.map((el: any) => {
			const req = request();
			return req.get('/audiorecording/' + el.id).then((res: AxiosResponse) => res.data);
		});
		Promise.all(promises).then((data) => {
			if (data.length > 0) {
				setAudioRecordingBooks(data);
				let total = 0;
				let totalDuration = 0;
				data.forEach((el: any) => {
					for (let i = 0; i < el.audioRecordingBook.audioRecordingTexts.length; i++) {
						const fetchedAudioRecordingTexts = el.audioRecordingBook.audioRecordingTexts[i];
						totalDuration = totalDuration + (fetchedAudioRecordingTexts.duration ? fetchedAudioRecordingTexts.duration : 0);
						if (fetchedAudioRecordingTexts.completed === true) {
							total = total + 1;
						}
					}
				});
				setTotalAudio(total);
				setTotalAudioDurationInSec(totalDuration);
				setTotalAudioDuration(totalDuration / 3600);
			}
		});
	};

	const handleFilterUserArray = (e: any) => {
		setFilerValue(e.target.value);
	};

	useEffect(() => {
		//getAllUserList();
		getPageStats();
	}, []);

	useEffect(() => {
		getUserList();
		//getAllUserList();
	}, [page, limit]);

	useEffect(() => {
		if (page !== 0) setPage(0);
		else getUserList();
		//getAllUserList();
	}, [filterValue, statusFilterValue]);

	useEffect(() => {
		//setUserMaleCount(userAllList.filter((x: any) => x.gender === 'Homme').length);
		//setUserFemaleCount(userAllList.filter((x: any) => x.gender === 'Femme').length);

		getTotalAudioCount();

		getChartData();
	}, [statData]);

	/*const getCompletedAudioRecordinBook = () => {
		if (audioRecordingBooks && audioRecordingBooks.length > 0) {
			let foundList = audioRecordingBooks.filter(
				(x: any) => x.audioRecordingBook.completed === true
			);
			return foundList;
		}
		return [];
	};*/

	/*const getTotalUserCompleted = () => {
		let foundList = getCompletedAudioRecordinBook();
		if (foundList) return foundList.length;

		return 0;
	};

	const getTotalMaleUserCompleted = () => {
		let foundList = getCompletedAudioRecordinBook();
		let foundMaleCompletedList = foundList.filter((book: any) =>
			userAllList.some(
				(user: any) => user.id === book.audioRecordingBook.uid && user.gender === 'Homme'
			)
		);
		if (foundMaleCompletedList) return foundMaleCompletedList.length;
		return 0;
	};

	const getTotalFemaleUserCompleted = () => {
		let foundList = getCompletedAudioRecordinBook();
		let foundFemaleCompletedList = foundList.filter((book: any) =>
			userAllList.some(
				(user: any) => user.id === book.audioRecordingBook.uid && user.gender === 'Femme'
			)
		);
		if (foundFemaleCompletedList) return foundFemaleCompletedList.length;
		return 0;
	};*/

	/*const handleDownloadData = async () => {
		const result = await modalContext.showConfirmation(
			'Confirmation',

			<p>
				Seul les donées des utlisateurs ayant un statut <strong>ACCEPTÉ</strong> seront téléchargé.
				Assurez-vous d'avoir accepté tous les données nécéssaires. Voulez-vous continuer?
			</p>,

			'Oui',
			'Non'
		);
		if (result) {
			await doRequest({
				url: `/audiorecording/downloads`,
				method: 'get',
				body: null,
				onSuccess: (res: any) => {
					if (res) {
					} else {
						notify.error('Erreur lors de la Requête au serveur.');
					}
				},
			});
		}
	};*/

	/*const maleC = getTotalMaleUserCompleted();
	const femaleC = getTotalFemaleUserCompleted();
	const allC = getTotalUserCompleted();

	const percentMaleCompleted = allC > 0 ? (maleC / allC) * 100 : 0;
	const percentFemaleCompleted = allC > 0 ? (femaleC / allC) * 100 : 0;

	const otherCompleted = allC > 0 ? ((allC - (maleC + femaleC)) / allC) * 100 : 0;*/
	return (
		<Box
			component="main"
			sx={{
				backgroundColor: '#F9FAFC',
				flexGrow: 1,
				py: 8,
			}}
		>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
				//onClick={handleClose}
			>
				<Box sx={{ position: 'relative', display: 'inline-flex' }}>
					<CircularProgress variant="determinate" size={30} />
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="caption" component="div" color="text.secondary">
							{'chargement...'}
						</Typography>
					</Box>
				</Box>
			</Backdrop>
			<Container maxWidth={false}>
				<Grid container spacing={3}>
					<Grid item lg={3} sm={6} xl={3} xs={12}>
						<User
							title={'Utilisateur'}
							count={statData.totalUserCount}
							icon={<PeopleIcon sx={{ color: 'white' }} />}
							bgc=""
							completedCount={statData.totalUserCompleted}
							label="Complété(s)"
						/>
					</Grid>
					<Grid item lg={3} sm={6} xl={3} xs={12}>
						<User
							title={'Homme'}
							count={statData.maleUserCount}
							icon={<MaleIcon sx={{ color: 'white' }} />}
							bgc="success.main"
							completedCount={statData.totalMaleUserCompleted}
							label="Complété(s)"
						/>
					</Grid>
					<Grid item lg={3} sm={6} xl={3} xs={12}>
						<User
							title={'Femme'}
							count={statData.femaleUserCount}
							icon={<FemaleIcon sx={{ color: 'white' }} />}
							bgc="warning.main"
							completedCount={statData.totalfemaleUserCompleted}
							label="Complété(s)"
						/>
					</Grid>
					<Grid item lg={3} sm={6} xl={3} xs={12}>
						<User
							title={'Enregistrement'}
							count={totalAudio}
							icon={<AudioIcon sx={{ color: 'white' }} />}
							bgc="primary.main"
							label={`Durée Totale audio : ${totalAudioDuration.toFixed(2)}hrs (${totalAudioDurationInSec.toFixed(2)}s)`}
						/>
					</Grid>
					<Grid item lg={8} md={12} xl={9} xs={12}>
						<UserChart data={chartData} />
					</Grid>
					<Grid item lg={4} md={6} xl={3} xs={12}>
						<CompletedByGender
							data={[
								{
									total: statData.totalMaleUserCompleted,
									title: 'Homme',
									value: Math.floor(
										statData.totalUserCompleted > 0
											? (statData.totalMaleUserCompleted / statData.totalUserCompleted) * 100
											: 0
									),
									icon: LaptopMacIcon,
									color: '#66bb6a',
								},
								{
									total: statData.totalfemaleUserCompleted,
									title: 'Femme',
									value: Math.floor(
										statData.totalUserCompleted > 0
											? (statData.totalfemaleUserCompleted / statData.totalUserCompleted) * 100
											: 0
									),
									icon: PhoneIcon,
									color: '#FB8C00',
								},
								{
									total: statData.totalOtherUserCompleted,
									title: 'Autre',
									value: Math.floor(
										statData.totalUserCompleted > 0
											? (statData.totalOtherUserCompleted / statData.totalUserCompleted) * 100
											: 0
									),
									icon: TabletIcon,
									color: '#3F51B5',
								},
							]}
							sx={{ backgroundColor: 'white', height: '100%' }}
						/>
					</Grid>
				</Grid>
			</Container>
			<Container maxWidth={false}>
				<UserListToolbar
					onDownloadData={() => {
						handleExportUsers();
					}}
					statusFilterValue={statusFilterValue}
					handleStatusFilterChange={(val: any) => setStatusFilterValue(val)}
					title={'Utilisateurs'}
					handleFilterUserArray={handleFilterUserArray}
				/>
				<Box sx={{ mt: 3 }}>
					<Typography variant="caption" component="div" color="black">
						***Note: la progression est calculée en fonction de 60 minutes de dictées.{' '}
					</Typography>
				</Box>

				<Box sx={{ mt: 3 }}>
					<UserList
						count={userCount}
						climit={limit}
						csetLimit={(val: number) => {
							setLimit(val);
						}}
						cpage={page}
						csetPage={(val: number) => {
							setPage(val);
						}}
						handleUserDeleted={handleUserDeleted}
						audioRecordingBooks={audioRecordingBooks}
						customers={userList}
					/>
				</Box>
			</Container>
		</Box>
	);
};

export default Admin;
