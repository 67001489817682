import React from 'react';
import { useState, useCallback } from 'react';
import useStateRef from 'react-usestateref';
import { browserName } from 'react-device-detect';

export interface recorderControls {
	startRecording: (recordingDeviceId: string) => void;
	stopRecording: () => void;
	recordingBlob?: Blob;
	isRecording: boolean;
	isMicInfoLoading: boolean;
	isRecordingRef: any;
	isPaused: boolean;
	recordingTime: number;
	previewAudioStream: MediaStream | null;
	recordingDevices: MediaDeviceInfo[];
}

/**
 * @returns Controls for the recording. Details of returned controls are given below
 *
 * @details `startRecording`: Calling this method would result in the recording to start. Sets `isRecording` to true
 * @details `stopRecording`: This results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
 * @details `togglePauseResume`: Calling this method would pause the recording if it is currently running or resume if it is paused. Toggles the value `isPaused`
 * @details `recordingBlob`: This is the recording blob that is created after `stopRecording` has been called
 * @details `isRecording`: A boolean value that represents whether a recording is currently in progress
 * @details `isPaused`: A boolean value that represents whether a recording in progress is paused
 * @details `recordingTime`: Number of seconds that the recording has gone on. This is updated every second
 * * @details `isMicInfoLoading`: loading microphones information
 */
const useAudioRecorder: () => recorderControls = () => {
	const [recordingDevices, setRecordingDevices] = useState<MediaDeviceInfo[]>([]);
	const [isRecording, setIsRecording, isRecordingRef] = useStateRef(false);
	const [isPaused, setIsPaused] = useState(false);
	const [recordingTime, setRecordingTime] = useState(0);
	const [mediaRecorder, setMediaRecorder] = useState<any>();
	const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>();
	const [recordingBlob, setRecordingBlob] = useState<Blob>();
	const [previewAudioStream, setPreviewAudioStream] = useState<MediaStream | null>(null);
	const [isMicInfoLoading, setIsMicInfoLoading] = useState(true);
	const _startTimer: () => void = () => {
		const interval = setInterval(() => {
			setRecordingTime((time) => time + 1);
		}, 1000);
		setTimerInterval((oldTimer) => {
			if (oldTimer) {
				clearInterval(oldTimer);
				setRecordingTime(0);
			}
			return interval;
		});
	};

	const _stopTimer: () => void = () => {
		timerInterval != null && clearInterval(timerInterval);
		setTimerInterval(undefined);
	};

	const loadDevices = () => {
		try {
			let microphones: MediaDeviceInfo[] = [];
			let speakers: MediaDeviceInfo[] = [];
			if (navigator.mediaDevices !== undefined && navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices
					.getUserMedia({ audio: true })
					.then(function (stream) {
						// Mic permissions granted, handle however you wish
						navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
							for (var i = 0; i !== deviceInfos.length; ++i) {
								var deviceInfo = deviceInfos[i];
								if (deviceInfo.kind === 'audioinput') microphones.push(deviceInfo);
								else if (deviceInfo.kind === 'audiooutput') speakers.push(deviceInfo);
							}

							setRecordingDevices(microphones);
							setIsMicInfoLoading(false);
						});
					})
					.catch(function (err) {
						// Mic permissions denied, handle however you wish
						setIsMicInfoLoading(false);
					});
			} else {
				setRecordingDevices(microphones);
				setIsMicInfoLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	/**
	 * Calling this method would result in the recording to start. Sets `isRecording` to true
	 */
	const startRecording: (recordingDeviceId: string) => void = useCallback(
		(recordingDeviceId: string) => {
			if (timerInterval != null)
				return;
			if (browserName.toLowerCase() === 'facebook') {
				setIsMicInfoLoading(false);
				return;
			}
			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
				navigator.mediaDevices
					.getUserMedia({ audio: { deviceId: recordingDeviceId ? recordingDeviceId : 'default' } })
					.then((stream) => {
						loadDevices();

						setIsRecording(true);
						const recorder = new MediaRecorder(stream);
						setPreviewAudioStream(stream);
						setMediaRecorder(recorder);
						recorder.start();
						_startTimer();

						recorder.addEventListener('dataavailable', (event) => {
							setRecordingBlob(event.data);
							recorder.stream.getTracks().forEach((t) => t.stop());
							setMediaRecorder(null);
						});
					})
					.catch((err) => {
						console.log(err)
						setIsMicInfoLoading(false);
					});
		},
		[timerInterval]
	);

	/**
	 * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
	 */
	const stopRecording: () => void = () => {
		mediaRecorder?.stop();
		_stopTimer();
		setRecordingTime(0);
		setIsRecording(false);
		setIsPaused(false);
	};

	return {
		startRecording,
		stopRecording,
		recordingBlob,
		isRecording,
		isRecordingRef,
		isPaused,
		isMicInfoLoading,
		recordingTime,
		previewAudioStream,
		recordingDevices,
	};
};

export default useAudioRecorder;
