import React, { useContext } from 'react';
import { Dialog, IconButton, DialogTitle, Box, Stack, DialogContent, Typography, DialogActions, Button, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactPlayer from 'react-player';

const HelpContext = React.createContext({} as any);

type Props = {
	open: boolean;
	closeModal: () => void;
};
export const useHelp = () => {
	return useContext(HelpContext);
};

export const HelpProvider = ({ children, defaultOptions = {} }: any) => {
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const openModal = () => {
		setOpen(true);
	};
	return (
		<React.Fragment>
			<HelpContext.Provider value={{ handleClose, openModal }}>{children}</HelpContext.Provider>
			<HelpModal open={open} closeModal={handleClose} />
		</React.Fragment>
	);
};

const HelpModal = (props: Props) => {
	const handleClose = () => {
		props.closeModal();
	};

	return (
		<Dialog sx={{}} open={props.open} maxWidth="sm">
			<DialogTitle
				style={{
					display: 'flex',
					backgroundColor: 'black',
				}}
			>
				<Box flexGrow={1}>
					<Typography
						sx={{ textTransform: 'uppercase', display: 'flex', justifyContent: 'center', fontWeight: 900, fontFamily: 'Anton' }}
					>
						{'INSTRUCTIONS'}
					</Typography>
				</Box>
				<IconButton onClick={handleClose}>
					<CloseIcon sx={{ color: 'white' }} />
				</IconButton>
			</DialogTitle>
			<DialogContent style={{ backgroundColor: '#EDEAE6' }}>
				<Stack sx={{ my: 3 }} spacing={3}>
					<Stack
						spacing={1}
						sx={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Typography component={'div'} variant="h6">
							<Avatar sx={{ color: '#1E1E1E', border: `1px solid #1E1E1E`, background: 'none', fontWeight: 'bold' }}>
								1
							</Avatar>
						</Typography>
						<Typography variant="h6" sx={{ color: '#1E1E1E' }}>
							{'Cliquer sur "Enregistrer" au bas de l\'écran pour débuter.'}
						</Typography>
						<Typography component={'div'} variant="h6">
							<Avatar sx={{ color: '#1E1E1E', border: `1px solid #1E1E1E`, background: 'none', fontWeight: 'bold' }}>
								2
							</Avatar>
						</Typography>
						<Typography variant="h6" sx={{ color: '#1E1E1E' }}>
							{'Dicter la phrase en dictant aussi les ponctuations.'}
						</Typography>
						<Typography component={'div'} variant="h6">
							<Avatar sx={{ color: '#1E1E1E', border: `1px solid #1E1E1E`, background: 'none', fontWeight: 'bold' }}>
								3
							</Avatar>
						</Typography>
						<Typography textAlign="center" variant="h6" sx={{ color: '#1E1E1E' }}>
							{
								'Il faut cliquer sur terminer pour finaliser la phrase et ensuite, cliquer sur la flèche à droite de "Terminer" pour poursuivre.'
							}
						</Typography>
						<Typography component={'div'} variant="h6">
							<Avatar sx={{ color: '#1E1E1E', border: `1px solid #1E1E1E`, background: 'none', fontWeight: 'bold' }}>
								4
							</Avatar>
						</Typography>
						<Typography textAlign="center" variant="h6" sx={{ color: '#1E1E1E' }}>
							{"Il est possible de reprendre jusqu'à satisfaction chacune des phrases."}
						</Typography>
						<Typography component={'div'} variant="h6">
							<Avatar sx={{ color: '#1E1E1E', border: `1px solid #1E1E1E`, background: 'none', fontWeight: 'bold' }}>
								5
							</Avatar>
						</Typography>
						<Typography textAlign="center" variant="h6" sx={{ color: '#1E1E1E' }}>
							{"Le pourcentage indique l'avancement vers la fin des enregistrements."}
						</Typography>
						<Typography component={'div'} variant="h6">
							<Avatar sx={{ color: '#1E1E1E', border: `1px solid #1E1E1E`, background: 'none', fontWeight: 'bold' }}>
								6
							</Avatar>
						</Typography>
						<Typography textAlign="center" variant="h6" sx={{ color: '#1E1E1E' }}>
							{
								"les textes sont des extraits de texte issus d'un collectif de création littéraire de l'association Les écrivain.e.s de la Sagamie intitulé Une nuit à l'auberge des 21. Il s'agit de Sonia Perron, de Cindy Dumais et de Micahël La Chance qui ont accepté de contribuer au projet. "
							}
						</Typography>
					</Stack>
					{/*<ReactPlayer
						url="https://youtu.be/8WyDNLCqukE"
						width="100%"
						height="calc(100vh - 250px)"
					/>*/}
					<Button
						color="secondary"
						variant="outlined"
						onClick={handleClose}
						sx={{ borderRadius: '30px', textTransform: 'uppercase', fontFamily: 'Anton', height: '30px' }}
					>
						DÉBUTER
					</Button>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: '#EDEAE6' }}>
				{/*<Button
					color="secondary"
					variant="contained"
					onClick={handleClose}
					sx={{ borderRadius: '30px', textTransform: 'uppercase', fontFamily: 'Anton', height: '30px' }}
				>
					Passer
				</Button>*/}
			</DialogActions>
		</Dialog>
	);
};

export default HelpModal;
