import React from 'react';
import { Box, Typography } from '@mui/material';
import { Container } from '../globalStyles';
import { grey } from '@mui/material/colors';
const Legal = () => {
	return (
		<Container>
			<Box>
				<Box
					sx={{
						p: 5,
						//border: 1,
						//borderColor: grey[500],
						//width: '80hw',
						//height: '75vh',
						//overflowY: 'scroll',
					}}
				>
					<Box sx={{ mb: 5 }}>
						<Typography variant="h3">
							Politique de confidentialité et utilisation des données
						</Typography>

						<Typography variant="h5" sx={{ mt: 2 }}>
							Enregistrements
						</Typography>

						<p>
							Les enregistrements audios recueillis serviront à bonifier le système de
							reconnaissance vocale pour le français québécois. Les textes utilisés dans ces dictées
							sont dépourvus de droit d’auteur.
						</p>

						<Typography variant="h5" sx={{ mt: 2 }}>
							Données personnelles
						</Typography>

						<p>
							Les données demandées au début de l’exercice sont uniquement afin de catégoriser les
							enregistrements audios, de façon à obtenir un échantillonnage le plus équitable au
							niveau des particularités de langage du Québec. Aucune de ces données ne sera échangée
							à des compagnies tierces ou utilisées afin de vous rejoindre à des buts commerciaux.
						</p>
					</Box>
					<Typography variant="h3">Règlement du concours</Typography>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Admissibilité
					</Typography>

					<p>Afin de pouvoir participer à ce concours, vous devez&nbsp;:</p>

					<p>
						<span>
							<span>
								a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</span>
						Être une personne dont la langue maternelle est le français originaire du Canada ou
						résider au Québec depuis plus de 5 ans et utiliser le français couramment;
					</p>

					<p>
						<span>
							<span>
								b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</span>
						Fournir un courriel valide.
					</p>

					<p>
						<span>
							<span>
								c)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</span>
						Fournir des enregistrements audios valides.
					</p>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Période du concours
					</Typography>

					<p>
						Le concours est actif du 15 novembre 2022 au 15 mars 2023 ou peut être extensionné
						jusqu’à ce que nous ayons atteint le nombre requis d'enregistrements (500 heures).{' '}
					</p>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Comment participer au concours
					</Typography>

					<p>
						Se rendre directement sur le site internet du concours, remplir les informations
						obligatoires demandées, produire les enregistrements demandés par l’application web. Une
						fois l'exercice complété, vous receverez automatiquement 2 participations. Partager le
						lien de cet exercice vous donnera 2 participations supplémentaires par invité l'ayant
						complété.
					</p>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Vérification
					</Typography>

					<p>
						Tous les enregistrements seront vérifiés manuellement par rapport aux textes originaux.
						En cas de discordance majeure, Imagem se réserve le droit de retirer les participations.{' '}
					</p>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Les prix
					</Typography>

					<p>Un montant de 1 000 $ CAN et 4 montants de 250 $ CAN.</p>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Limitation de la responsabilité
					</Typography>

					<p>
						Le Commanditaire (Les systèmes médicaux Imagem inc.) n’assume aucune responsabilité pour
						les inscriptions, les formulaires de déclaration et quittance ou les courriels perdus,
						retardés, détruits ou mal dirigés ou pour quelque défaut technique notamment en raison
						d’un ordinateur, d’un logiciel, d’un téléphone ou de la connexion Internet.
					</p>

					<p>
						Le Commanditaire n’assume aucune responsabilité pour tout renseignement erroné ou
						inexact, qu’il soit causé par quelques équipement que ce soit ou programmation associés
						ou utilisés pour le Concours ou pour toute erreur humaine ou technique qui peut se
						produire au cours de l’administration du Concours.{' '}
					</p>

					<p>
						Tel que permis par la loi, le Commanditaire se réserve le droit, avec le consentement de
						la Régie des alcools, des courses et des jeux (la « Régie ») si nécessaire, d’annuler,
						de suspendre ou de modifier le Concours si un virus, un bogue, un problème informatique,
						une intervention humaine non autorisée ou une autre cause en dehors du contrôle du
						Commanditaire, altère ou affecte l’administration, la sécurité, l’équité, l’intégrité ou
						la bonne conduite de ce Concours{' '}
					</p>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Propriété intellectuelle
					</Typography>

					<p>
						Toute la propriété intellectuelle et tout le matériel promotionnel, pages web et codes
						sources sont la propriété du Commanditaire et/ou de ses filiales et tous les droits leur
						sont réservés. La copie ou l’usage non autorisé de matériel protégé par des droits
						d’auteur ou des marques de commerce sans le consentement exprès et écrit de son
						propriétaire est strictement interdit.{' '}
					</p>
				</Box>
			</Box>
		</Container>
	);
};

export default Legal;
