import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { makeStyles } from '../hooks/makeStyles';
import { Box, Container, TextField, Button, Typography } from '@mui/material';
import background from '../../images/bg-animé.gif'; // Import using relative path
import request from '../../helpers/myAxios';
import { useNotify } from '../providers/NotifyProvider';
const useStyles = makeStyles()((theme) => ({
	margin: {
		width: '550px',
		margin: 'auto',
		padding: '40px',
		borderRadius: theme.shape.borderRadius,
	},
	padding: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
	view: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		//backgroundImage: `url(${background})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
}));

const ForgotPassword = () => {
	const { classes } = useStyles();
	const notify = useNotify();
	const [email, setEmail] = useState('');
	const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
	const [isLoading, setIsloading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const reset = () => {
		setEmail('');
		setIsSubmitSuccessful(false);
	};

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset();
		}
	}, [isSubmitSuccessful]);

	const handleForgotPassword = () => {
		try {
			setIsloading(true);
			const req = request();
			if (email === '') return;
			req.post('auth/forgotpassword', { email: email })
				.then((res: AxiosResponse) => {
					if (res.status === 200) {
						setIsSuccess(true);
					}
				})
				.catch((err: any) => {
					notify.error('Impossible de modifier votre mot de passe. Veuillez réessayer plustard.');
				});
		} catch (error) {}
		setIsloading(false);
	};

	if (isSuccess) {
		return (
			<Container
				maxWidth={false}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<Box
						maxWidth="27rem"
						width="100%"
						height="13rem"
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '#235A7B',
							p: { xs: '1rem', sm: '2rem' },
							borderRadius: 2,
						}}
					>
						<Typography textAlign="center" component="h5">
							{`Si un compte existe avec votre adresse e-mail, ${email}, nous venons de vous envoyer un
							e-mail avec des instructions pour réinitialiser votre mot de passe.`}
						</Typography>
					</Box>
				</Box>
			</Container>
		);
	}

	return (
		<>
			<Container
				maxWidth={false}
				sx={{
					position: 'absolute',
					width: '100%',
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundImage: `url(${background})`,
					backgroundRepeat: 'no-repeat',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						backgroundColor: '#EDEAE6',
						p: { xs: '1rem', sm: '2rem' },
						borderRadius: 2,
					}}
				>
					<Typography
						textAlign="center"
						component="h1"
						sx={{
							fontSize: { xs: '4rem', md: '2rem' },
							fontWeight: 700,
							mb: 1,
							letterSpacing: -3,
							color: '#1E1E1E',
							fontFamily: 'Anton',
							textTransform: 'uppercase',
						}}
					>
						{'Réinitialiser votre mot de passe'}
					</Typography>
					<Typography component="h2" sx={{ color: '#1E1E1E', mb: 2 }}>
						{"Saisissez l'adresse courriel associée à votre compte."}
					</Typography>
					<Box component="form" noValidate autoComplete="off" maxWidth="27rem" width="100%">
						<TextField
							InputLabelProps={{
								style: {
									color: '#1E1E1E',
									fontFamily: 'Baskerville',
									textTransform: 'uppercase',
								},
							}}
							inputProps={{
								style: {
									color: '#1E1E1E',
									fontFamily: 'Baskerville',
								},
							}}
							sx={{ mb: 5 }}
							id="email_reset_pass"
							label={'Courriel'}
							type="text"
							size="small"
							fullWidth
							required
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<Button
							style={{
								textTransform: 'uppercase',
								marginBottom: '8px',
								color: 'black',
								background: 'none',
								border: 'none',
								fontWeight: 'bolder',
								fontSize: '1.5rem',
								lineHeight: 0.5,
								fontFamily: 'Anton',
								letterSpacing: '-2px',
							}}
							fullWidth
							variant="contained"
							onClick={handleForgotPassword}
						>
							{'Continuer'}
						</Button>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default ForgotPassword;
