import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from './hooks/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {
	SkipPrevious,
	SkipNext,
	FiberManualRecord,
	ChangeCircle,
	StopCircle,
	Edit,
} from '@mui/icons-material';

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
	useMediaQuery,
	useTheme,
	Box,
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	Alert,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Share';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import useRequest from './hooks/useRequest';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import InviteFreind from './views/Dialog/InviteFreind';
//import { Trans } from 'react-i18next';
import request from '../helpers/myAxios';
import { Button, Tooltip } from '@mui/material';
import Logo from '../images/logo_plus.png';
import { useAudioRecordingSession } from './providers/AudioRecordingSessionProvider';
import { green } from '@mui/material/colors';
import { AxiosError, AxiosResponse } from 'axios';
import { useNotify } from './providers/NotifyProvider';
import { useHelp } from './providers/HelpModal';
import { LinkedinShareButton, FacebookShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon } from 'react-share';
import { userDetailsContext } from './providers/UserDetailsProvider';
import { grey, red } from '@mui/material/colors';
import ReferralCode from './views/Dialog/ReferralCode';
import Fade from '@mui/material/Fade';
import { FaBlackTie, FaClosedCaptioning } from 'react-icons/fa';

const useStyles = makeStyles()((theme) => ({
	bar: {
		zIndex: theme.zIndex.drawer + 1,
		//color: "whitesmoke",
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		display: 'flex',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
		paddingRight: '20px',
		color: 'white',
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	createButton: {
		marginLeft: '10px',
	},
	link: {
		color: theme.palette.primary.main,
		textDecoration: 'inherit',
		/*...theme.typography.caption,*/
		fontSize: 16,
		fontWeight: 'bold',
		paddingLeft: 10,
		paddingRight: 10,
	},
	currentLocation: {
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
}));

interface PrimaryAppBarProps {
	onLogout: Function;
	/*onThemeTypeChange: (type: 'light' | 'dark') => void*/
}

const mediaButtonSize = 35;
const drawerWidth = 250;
const drawerShortCutList = [
	{
		title: "Démarrer l'enregistrement",
		label: 'flèche vers le bas',
		listicon: <FiberManualRecord sx={{ fontSize: 21, color: red[500] }} />,
		color: 'red',
	},
	{
		title: "Arrêter l'enregistrement",
		label: 'flèche vers le bas',
		listicon: <StopCircle sx={{ fontSize: 21, color: red[500] }} />,
		color: 'red',
	},
	{
		title: "Recommencer l'enregistrement",
		label: 'flèche vers le bas',
		listicon: <ChangeCircle sx={{ fontSize: 21, color: red[500] }} />,
		color: 'red',
	},
	{
		title: 'Dictée précédente',
		label: 'flèche vers la gauche',
		listicon: <SkipPrevious sx={{ fontSize: 21 }} />,
		color: 'red',
	},
	{
		title: 'Dictée suivante',
		label: 'flèche vers la droite',
		listicon: <SkipNext sx={{ fontSize: 21 }} />,
		color: 'red',
	},
];

const DrawerComp = (props: any) => {
	const [openDrawer, setOpenDrawer] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();

	const { getTotalAudioDuration, resetRecordingSession, getProgression } =
		useAudioRecordingSession();
	const { loggerName, isAdmin, handleNavigateToAdmin, openHelpModal, onLogout, onSendInvitation } =
		props;

	const progressValue = getTotalAudioDuration(); /*Math.round(getProgression())*/
	let displayedProgressValue = '0/2';
	if (progressValue >= 30 && progressValue < 60) {
		displayedProgressValue = '1/2';
	} else if (progressValue === 60 || getProgression() >= 100) {
		displayedProgressValue = '2/2';
	}

	const textLengthTotalProgress = getProgression();
	const audioLengthTotalProgress = Math.round((progressValue / 60) * 100);

	const Participation1Progress = progressValue <= 30 ? Math.round((progressValue / 30) * 100) : 100;
	const Participation2Progress =
		progressValue <= 30
			? 0
			: audioLengthTotalProgress > textLengthTotalProgress
				? audioLengthTotalProgress
				: textLengthTotalProgress;
	return (
		<>
			<Drawer
				//sx={{ paper: '#235A7B' }}
				open={openDrawer}
				onClose={() => {
					setOpenDrawer(false);
				}}
				sx={{
					'& .MuiDrawer-paper': {
						backgroundColor: 'black',
						width: drawerWidth,
						overflowX: 'hidden',
						overflowY: 'auto',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						//paddingRight: 10,
						alignItems: 'center',
						minHeight: '100%',
						maxWidth: '100%',
					}}
				>
					<Box sx={{ width: '100%' }}>
						{isAdmin && location.pathname === '/admin' ? (
							<List>
								{/*<ListItemButton
									onClick={() => {
										navigate('/textrecorder');
									}}
									alignItems="center"
								>
									<ListItemIcon sx={{}}>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												flexDirection: 'row',
												//paddingRight: 10,
												alignItems: 'center',
												width: '100%',
											}}
										>
											<ArrowBackIcon color="success" fontSize="small" />
											<ListItemText
												sx={{ mr: '15px', color: '#66bb6a' }}
												primary={'Retour'.toUpperCase()}
											/>
										</Box>
									</ListItemIcon>
										</ListItemButton>*/}
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
										padding: 1,
										alignItems: 'center',
										width: '100%',
									}}
								>
									<Button
										onClick={() => {
											navigate('/textrecorder');
										}}
										startIcon={<ArrowBackIcon color="success" fontSize="small" />}
									>
										retour
									</Button>
									<IconButton
										onClick={() => {
											setOpenDrawer(false);
										}}
									>
										<CloseIcon />
									</IconButton>
								</Box>
							</List>
						) : (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'row',
									padding: 1,
									alignItems: 'center',
									width: '100%',
								}}
							>
								<Box />
								<IconButton
									onClick={() => {
										setOpenDrawer(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							</Box>
						)}
						<Divider />
						<List>
							<ListItemButton>
								<ListItemIcon>
									<FacebookShareButton
										style={{ marginRight: '8px',}}
										url={`https://concours.imagemsoft.com/`}
										quote={'SR Recorder.-Imagem'}
										hashtag={'#ImagemConcours'}
										title={'Partager sur facebook'}
									>
										<FacebookOutlinedIcon sx={{color: 'white',height: '30px', width: '30px',}} />{/*<FacebookIcon size={mediaButtonSize} iconFillColor="white" bgStyle={{background: 'black'}} round={true} />*/}
									</FacebookShareButton>
									<ListItemText primary={'Facebook'} />
								</ListItemIcon>
							</ListItemButton>
							<ListItemButton>
								<ListItemIcon>
									<LinkedinShareButton
										style={{ marginRight: '8px' }}
										url={`https://concours.imagemsoft.com/`}
										title={'Partager via LinkedIn'}
										//hashtag={'#ImagemConcours'}
										summary={'Dictez des phrases et courrez la chance de gagner $1000 canadien'}
									>
										<LinkedInIcon sx={{color: 'white',height: '30px', width: '30px',}} />{/*<LinkedinIcon size={mediaButtonSize} round />*/}
									</LinkedinShareButton>
									<ListItemText primary={'Linked'} />
								</ListItemIcon>
							</ListItemButton>
							<ListItemButton onClick={onSendInvitation}>
								<ListItemIcon>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'flex',
											flexDirection: 'row',
											//paddingRight: 10,
											alignItems: 'center',
										}}
									>
										<SettingsIcon sx={{ mr: 1, color: 'white' }} />
										<ListItemText primary={'Inviter un ami'} />
									</Box>
								</ListItemIcon>
							</ListItemButton>
							{!props.isAdmin && (
								<ListItemButton onClick={props.onEditReferralCode}>
									<ListItemIcon>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'flex',
												flexDirection: 'row',
												//paddingRight: 10,
												alignItems: 'center',
											}}
										>
											<Edit sx={{ mr: 1, color: 'white' }}/>
											<ListItemText primary={'Code de référence'} />
										</Box>
									</ListItemIcon>
								</ListItemButton>
							)}
						</List>
						<Divider />
						<List sx={{ minWidth: '100%' }}>
							<ListItemButton disabled>
								<Box
									sx={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										maxWidth: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
									}}
								>
									<Alert severity="info">
										L'exercice consiste à dicter les phrases (incluant le texte affiché en gras).
										Elle dure entre 35 et 65 minutes.
									</Alert>
								</Box>
							</ListItemButton>
							<ListItemButton disabled>
								<Box
									sx={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										maxWidth: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
									}}
								>
									<div className="sidecard">
										<h3>Vos participations:</h3>
										<div>
											<b>1 participation</b> = <i>avoir complété 30 minutes de dictées.</i>
											<br />
											<b>2 participations</b> = <i> avoir complété l'exercice.</i>
										</div>
										<span className="sidetop"></span>
										<span className="sideright"></span>
										<span className="sidebottom"></span>
										<span className="sideleft"></span>
									</div>
								</Box>
							</ListItemButton>
							<ListItemButton disabled>
								<Box
									sx={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										width: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
									}}
								>
									<Alert severity="info">
										À tout moment vous pouvez quitter et revenir plus tard.
									</Alert>
								</Box>
							</ListItemButton>
							<ListItemButton disabled>
								<Box
									sx={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										width: '100%',
										//mt: `${idx === 0 ? '15px' : ''}`,
										mb: 2,
									}}
								>
									<Alert severity="info">
										Utiliser l'enregistrement automatique et les raccourcis clavier pour dicter plus
										rapidement.
									</Alert>
								</Box>
							</ListItemButton>
							{/*{drawerShortCutList &&
								drawerShortCutList.map((item, idx) => (
									<ListItemButton disabled>
										<ListItemIcon>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'flex',
													flexDirection: 'row',
													//paddingRight: 10,
													alignItems: 'center',
												}}
											>
												{item.listicon}
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'Column',
														paddingRight: 10,
														ml: 2,
													}}
												>
													<Typography variant="subtitle2">{item.title}</Typography>
													<Typography variant="caption" color={grey[500]}>
														{`[ ${item.label} ]`}
													</Typography>
												</Box>
											</Box>
										</ListItemIcon>
									</ListItemButton>
								))}*/}
						</List>

						<Divider />
						<List>
							{isAdmin && (
								<>
									<ListItemButton onClick={handleNavigateToAdmin}>
										<ListItemIcon>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'flex',
													flexDirection: 'row',
													//paddingRight: 10,
													alignItems: 'center',
												}}
											>
												<AdminPanelSettingsIcon />
												<ListItemText
													sx={{ ml: 2, fontSize: '2rem', fontWeight: 'bold' }}
													primary={'Administration'.toUpperCase()}
												/>
											</Box>
										</ListItemIcon>
									</ListItemButton>
								</>
							)}

							<ListItemButton disabled>
								<ListItemIcon>
									<Tooltip
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
										title={
											<div>
												1 participation = avoir complété 30 minutes de dictées
												<br />2 participation = avoir complété l'exercice au complet
											</div>
										}
										arrow
									>
										<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography
													sx={{ fontSize: '12px' }}
													variant="body2"
													color="text.secondary"
												>
													{'Participation 1: '.toUpperCase()}
												</Typography>
												<Typography
													sx={{
														color: 'white',
														ml: 1,
														fontWeight: 'bold',
														fontSize: '12px',
													}}
													variant="body2"
													color="text.secondary"
												>
													{`${Participation1Progress} %`}
												</Typography>
											</Box>

											<Divider />

											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography
													sx={{ fontSize: '12px' }}
													variant="body2"
													color="text.secondary"
												>
													{'Participation 2: '.toUpperCase()}
												</Typography>
												<Typography
													sx={{
														color: 'white',
														ml: 1,
														fontWeight: 'bold',
														fontSize: '12px',
													}}
													variant="body2"
													color="text.secondary"
												>
													{`${Participation2Progress} %`}
												</Typography>
											</Box>
										</Box>
									</Tooltip>
								</ListItemIcon>
							</ListItemButton>
							<ListItemButton onClick={openHelpModal}>
								<ListItemIcon>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<HelpIcon />
										<ListItemText
											sx={{ ml: 2, fontSize: '2rem', fontWeight: 'bold' }}
											primary={'Aide'.toUpperCase()}
										/>
									</Box>
								</ListItemIcon>
							</ListItemButton>
						</List>
					</Box>
					<Box sx={{ width: '100%' }}>
						<Divider />
						<List>
							<ListItemButton onClick={onLogout}>
								<ListItemIcon>
									<Tooltip title={'Déconnexion'}>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'flex',
												flexDirection: 'row',
												//paddingRight: 10,
												alignItems: 'center',
											}}
										>
											<LogoutIcon />
											<ListItemText
												sx={{ ml: 2, fontSize: '2rem', fontWeight: 'bold' }}
												primary={loggerName.toUpperCase()}
											/>
										</Box>
									</Tooltip>
								</ListItemIcon>
							</ListItemButton>
						</List>
					</Box>
				</Box>
			</Drawer>
			<IconButton
				onClick={() => {
					setOpenDrawer(!openDrawer);
				}}
			>
				<MenuIcon></MenuIcon>
			</IconButton>
		</>
	);
};
const PrimaryAppBar = (props: PrimaryAppBarProps) => {
	const { userDetails, saveUser, isAdmin } = useContext(userDetailsContext);
	const [settingsAnchorEl, setSettingsAnchorEl] = React.useState(null);
	const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
	const [invitModalOpen, setInvitModalOpen] = React.useState(false);
	const [referralCode, setReferralCode] = React.useState(false);
	const { audioRecordingSession, getTotalAudioDuration, resetRecordingSession, getProgression } =
		useAudioRecordingSession();
	const navigate = useNavigate();
	const location = useLocation();
	const notify = useNotify();
	const help = useHelp();
	const theme = useTheme();
	const isMatch = useMediaQuery(theme.breakpoints.down('md'));
	const isProfileMenuOpen = Boolean(profileAnchorEl);
	const { doRequest, errors } = useRequest();

	const handleSettingsMenuOpen = (event: React.BaseSyntheticEvent) => {
		setSettingsAnchorEl(event.currentTarget);
	};

	const handleSettingsMenuClose = () => {
		setSettingsAnchorEl(null);
	};

	const isUserLoggedIn = () => {
		return !userDetails ? false : true;
	};

	const handleNavigateToAdmin = () => {
		navigate('/admin');
	};

	useEffect(() => {
		notify.error(errors);
	}, [errors]);

	const handleLogout = async () => {
		await doRequest({
			url: '/auth/logout',
			method: 'get',
			body: null,
			onSuccess: null,
		});
		props.onLogout();
		resetRecordingSession();
		handleSettingsMenuClose();
		handleProfileMenuClose();
		saveUser(undefined);
		navigate('/login');
	};

	const handleProfileMenuOpen = (event: React.BaseSyntheticEvent) => {
		setProfileAnchorEl(event.currentTarget);
	};

	const handleProfileMenuClose = () => {
		setProfileAnchorEl(null);
	};

	const updateUserReferralCode = async (code: string) => {
		await doRequest({
			url: `/user/${userDetails.id}`,
			method: 'put',
			body: { referral_code: code },
			onSuccess: (res: any) => {
				if (res) {
					saveUser(res.user);
					setReferralCode(false);
				} else {
				}
			},
		});
	};
	const loggerName =
		userDetails && userDetails.firstName && userDetails.lastName
			? userDetails.firstName + ' ' + userDetails.lastName
			: userDetails && userDetails.name
				? userDetails.name
				: '';

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={profileAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isProfileMenuOpen}
			onClose={handleProfileMenuClose}
		>
			<MenuItem onClick={handleLogout}>
				{' '}
				<ExitToAppIcon />
				{'Déconnexion'}
			</MenuItem>
		</Menu>
	);

	const duration1 = getTotalAudioDuration(); /*Math.round(getProgression())*/
	const duration2 = (duration1 > 30) ? (duration1 - 30) : 0

	const Participation1Progress = duration1 <= 30 ? Math.round((duration1 / 30) * 100) : 100;
	const Participation2Progress = duration2 <= 30 ? Math.round((duration2 / 30) * 100) : 100;

	return (
		<>
			<AppBar sx={{ zIndex: !isMatch ? theme.zIndex.drawer + 1 : '' }} >
				<Toolbar
					disableGutters
					sx={{
						left: 0,
						px: 2,
						justifyContent: 'space-between',
						display: 'flex',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex',
							flexDirection: 'row',
							//paddingRight: 10,
							alignItems: 'center',
							fontSize: '2rem',
							fontWeight: 'bold',
						}}
					>
						{/*<img
							style={{ height: 35, marginRight: '0.5rem', color: 'white' }}
							src={Logo}
							alt="logo"
						/>
						<Typography color="textPrimary" variant="h5">
							{isMatch ? 'Concours-Imagem' : 'Dicte et cours la chance de gagner jusqu’à 1 000 $ !'}
						</Typography>*/}
					</Box>

					{isUserLoggedIn() ? (
						isMatch ? (
							<>
								<Box sx={{ marginLeft: 'auto' }}>
									<DrawerComp
										onEditReferralCode={() => {
											setReferralCode(true);
										}}
										loggerName={loggerName}
										isAdmin={isAdmin()}
										handleNavigateToAdmin={handleNavigateToAdmin}
										onLogout={handleLogout}
										onSendInvitation={() => {
											setInvitModalOpen(true);
										}}
										openHelpModal={() => {
											help.openModal();
										}}
									/>
								</Box>
							</>
						) : (
							<>
								<Box
									sx={{
										display: 'flex',
										//justifyContent: 'center',
										flexDirection: 'row',
										//marginLeft: 65,
										//alignItems: 'center',
										alignSelf: 'center',
									}}
								>
									<InfoOutlinedIcon sx={{color: 'white', height: '40px', width: '40px', mr: 70,mt: 1}} />
									<FacebookShareButton
										style={{ marginRight: '8px' }}
										url={`https://concours.imagemsoft.com/index`}
										quote={'Concours - Imagem'}
										hashtag={'#ImagemConcours'}
										title={'Partager sur facebook'}
									>
										<FacebookOutlinedIcon sx={{color: 'white', height: '40px', width: '40px',}}/>{/*<FacebookIcon size={mediaButtonSize} round />*/}
									</FacebookShareButton>

									<LinkedinShareButton
										style={{ marginRight: '8px' }}
										url={`https://concours.imagemsoft.com/index`}
										title={'Partager via LinkedIn'}
										//hashtag={'#ImagemConcours'}
										summary={'Dictez des phrases et courrez la chance de gagner $500 canadien'}
									>
										<LinkedInIcon sx={{color: 'white',height: '40px', width: '40px',}} />{/*<LinkedinIcon size={mediaButtonSize} round />*/}
									</LinkedinShareButton>

									<Divider orientation= 'vertical' flexItem />
									<Button
										sx={{ mr: 5 }}
										onClick={() => {
											setInvitModalOpen(true);
										}}
										color="inherit"
										startIcon={<SettingsIcon sx={{color: 'white', height: '40px', width: '40px',}}/>}
									>
										<Typography variant="button"> {'Inviter un ami'}</Typography>
									</Button>
									{!isAdmin() && (
										<Button
											onClick={() => {
												setReferralCode(true);
											}}
											color="inherit"
											endIcon={<Edit sx={{color: 'white',}} />}
										>
											<Typography variant="button"> {'Code de référence'}</Typography>
										</Button>
									)}
								</Box>
								{location.pathname !== '/legal/terms' ? (
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
										}} /*className={classes.sectionDesktop}*/
									>
										{userDetails && isAdmin() && (
											<Box sx={{ marginLeft: 'auto', mr: 1 }}>
												<Button onClick={handleNavigateToAdmin} color="inherit">
													<AdminPanelSettingsIcon sx={{ mr: 1 }} />
													<Typography variant="button"> {'Administration'}</Typography>
												</Button>
											</Box>
										)}
										{audioRecordingSession.audioRecordingBook && (
											<Tooltip
												TransitionComponent={Fade}
												TransitionProps={{ timeout: 600 }}
												title={
													<div>
														1 participation = avoir complété 30 minutes de dictées
														<br />2 participation = avoir complété l'exercice au complet
													</div>
												}
												arrow
											>
												<Box
													sx={{ 
														display: 'flex', 
														flexDirection: 'column', 
														alignItems: 'center',
														mr: 20, 
														border: '1px solid white',
														p: 1,
														borderRadius: '30px',
														mt: 0.5,
														width: '200px'
													}}
												>
													<Box sx={{ display: 'flex', alignItems: 'center' }}>
														<Typography
															sx={{ fontSize: '12px' }}
															variant="body2"
															color="text.secondary"
														>
															{'Participation 1: '.toUpperCase()}
														</Typography>
														<Typography
															sx={{
																color: 'white',
																ml: 1,
																fontWeight: 'bold',
																fontSize: '12px',
															}}
															variant="body2"
														>
															{`${Participation1Progress} %`}
														</Typography>
													</Box>

													<Divider orientation="horizontal" flexItem />

													<Box sx={{ display: 'flex', alignItems: 'center' }}>
														<Typography
															sx={{ fontSize: '12px' }}
															variant="body2"
															color="text.secondary"
														>
															{'Participation 2: '.toUpperCase()}
														</Typography>
														<Typography
															sx={{
																color: 'white',
																ml: 1,
																fontWeight: 'bold',
																fontSize: '12px',
															}}
															variant="body2"
														>
															{`${Participation2Progress} %`}
														</Typography>
													</Box>
												</Box>
											</Tooltip>
										)}
										<Box sx={{ mr: 10, bgcolor: '#EDEAE6' }}>
											<Button
												startIcon={<AccountCircleIcon sx={{ mr: 1, color:'black' }} />}
												onClick={handleProfileMenuOpen}
												color="inherit"
											>
												<Typography variant="button" sx={{color:'black' }}> {loggerName}</Typography>
											</Button>
										</Box>
									</Box>
								) : null}
							</>
						)
					) : null}
				</Toolbar> 
				{renderMenu}
				<InviteFreind
					open={invitModalOpen}
					closeModal={() => {
						setInvitModalOpen(false);
					}}
				/>
				<ReferralCode
					value={userDetails ? userDetails.referral_code : ''}
					open={referralCode}
					onSubmit={updateUserReferralCode}
					closeModal={() => {
						setReferralCode(false);
					}}
				/>
			</AppBar>
		</>
	);
};

export default PrimaryAppBar;
