import styled, { createGlobalStyle } from 'styled-components';
import { FaAngleUp } from 'react-icons/fa';
const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
 } 
`;

export const Container = styled.div`
	z-index: 1;
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 50px;
	padding-left: 50px;

	@media screen and (max-width: 991px) {
		padding-right: 30px;
		padding-left: 30px;
	}
`;

export const Button = styled.button`
	border-radius: 4px;
	background: ${({ primary }) => (primary ? '#4B59F7' : '#0467FB')};
	white-space: nowrap;
	padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
	color: #fff;
	font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
	outline: none;
	border: none;
	cursor: pointer;

	&:hover {
		transition: all 0.3s ease-out;
		background: #fff;
		background-color: ${({ primary }) => (primary ? '#0467FB' : '#4B59F7')};
	}

	@media screen and (max-width: 960px) {
		width: 100%;
	}
`;

export const GoToTopBtnContainer = styled.div`
	position: relative;
`;

export const GoToTopIconBtn = styled(FaAngleUp)`
	position: fixed;
	bottom: 40px;
	right: 25px;
	z-index: 20;
	background-color: #4b59f7;
	border: 2px solid #fff;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	color: #fff;
	cursor: pointer;
	animation: movebtn 3s ease-in-out infinite;
	transition: all 0.5s ease-in-out;
	&:hover {
		animation: none;
		background: #fff;
		color: #4b59f7;
		border: 2px solid #4b59f7;
	}
`;
export default GlobalStyle;
