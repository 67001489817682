import React, { useState, KeyboardEvent, Children } from 'react';
import axios from 'axios';
import { Container, Box, TextField, Checkbox, Button, Typography, InputAdornment, IconButton, FormControlLabel } from '@mui/material';
import { Person, Lock, BorderColor } from '@mui/icons-material';
import request from '../../helpers/myAxios';
import { Navigate, useNavigate } from 'react-router-dom';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { AxiosError, AxiosResponse } from 'axios';
import { isUserLoggedIn } from '../../helpers/session';
import { Link } from 'react-router-dom';
import background from '../../images/bg-animé.gif'; // Import using relative path
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
//import i18next from '../../i18n';

interface LoginProps extends ReactCookieProps {
	onLogin: (user: any, token: string, expiresIn: number) => void;
}

const Login = (props: LoginProps) => {
	let usernameInput: any = null;
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const navigate = useNavigate();

	const handleLogin = () => {
		try {
			setLoading(true);
			if (errorMsg) setErrorMsg('');
			if (username && password) {
				const req = request();
				req.post('/auth/login', { email: username, password: password })
					.then((res: AxiosResponse) => {
						setUsername('');
						setPassword('');
						if (res.status === 200 && res.data.accessToken) {
							//Cookies.set("access_token", res.data.accessToken);
							const user = res.data.user;

							props.onLogin(user, res.data.accessToken, 0);
							navigate('/');
						}
						setLoading(false);
					})
					.catch((err: Error | AxiosError) => {
						usernameInput?.focus();
						if (
							axios.isAxiosError(err) &&
							/*err.response?.data &&
						err.response?.data.code &&*/
							err.response?.data?.message === 'Invalid email or password'
						)
							setErrorMsg('Utilisateur / Mot de passe invalide.');
						else {
							setErrorMsg('Impossible de se connecter au serveur. Notre équipe est sur le coup.');
						}
						setLoading(false);
					});
			} else {
				setErrorMsg('Impossible de se connecter.');
				usernameInput?.focus();

				setLoading(false);
			}
		} catch (error) {
			setErrorMsg("Impossible de se connecter : Erreur interne à l'application. Notre équipe est sur le coup.");
		}
	};

	const handleKeyPress = (e: KeyboardEvent) => {
		if (e.key === 'Enter') handleLogin();
	};

	const [checked, setChecked] = React.useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	if (isUserLoggedIn()) return <Navigate to="/textrecorder" />;

	return (
		<>
			<Box
				component="main"
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexGrow: 1,
					minHeight: '100%',
					//backgroundImage: `url(${background})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					padding: 2,
					'& fieldset': {
						paddingLeft: (theme) => theme.spacing(2.5),
						borderRadius: '30px',
					},
				}}
			>
				<Container sx={{ backgroundColor: '#EDEAE6', borderRadius: '30px' }} maxWidth={'xs'}>
					<Box
						sx={{
							my: 3,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<Typography
								color="textPrimary"
								variant="h4"
								sx={{
									color: '#1E1E1E',
									fontSize: { xs: '1rem', md: '2.5rem' },
									fontWeight: 600,
									mb: -1,
									letterSpacing: -4,
									textTransform: 'uppercase',
									fontFamily: 'Anton',
								}}
							>
								{'Bon  retour!'}
							</Typography>
						</Box>

						<Box
							sx={{
								pb: 1,
								pt: 1,
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									inputProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
									fullWidth
									margin="normal"
									label={'Courriel'}
									autoFocus
									required
									color="secondary"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									inputRef={(input) => (usernameInput = input)}
									size="small"
									name="email"
									variant="outlined"
								/>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									mb: -0.5,
								}}
							>
								<TextField
									InputLabelProps={{
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
											textTransform: 'uppercase',
										},
									}}
									id="password"
									label={'Mot de passe'}
									type={showPassword ? 'text' : 'password'}
									size="small"
									fullWidth
									required
									color="secondary"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									onKeyDown={handleKeyPress}
									margin="normal"
									name="email"
									variant="outlined"
									InputProps={{
										// <-- This is where the toggle button is added.
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													color="secondary"
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
										style: {
											color: '#1E1E1E',
											fontFamily: 'Baskerville',
										},
									}}
								/>
							</Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontFamily: 'Baskerville',
								}}
							>
								<Typography variant="caption" color="error">
									{errorMsg}
								</Typography>
							</Box>

							<Typography
								color="textSecondary"
								variant="body2"
								sx={{
									fontSize: '0.9rem',
									mb: '1rem',
									ml: '1rem',
									color: '#1E1E1E',
									fontFamily: 'baskerville',
								}}
							>
								<Link
									style={{
										color: 'inherit',
									}}
									to="/forgotpass"
								>
									{'Mot de passe oublié ?'}
								</Link>
							</Typography>
							<FormControlLabel
								sx={{
									ml: 0.5,
									color: 'black',
									fontFamily: 'Baskerville',
									fontSize: 5,
								}}
								label="Je consens à ce que mes données soient utilisées pour l’update vocale."
								control={
									<Checkbox
										color="secondary"
										checked={checked}
										onChange={handleChange}
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{
											color: 'black',
											'& .MuiSvgIcon-root': { fontSize: 18 },
										}}
									/>
								}
							/>
							<Box sx={{ py: 2 }}>
								<Button
									style={{
										textTransform: 'uppercase',
										marginBottom: '8px',
										color: 'black',
										background: 'none',
										border: 'none',
										fontWeight: 'bolder',
										fontSize: '1.5rem',
										lineHeight: 0.5,
										fontFamily: 'Anton',
										letterSpacing: '-2px',
									}}
									onClick={handleLogin}
									fullWidth
									type="submit"
									variant="text"
									disabled={loading}
								>
									{loading ? 'Connexion en cours...' : 'Connexion'}
								</Button>
							</Box>

							<Typography
								color="textSecondary"
								variant="body2"
								sx={{
									fontSize: '0.9rem',
									ml: '2rem',
									textTransform: 'uppercase',
									color: '#1E1E1E',
									fontFamily: 'baskerville',
								}}
							>
								{"Vous n'avez pas de compte ? "}
								<Link
									style={{
										color: 'inherit',
										fontWeight: 'bold',
									}}
									to="/register"
								>
									{" S'inscrire"}
								</Link>
							</Typography>
						</Box>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default withCookies(Login);
