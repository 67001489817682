import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { sessionClear } from '../../helpers/session';
import { useUserDetails } from '../providers/UserDetailsProvider';
import { Box, Button, Typography, FormControlLabel, Checkbox, FormGroup, Stack, Backdrop, CircularProgress } from '@mui/material';
import request from '../../helpers/myAxios';
import { grey } from '@mui/material/colors';
import { useConfirmationModalContext } from '../providers/ConfirmModalContext';
import useRequest from '../hooks/useRequest';
import { useNotify } from '../providers/NotifyProvider';
const Legal = () => {
	const { doRequest, errors, loading } = useRequest();
	const notify = useNotify();
	const [termAccepted, setTermAccepted] = useState(false);
	const navigate = useNavigate();
	const session = useUserDetails();
	const user = session.userDetails;
	const modalContext = useConfirmationModalContext();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTermAccepted(event.target.checked);
	};

	useEffect(() => {
		notify.error(errors);
	}, [errors]);

	const handleConfirmModalOpen = async () => {
		const result = await modalContext.showConfirmation(
			'Confirmation',

			<p>
				Vous devez accepter notre <strong>politique de confidentialité et utilisation des données</strong> pour accéder à
				l'application. Voulez-vous continuer ?
			</p>,

			'Continuer',
			'Annuler'
		);
		if (result) {
			handleCancel();
		} else {
		}
	};

	const handleCancel = () => {
		session.saveUser(undefined);
		sessionClear();

		navigate('/login');
	};
	const handleTermsAccepted = async () => {
		if (termAccepted && user !== null) {
			await doRequest({
				url: `/user/${user.id}`,
				method: 'put',
				body: { legal_terms_accepted: termAccepted },
				onSuccess: (res: any) => {
					if (res) {
						session.saveUser(res.user);
						navigate('/textrecorder');
					} else {
						notify.error('Erreur lors de la Requête au serveur.');
					}
				},
			});
		}
	};

	if (user && (user.legal_terms_accepted || user.role === 'admin')) {
		navigate('/textrecorder');
		return null;
	}
	return (
		<Box sx={{ p: 5, mt: 5 }}>
			<Box
				sx={{
					p: 5,
					border: 1,
					borderColor: grey[500],
					width: '80hw',
					height: '75vh',
					overflowY: 'scroll',
				}}
			>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={!loading}
					//onClick={handleClose}
				>
					<Box sx={{ position: 'relative', display: 'inline-flex' }}>
						<CircularProgress variant="determinate" size={30} />
						<Box
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography variant="caption" component="div" color="text.secondary">
								{'chargement...'}
							</Typography>
						</Box>
					</Box>
				</Backdrop>
				<Box sx={{ mb: 5 }}>
					<Typography variant="h3">Politique de confidentialité et utilisation des données</Typography>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Enregistrements
					</Typography>

					<p>
						Les enregistrements audios recueillis serviront à bonifier le système de reconnaissance vocale pour le français
						québécois. Les textes utilisés sont trois textes d’auteurs d’ici qui nous ont accordé les droits d’auteurs pour les
						extraits présentés.
					</p>

					<Typography variant="h5" sx={{ mt: 2 }}>
						Données personnelles
					</Typography>

					<p>
						Les données demandées au début de l’exercice sont uniquement afin de catégoriser les enregistrements audios, de
						façon à obtenir un échantillonnage le plus équitable au niveau des particularités de langage du Québec. Aucune de
						ces données ne sera échangée à des compagnies tierces ou utilisées afin de vous rejoindre à des buts commerciaux et
						les données personnelles seront détruites au plus tard le 15 janvier 2025.
					</p>
				</Box>
				<Typography variant="h3">Règlement du concours</Typography>

				<Typography variant="h5" sx={{ mt: 2 }}>
					Admissibilité
				</Typography>

				<p>Afin de pouvoir participer à ce concours, vous devez&nbsp;:</p>

				<p>
					<span>
						<span>
							a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						</span>
					</span>
					Être une personne dont la langue maternelle est le français originaire du Canada ou résider au Québec et utiliser le
					français couramment et être un étudiant du Cégep de Jonquière;
				</p>

				<p>
					<span>
						<span>
							b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						</span>
					</span>
					Fournir un courriel valide.
				</p>

				<p>
					<span>
						<span>
							c)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						</span>
					</span>
					Fournir des enregistrements audios valides.
				</p>

				<Typography variant="h5" sx={{ mt: 2 }}>
					Période du concours
				</Typography>

				<p>
					Le concours est actif du 10 septembre au 10 octobre 2024 ou peut être extensionné jusqu’à ce que nous ayons atteint le
					nombre requis d'enregistrements (500 heures).{' '}
				</p>

				<Typography variant="h5" sx={{ mt: 2 }}>
					Comment participer au concours
				</Typography>

				<p>
					Se rendre directement sur le site internet du concours, remplir les informations obligatoires demandées et compléter les
					enregistrements demandés par l’application web.
				</p>

				<Typography variant="h5" sx={{ mt: 2 }}>
					Vérification
				</Typography>

				<p>
					Tous les enregistrements seront vérifiés manuellement par rapport aux textes originaux. En cas de discordance majeure,
					Imagem se réserve le droit de retirer les participations.{' '}
				</p>

				<Typography variant="h5" sx={{ mt: 2 }}>
					Les prix
				</Typography>

				<p>Un Macbook Air sera tiré le 15 octobre 2024.</p>

				<Typography variant="h5" sx={{ mt: 2 }}>
					Limitation de la responsabilité
				</Typography>

				<p>
					Le Commanditaire (Les systèmes médicaux Imagem inc.) n’assume aucune responsabilité pour les inscriptions, les
					formulaires de déclaration et quittance ou les courriels perdus, retardés, détruits ou mal dirigés ou pour quelque
					défaut technique notamment en raison d’un ordinateur, d’un logiciel, d’un téléphone ou de la connexion Internet.
				</p>

				<p>
					Le Commanditaire n’assume aucune responsabilité pour tout renseignement erroné ou inexact, qu’il soit causé par quelques
					équipement que ce soit ou programmation associés ou utilisés pour le Concours ou pour toute erreur humaine ou technique
					qui peut se produire au cours de l’administration du Concours.{' '}
				</p>

				<p>
					Tel que permis par la loi, le Commanditaire se réserve le droit, avec le consentement de la Régie des alcools, des
					courses et des jeux (la « Régie ») si nécessaire, d’annuler, de suspendre ou de modifier le Concours si un virus, un
					bogue, un problème informatique, une intervention humaine non autorisée ou une autre cause en dehors du contrôle du
					Commanditaire, altère ou affecte l’administration, la sécurité, l’équité, l’intégrité ou la bonne conduite de ce
					Concours{' '}
				</p>

				<Typography variant="h5" sx={{ mt: 2 }}>
					Propriété intellectuelle
				</Typography>

				<p>
					Toute la propriété intellectuelle et tout le matériel promotionnel, pages web et codes sources sont la propriété du
					Commanditaire et/ou de ses filiales et tous les droits leur sont réservés. La copie ou l’usage non autorisé de matériel
					protégé par des droits d’auteur ou des marques de commerce sans le consentement exprès et écrit de son propriétaire est
					strictement interdit.{' '}
				</p>
			</Box>

			<Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
				<Stack>
					<FormGroup>
						<FormControlLabel
							control={<Checkbox checked={termAccepted} onChange={handleChange} name="legal" />}
							label="En continuant vous acceptez les conditions d'utilisation et la politique de confidentialité du concours."
						/>
					</FormGroup>
					<Box display="flex" justifyContent="center" alignItems="center">
						<Button sx={{ mr: 2 }} onClick={handleTermsAccepted} variant="contained" color="primary" disabled={!termAccepted}>
							{'Continuer'}
						</Button>
						<Button variant="contained" onClick={handleConfirmModalOpen} color="secondary">
							{'Annuler'}
						</Button>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
};

export default Legal;
