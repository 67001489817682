import svg1 from '../images/svg-1.svg';
import svg2 from '../images/svg-3.svg';

export const homeObj1 = {
	primary: true,
	lightBg: false,
	lightTopLine: false,
	lightText: true,
	lightTextDesc: true,
	topLine: " Le concours se termine après 500 heures d'enregistrements",
	headline: 'Participe pour gagner !',
	description:
		"En complétant les dictées, vous courez la chance de gagner 1 000 $ ou l'un des 4 prix de 250 $ .",
	buttonLabel: 'Participe au concours',
	imgStart: 'start',
	img: require('../images/concours.png'),
	alt: 'Vault',
	start: 'true',
	path: '/register',
	addBtn: true,
	isForm: false,
};

export const homeObj2 = {
	primary: false,
	lightBg: true,
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	topLine: '',
	headline: 'Simple à utiliser',
	description:
		"Une fois connecté(e), vous serez dirigé(e) vers une interface simple et conviviale. Il ne vous restera qu'à lire les textes affichés à l'écran. Vous pourrez revenir à tout moment pour compléter vos dictées. L'exercice peut être fait sur votre ordinateur ou votre tablette.",
	buttonLabel: 'Learn More',
	imgStart: '',
	img: svg2,
	alt: 'video',
	start: '',
	path: '/register',
	addBtn: false,
	isForm: false,
};

export const homeObj3 = {
	primary: false,
	lightBg: false,
	lightTopLine: true,
	lightText: true,
	lightTextDesc: true,
	topLine: '',
	headline: 'Spécialiste et chef de file de la dictée et transcription au Québec',
	description:
		"Nous aidons de nombreuses entreprises dans la rédaction de rapports grâce à la reconnaissance vocale. Dans le but d'améliorer davantage notre engin de reconnaissance vocale, nous sollicitons votre participation afin de collecter des enregistrements en français canadien.",
	buttonLabel: 'Participe au concours',
	start: 'true',
	img: svg1,
	alt: 'none',
	imgStart: 'start',
	path: '/register',
	addBtn: false,
	isForm: false,
};

export const homeObjForm = {
	primary: false,
	lightBg: true,
	lightTopLine: false,
	lightText: false,
	lightTextDesc: false,
	topLine: '',
	headline: '',
	description: '',
	buttonLabel: '',
	imgStart: '',
	img: null,
	alt: '',
	start: '',
	path: '',
	addBtn: false,
	isForm: true,
};
