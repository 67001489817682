import { Alert, Snackbar } from '@mui/material';
import React, { useContext } from 'react';

const NotifyContext = React.createContext({} as any);

export interface NotifyProps {
	message?: string;
	type?: 'success' | 'info' | 'warning' | 'error';
	vertical: 'bottom' | 'top';
	horizontal: 'center' | 'left' | 'right';
}

export const NotifyProvider = (props: any) => {
	let [notify, setNotify] = React.useState<NotifyProps>({
		vertical: 'bottom',
		horizontal: 'right',
	});

	const error = (message: string) => {
		setNotify({ ...notify, type: 'error', message });
	};

	const success = (message: string) => {
		setNotify({ type: 'success', message, ...notify });
	};

	const warn = (message: string) => {
		setNotify({ type: 'warning', message, ...notify });
	};

	const info = (message: string) => {
		setNotify({ type: 'info', message, ...notify });
	};

	const infoTopCenter = (message: string) => {
		setNotify({ type: 'error', message, vertical: 'top', horizontal: 'center' });
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setNotify({ ...notify, message: '' });
	};
	return (
		<NotifyContext.Provider value={{ error, success, warn, info, infoTopCenter }}>
			{props.children}
			<Snackbar
				open={notify.message ? true : false}
				autoHideDuration={4000}
				onClose={handleClose}
				anchorOrigin={{ vertical: notify.vertical, horizontal: notify.horizontal }}
			>
				<Alert variant="filled" onClose={handleClose} severity={notify.type}>
					{notify.message}
				</Alert>
			</Snackbar>
		</NotifyContext.Provider>
	);
};

export const useNotify = () => {
	return useContext(NotifyContext);
};
