import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { DialogProps } from '@mui/material/Dialog';
import { ButtonProps } from '@mui/material/Button';

export interface ConfirmOptions {
    title?: React.ReactNode;
    description?: React.ReactNode;
    content?: React.ReactNode | null;
    confirmationText?: React.ReactNode;
    cancellationText?: React.ReactNode;
    dialogProps?: Omit<DialogProps, "open">;
    confirmationButtonProps?: ButtonProps;
    cancellationButtonProps?: ButtonProps;
}

export interface ConfirmProviderProps {
    defaultOptions?: ConfirmOptions;
}

const ConfirmContext = React.createContext({} as any);

const DEFAULT_OPTIONS = {
    title: 'Are you sure?',
    description: '',
    content: null,
    confirmationText: 'Ok',
    cancellationText: 'Cancel',
    dialogProps: {},
    confirmationButtonProps: {},
    cancellationButtonProps: {},
};

const buildOptions = (defaultOptions: ConfirmOptions, options: any) => {
    const dialogProps = {
        ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
        ...(options.dialogProps || {}),
    };
    const confirmationButtonProps = {
        ...(defaultOptions.confirmationButtonProps || DEFAULT_OPTIONS.confirmationButtonProps),
        ...(options.confirmationButtonProps || {}),
    };
    const cancellationButtonProps = {
        ...(defaultOptions.cancellationButtonProps || DEFAULT_OPTIONS.cancellationButtonProps),
        ...(options.cancellationButtonProps || {}),
    };

    return {
        ...DEFAULT_OPTIONS,
        ...defaultOptions,
        ...options,
        dialogProps,
        confirmationButtonProps,
        cancellationButtonProps,
    }
};

export const ConfirmProvider = ({ children, defaultOptions = {} }: any) => {
    const [options, setOptions] = React.useState({ ...DEFAULT_OPTIONS, ...defaultOptions });
    const [resolveReject, setResolveReject] = React.useState<any>([]);
    const [resolve, reject] = resolveReject;

    const confirm = React.useCallback((options = {}) => {
        return new Promise((resolve, reject) => {
            setOptions(buildOptions(defaultOptions, options));
            setResolveReject([resolve, reject]);
        });
    }, [defaultOptions]);

    const handleClose = React.useCallback(() => {
        setResolveReject([]);
    }, []);

    const handleCancel = React.useCallback(() => {
        if (reject)
            reject();
        handleClose();
    }, [reject, handleClose]);

    const handleConfirm = React.useCallback(() => {
        if (resolve)
            resolve();
        handleClose();
    }, [resolve, handleClose]);

    return (
        <React.Fragment>
            <ConfirmContext.Provider value={confirm}>
                {children}
            </ConfirmContext.Provider>
            <ConfirmModal
                open={resolveReject.length === 2}
                options={options}
                onClose={handleClose}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
        </React.Fragment>
    );
};

export const useConfirm = () => {
    return useContext(ConfirmContext);
}


const ConfirmModal = ({ open, options, onCancel, onConfirm, onClose }: any) => {
    const {
        title,
        description,
        content,
        confirmationText,
        cancellationText,
        dialogProps,
        confirmationButtonProps,
        cancellationButtonProps,
    } = options;

    return (
        <Dialog fullWidth {...dialogProps} open={open} onClose={onClose}>
            {title && (
                <DialogTitle>{title}</DialogTitle>
            )}
            {content ? (
                <DialogContent>
                    {content}
                </DialogContent>
            ) : (
                description && (
                    <DialogContent>
                        <Typography>{description}</Typography>
                    </DialogContent>
                )
            )}
            <DialogActions>
                <Button color="primary" variant='contained' {...confirmationButtonProps} onClick={onConfirm}>
                    {confirmationText}
                </Button>
                <Button {...cancellationButtonProps} onClick={onCancel}>
                    {cancellationText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal
