import React, { useState, useEffect } from 'react';
import {
	Avatar,
	Box,
	Card,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableContainer,
	Typography,
	Chip,
} from '@mui/material';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { useConfirmationModalContext } from '../providers/ConfirmModalContext';
import { Link } from 'react-router-dom';

const UserList = ({
	climit,
	count,
	cpage,
	csetLimit,
	csetPage,
	customers,
	handleUserDeleted,
	audioRecordingBooks,
	...rest
}: any) => {
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(0);
	const [forceRedraw, setForceRedraw] = useState(false);
	const modalContext = useConfirmationModalContext();
	const handleLimitChange = (event: any) => {
		csetLimit(event.target.value);
		csetPage(0);
	};

	useEffect(() => {
		setLimit(climit);
		setPage(cpage);
	}, [climit, cpage]);

	const getProgression = (id: string) => {
		//filter user audio boo
		if (audioRecordingBooks && audioRecordingBooks.length > 0) {
			let foudList = audioRecordingBooks.filter((x: any) => x.audioRecordingBook.uid === id);
			if (foudList && foudList.length > 0) {
				const book = foudList[0];
				if (book.audioRecordingBook && book.audioRecordingBook?.audioRecordingTexts) {
					let completeCount = 0;
					book.audioRecordingBook?.audioRecordingTexts.forEach((item: any) => {
						if (item.completed) completeCount++;
					});
					return Math.floor(
						(completeCount * 100) / book.audioRecordingBook?.audioRecordingTexts.length
					);
				}
			}
		}

		return 0;
	};

	const handleConfirmModalOpen = async (id: any, name: any) => {
		const result = await modalContext.showConfirmation(
			'Confirmation',

			<p>
				Voulez-vous vraiment supprimer l'utilisateur <strong>{name}</strong> ?
			</p>,

			'Oui',
			'Non'
		);
		if (result) {
			handleUserDeleted(id);
		} else {
		}
	};

	const handlePageChange = (event: any, newPage: any) => {
		csetPage(newPage);
		setForceRedraw(!forceRedraw);
	};
	const getDuration = (sessionId: string) => {
		if (audioRecordingBooks && audioRecordingBooks.length > 0) {
			let foudList = audioRecordingBooks.filter((x: any) => x.audioRecordingBook.uid === sessionId);
			if (foudList && foudList.length > 0) {
				const book = foudList[0];
				if (book.audioRecordingBook && book.audioRecordingBook?.audioRecordingTexts) {
					let totalDuration = 0;
					book.audioRecordingBook?.audioRecordingTexts.forEach((item: any) => {
						if (item.completed) totalDuration += item && item.duration ? item.duration : 0;
					});
					return totalDuration / 60;
				}
			}
		}
		return 0;
	};
	const getInitials = (name = '') =>
		name
			.replace(/\s+/, ' ')
			.split(' ')
			.slice(0, 2)
			.map((v) => v && v[0].toUpperCase())
			.join('');

	const getUserDictationStatus = (status: any) => {
		if (status === 'none') {
			return <Chip sx={{ backgroundColor: 'grey' }} label={'CRÉÉ'} />;
		} else if (status === 'completed') {
			return <Chip color={'secondary'} label={'COMPLÉTÉ'} />;
		} else if (status === 'rejected') {
			return <Chip color={'error'} label={'REJETÉ'} />;
		} else if (status === 'accepted') {
			return <Chip color={'primary'} label={'ACCEPTÉ'} />;
		} else if (status === 'in_progress') {
			return <Chip color={'info'} label={'EN COURS'} />;
		} else {
			return <Chip color={'warning'} label={'VÉRIFIÉ'} />;
		}
	};
	const getQARanking = (value: number) => {
		const starBox = [
			<StarBorderIcon sx={{ fontSize: '18px' }} />,
			<StarBorderIcon sx={{ fontSize: '18px' }} />,
			<StarBorderIcon sx={{ fontSize: '18px' }} />,
			<StarBorderIcon sx={{ fontSize: '18px' }} />,
			<StarBorderIcon sx={{ fontSize: '18px' }} />,
		];
		return (
			<Box>
				{starBox.map((el, idx) =>
					value <= 0 ? (
						el
					) : idx < value ? (
						<StarIcon sx={{ color: '#FFD700', fontSize: '21px' }} />
					) : (
						el
					)
				)}
			</Box>
		);
	};
	return (
		<Card sx={{ backgroundColor: 'white', minHeight: '40rem', width: '100%' }} {...rest}>
			<TableContainer style={{ maxHeight: '100%' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ color: 'black' }}>Nom</TableCell>
							<TableCell sx={{ color: 'black' }}>Courriel</TableCell>
							<TableCell sx={{ color: 'black' }}>Code</TableCell>
							<TableCell sx={{ color: 'black' }}>Location</TableCell>
							{/*<TableCell sx={{ color: 'black' }}>Téléphone</TableCell>*/}

							<TableCell sx={{ color: 'black' }}>Durée(mn)</TableCell>
							<TableCell sx={{ color: 'black' }}>Progression</TableCell>
							<TableCell sx={{ color: 'black' }}>Date d'inscription</TableCell>
							<TableCell sx={{ color: 'black' }}>Dernière Connexion</TableCell>
							<TableCell sx={{ color: 'black' }}>Note QA</TableCell>
							<TableCell sx={{ color: 'black' }}>Status</TableCell>
							<TableCell sx={{ color: 'black' }}>Action(s)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{customers.map((customer: any) => {
							const progression = getProgression(customer.id);
							return (
								<TableRow hover key={customer.id}>
									<TableCell>
										<Box
											sx={{
												alignItems: 'center',
												display: 'flex',
											}}
										>
											<Avatar src={customer.avatarUrl} sx={{ mr: 2 }}>
												{getInitials(customer.name)}
											</Avatar>
											<Link style={{ color: 'black' }} to={`/admin/audio/${customer.id}`}>
												{customer.name}
											</Link>
										</Box>
									</TableCell>
									<TableCell sx={{ color: 'black' }}>{customer.email}</TableCell>
									<TableCell sx={{ color: 'black' }}>{customer.referral_code}</TableCell>
									<TableCell sx={{ color: 'black' }}>
										{customer.province === 'Québec'
											? `${customer.province} / ${customer.region}`
											: `${customer.province}`}
									</TableCell>
									<TableCell sx={{ color: 'black' }}>
										{getDuration(customer.id).toFixed(2)}
									</TableCell>
									<TableCell>
										<Box
											sx={{
												alignItems: 'center',
												display: 'flex',
											}}
										>
											<Box sx={{ width: '100%', mr: 1 }}>
												<LinearProgress value={customer.progression} variant="determinate" />
											</Box>
											<Typography variant="body2" color="black">{`${Math.round(
												customer.progression
											)}%`}</Typography>
										</Box>
									</TableCell>
									<TableCell sx={{ color: 'black' }}>
										{moment(customer.created_at).format('YYYY-MM-DD HH:MM:SS')}
									</TableCell>
									<TableCell sx={{ color: 'black' }}>
										{moment(customer.last_connection).format('YYYY-MM-DD HH:MM:SS')}
									</TableCell>
									<TableCell sx={{ color: 'black' }}>
										{getQARanking(customer.quality_note)}
									</TableCell>
									<TableCell sx={{ color: 'black' }}>
										<Box
											sx={{
												alignItems: 'center',
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											<Box sx={{ width: '100%', mr: 1 }}>
												{getUserDictationStatus(customer.dictation_status)}
											</Box>
										</Box>
									</TableCell>
									<TableCell sx={{ color: 'black' }}>
										<Box
											sx={{
												alignItems: 'center',
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											{customer.dictation_status === 'verified' ||
											customer.dictation_status === 'accepted' ? (
												<></>
											) : (
												<Box sx={{ width: '100%', mr: 1 }}>
													<DeleteIcon
														onClick={() => {
															handleConfirmModalOpen(customer.id, customer.name);
														}}
														sx={{ cursor: 'pointer' }}
														color="error"
													/>
												</Box>
											)}
										</Box>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<TablePagination
					sx={{
						color: 'black',

						'&.MuiTablePagination-root': {
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						},
					}}
					component="div"
					count={count}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={[5, 10, 25]}
				/>
			</TableContainer>
		</Card>
	);
};

export default UserList;
