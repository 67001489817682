import React, { useEffect, useState } from 'react';
import {
	Box,
	Card,
	Grid,
	CardContent,
	CardActions,
	Typography,
	Divider,
	Container,
	Backdrop,
	CircularProgress,
	TextField,
	CardHeader,
	Button,
	Chip,
	MenuItem,
	InputAdornment,
	SvgIcon,
	FormLabel,
	FormControl,
	FormControlLabel,
	FormGroup,
	Checkbox,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
//import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import useRequest from './hooks/useRequest';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CTable from './table/Table';
import { useNotify } from './providers/NotifyProvider';
import DeleteIcon from '@mui/icons-material/Delete';
import CTextEditor from './admin/CTextEditor';
import { createSvgIcon } from '@mui/material/utils';
import NotificationCriteria from './admin/NotificationCriteria';

export const SearchIcon = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		<path
			fillRule="evenodd"
			d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
			clipRule="evenodd"
		/>
	</svg>,
	'Search'
);
const notificationCriteria = [
	{
		value: 'completed',
		label: 'Utilisateur(s) avec le status COMPLÉTÉ',
	},
	{
		value: 'verified',
		label: 'Utilisateur(s) avec le status VÉRIFIÉ',
	},
	{
		value: 'accepted',
		label: 'Utilisateur(s) avec le status ACCEPTÉ',
	},
	{
		value: 'none',
		label: 'Utilisateur(s) avec le status CRÉÉ',
	},
	{
		value: 'in_progress',
		label: 'Utilisateur(s) avec le status EN COURS',
	},
	{
		value: 'custom',
		label: 'Personalisé',
	},
];

const verificationStatus = [
	{
		value: 'all',
		label: 'Tous les status',
	},
	{
		value: 'completed',
		label: 'COMPLÉTÉ',
	},
	{
		value: 'verified',
		label: 'VÉRIFIÉ',
	},
	{
		value: 'rejected',
		label: 'REJETÉ',
	},
	{
		value: 'accepted',
		label: 'ACCEPTÉ',
	},
	{
		value: 'in_progress',
		label: 'EN COURS',
	},
	{
		value: 'none',
		label: 'CRÉÉ',
	},
];

const notifStatus = [
	{
		value: 'enabled',
		label: 'ACTIVÉ',
	},
	{
		value: 'disabled',
		label: 'DÉSACTIVÉ',
	},
];

const daysOfWeek = [
	{
		value: 0,
		label: 'Lundi',
	},
	{
		value: 1,
		label: 'Mardi',
	},
	{
		value: 2,
		label: 'Mercredi',
	},
	{
		value: 3,
		label: 'Jeudi',
	},
	{
		value: 4,
		label: 'Vendredi',
	},
	{
		value: 5,
		label: 'Samedi',
	},
	{
		value: 6,
		label: 'Dimanche',
	},
];

const textFeildSx = {
	'& .MuiInputBase-input.Mui-disabled': {
		WebkitTextFillColor: '#000000',
	},
	'& .MuiInputLabel-root': {
		color: 'grey',

		'&.Mui-disabled': {
			color: 'grey',
			opacity: 0.5,
		},
	},
	/*'& .MuiOutlinedInput': {
			color: 'red',
			'&.Mui-disabled input': {
				backgroudColor: 'red',
				opacity: 0.5,
			},
		},*/
	'& .MuiOutlinedInput-root': {
		color: 'black',
		'&.Mui-focused fieldset': {
			borderColor: '#235A7B',
			color: '#235A7B',
		},
		'&.Mui-disabled fieldset': {
			borderColor: ' #235A7B',
			opacity: 0.5,
		},
		'&.Mui-disabled': {
			color: '#235A7B',
			opacity: 0.5,
		},
		//'&.Mui-disabled:hover': { background: theme.palette.secondary.main },
		'&:hover fieldset': {
			borderColor: '#238A7B',
		},
		svg: { color: ' #238A7B' },
		mb: 2,
	},
};
interface Notification {
	expirationDate: Dayjs | null;
	name: string;
	message: string;
	status: string;
	description?: string;
	sending_days: number[];
}
const CreateNotification = () => {
	const [selectedFilter, setSelectedFilter] = React.useState<any[]>([]);
	const [selectedDataIds, setSelectedDataIds] = useState<any>([]);
	const [statusFilter, setStatusFilter] = useState('all');
	const [userSearchFilter, setUserSearchFilter] = useState('');
	const [openPopover, setOpenPopover] = React.useState(false);
	const [currentRefIndex, setCurrentRefIndex] = React.useState(0);
	const [values, setValues] = React.useState<Notification>({
		expirationDate: dayjs(),
		name: '',
		message: '',
		status: 'disabled',
		description: '',
		sending_days: [],
	});
	const [customizeCriteria, setCustomizeCriteria] = useState(false);
	const [userList, setUserList] = useState([]);
	const [filteredUserList, setFilteredUserList] = useState([]);
	const { doRequest, errors, loading } = useRequest();
	const params = useParams();
	const navigate = useNavigate();
	const notify = useNotify();
	const navigateBackToNotification = () => {
		navigate('/admin/notification');
	};

	const handleRevomeCriteria = (el: any) => {
		try {
			setSelectedFilter([...selectedFilter.filter((x) => x.value !== el.value)]);
		} catch (error) {
			console.log(error);
		}
	};

	const handleCriteriaChange = (value: any) => {
		if (value.label === 'Personalisé' && selectedFilter.indexOf(value) === -1) {
			setCustomizeCriteria(true);
			setSelectedFilter([value]);
		} else if (selectedFilter.indexOf(value) === -1) {
			setCustomizeCriteria(false);
			setSelectedFilter([...selectedFilter.filter((x) => x.label !== 'Personalisé'), value]);
		} else {
			setCustomizeCriteria(false);
			setSelectedFilter(selectedFilter.filter((x) => x.label !== value.label));
		}
	};
	const isNewNotification = () => {
		let isNew = false;

		if (params.notificationId === 'new') {
			isNew = true;
		}

		return isNew;
	};
	const handleChange = (newValue: Dayjs | null) => {
		setValues({ ...values, expirationDate: newValue });
	};

	const handleMessageChanged = (e: any) => {
		setValues({ ...values, message: e.target.value });
	};
	const handleNameChanged = (e: any) => {
		setValues({ ...values, name: e.target.value });
	};
	const handleDescriptionChanged = (e: any) => {
		setValues({ ...values, description: e.target.value });
	};
	const handleNotificationStatusChanged = (e: any) => {
		setValues({ ...values, status: e.target.value });
	};

	const handleStatusFilterChanged = (e: any) => {
		setStatusFilter(e.target.value);
	};
	const handleUserSearchFilterChanged = (e: any) => {
		setUserSearchFilter(e.target.value);
	};

	const handleSaveNotification = async () => {
		if (params.notificationId !== 'new') {
			await doRequest({
				url: `/notification/${params.notificationId}`,
				method: 'put',
				body: {
					...values,
					expirationDate: values.expirationDate?.toISOString(),
					userIds: selectedDataIds,
					criteria: selectedFilter.map((el: any) => el.value),
				},
				onSuccess: (res: any) => {
					if (res) {
						notify.success('Notification modifé avec succès.');
						navigate('/admin/notification');
					} else {
						//notify.error('Erreur lors de la Requête au serveur.');
					}
				},
			});
		} else {
			await doRequest({
				url: `/notification/`,
				method: 'post',
				body: {
					...values,
					expirationDate: values.expirationDate?.toISOString(),
					userIds: selectedDataIds,
					criteria: selectedFilter.map((el: any) => el.value),
				},
				onSuccess: (res: any) => {
					if (res) {
						notify.success('Notification ajouté avec succès.');
						navigate('/admin/notification');
					} else {
						//notify.error('Erreur lors de la Requête au serveur.');
					}
				},
			});
		}
	};
	const getUserList = async () => {
		let filter = '';
		if (statusFilter !== 'all') {
			filter = `&status=${statusFilter}`;
		}
		if (userSearchFilter) {
			filter += `&name=${userSearchFilter}`;
		}
		await doRequest({
			url: `/user?${filter}`,
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					if (filter) setFilteredUserList(res.users);
					else {
						setUserList(res.users);
						setFilteredUserList([]);
					}
					//setUserCount(res.result);
				} else {
					//notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	useEffect(() => {
		getUserList();
	}, [statusFilter, userSearchFilter]);

	useEffect(() => {
		notify.error(errors);
	}, [errors]);

	const getNotificationById = async () => {
		console.log('params.notificationId:', params.notificationId);
		await doRequest({
			url: `/notification/${params.notificationId}`,
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					setValues({
						...res.notifications,
						expirationDate: dayjs(res.notifications.expirationDate),
					});
					setSelectedDataIds(res.notifications.users.map((el: any) => el.id));
					setSelectedFilter(
						notificationCriteria.filter((val) => res.notifications.criteria.some((filter: any) => filter === val.value))
					);
					if (res.notifications.criteria.indexOf('custom') !== -1) {
						setCustomizeCriteria(true);
					}
				} else {
					//notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	useEffect(() => {
		if (!isNewNotification()) {
			getNotificationById();
		}
	}, [params]);

	const getUserNameById = (params: string) => {
		const user = userList.find((x: any) => x.id === params) as any;
		if (user) {
			return user?.name;
		}
		return '';
	};

	const getUserDictationStatus = (status: any) => {
		if (status === 'none') {
			return <Chip sx={{ backgroundColor: 'grey' }} label={'CRÉÉ'} />;
		} else if (status === 'completed') {
			return <Chip color={'secondary'} label={'COMPLÉTÉ'} />;
		} else if (status === 'rejected') {
			return <Chip color={'error'} label={'REJETÉ'} />;
		} else if (status === 'accepted') {
			return <Chip color={'primary'} label={'ACCEPTÉ'} />;
		} else if (status === 'in_progress') {
			return <Chip color={'info'} label={'EN COURS'} />;
		} else {
			return <Chip color={'warning'} label={'VÉRIFIÉ'} />;
		}
	};

	const handleDaysofWeekChange = (e: any) => {
		e.persist();
		console.log(`${e.target.name}: ${e.target.value} :${e.target.checked}`);
		if (e.target.checked) {
			const selectedIndex = selectedDataIds.indexOf(e.target.value);

			if (selectedIndex === -1) {
				setValues({ ...values, sending_days: [...values.sending_days, parseInt(e.target.value)] });
			}
		} else {
			setValues({
				...values,
				sending_days: values.sending_days.filter((pos: number) => pos !== parseInt(e.target.value)),
			});
		}
	};
	return (
		<Box
			component="main"
			sx={{
				backgroundColor: '#F9FAFC',
				flexGrow: 1,
				py: 8,
			}}
		>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
				//onClick={handleClose}
			>
				<Box sx={{ position: 'relative', display: 'inline-flex' }}>
					<CircularProgress variant="determinate" size={30} />
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="caption" component="div" color="text.secondary">
							{'chargement...'}
						</Typography>
					</Box>
				</Box>
			</Backdrop>
			<Container sx={{ mb: 3 }}>
				<Typography sx={{ mb: 3, color: 'black' }} variant="h4">
					{params.notificationId !== 'new' ? 'Modifier la notification' : 'Nouvelle notification'}
				</Typography>
				<Card sx={{ backgroundColor: 'white' }}>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item md={4} xs={12}>
								<Typography variant="h6" sx={{ color: 'black', mb: 1, fontWeight: 500 }}>
									Détails de la notification
								</Typography>
							</Grid>
							<Grid item md={8} xs={12}>
								<TextField
									fullWidth
									//size="small"
									value={values.name}
									sx={textFeildSx}
									label={'Nom'}
									onChange={handleNameChanged}
								/>
								<TextField
									fullWidth
									//size="small"
									value={values.description}
									sx={textFeildSx}
									label={'Description'}
									onChange={handleDescriptionChanged}
								/>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Typography variant="subtitle1" sx={{ color: 'black', mb: 1, fontWeight: 500 }}>
										Message
									</Typography>
									<TextareaAutosize
										value={values.message}
										placeholder="Écrivez votre message ici..."
										style={{ height: 400, padding: 20, overflow: 'auto' }}
										onChange={handleMessageChanged}
									/>
								</Box>
							</Grid>
						</Grid>
					</CardContent>
					<Divider />
				</Card>
			</Container>
			<Container sx={{ mb: 3 }}>
				<Card sx={{ backgroundColor: 'white' }}>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item md={4} xs={12}>
								<Typography variant="h6" sx={{ color: 'black', mb: 1, fontWeight: 500 }}>
									Paramètres d'envoi
								</Typography>
							</Grid>
							<Grid item md={8} xs={12}>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<Stack spacing={3}>
											<DesktopDatePicker
												label="Expire le:"
												inputFormat="YYYY-MM-DD"
												value={values.expirationDate}
												onChange={handleChange}
												/*InputProps={{
												inputProps: {
													'& MuiIconButton-root': {
														color: 'black',
													},
												},
											}}*/
												renderInput={(params: any) => <TextField sx={textFeildSx} {...params} />}
											/>
										</Stack>
									</LocalizationProvider>
									<TextField
										sx={textFeildSx}
										//fullWidth
										label="Status"
										name="notification_status"
										onChange={handleNotificationStatusChanged}
										select
										value={values.status}
										variant="outlined"
									>
										{notifStatus.map((option) => (
											<MenuItem /*sx={MuiItemSx}*/ key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>

									<FormControl component="fieldset">
										<FormLabel sx={{ color: 'black' }} component="legend">
											{'Envoyé tous les : '}
										</FormLabel>
										<FormGroup aria-label="position" row onChange={handleDaysofWeekChange}>
											{daysOfWeek.map((item: any, pos: number) => (
												<FormControlLabel
													sx={{ color: 'grey' }}
													value={item.value}
													name={item.label}
													control={
														<Checkbox
															checked={values.sending_days.indexOf(item.value) !== -1 ? true : false}
															sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, color: 'grey' }}
														/>
													}
													label={item.label}
													labelPlacement="end"
												/>
											))}
										</FormGroup>
									</FormControl>
									<NotificationCriteria
										criteria={notificationCriteria}
										selectedFilter={selectedFilter}
										setSelectedFilter={handleCriteriaChange}
										onRemoveCriteria={handleRevomeCriteria}
									/>
									<Box>
										<Typography sx={{ mt: 2, color: 'black' }} variant={'subtitle2'}>
											{
												"*Note: les notifications sont envoyées les journées indiquées à 18h jusqu'à la date d'expiration."
											}
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Container>
			{customizeCriteria && (
				<Container sx={{ mb: 3 }}>
					<Card sx={{ backgroundColor: 'white' }}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item md={4} xs={12}>
									<Typography variant="h6" sx={{ color: 'black', mb: 1, fontWeight: 500 }}>
										Destinataire(s)
									</Typography>
								</Grid>
								<Grid item md={8} xs={12}>
									<Box
										sx={{
											mb: 2,
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'flex-start',
											justifyContent: 'space-between',
											flexWrap: 'wrap',
										}}
									>
										<TextField
											sx={textFeildSx}
											//fullWidth
											label="Status"
											size="small"
											name="dictation_status"
											onChange={handleStatusFilterChanged}
											select
											value={statusFilter}
											variant="outlined"
										>
											{verificationStatus.map((option) => (
												<MenuItem /*sx={MuiItemSx}*/ key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
										<TextField
											//fullWidth
											value={userSearchFilter}
											size="small"
											sx={{
												'& .MuiOutlinedInput-root': {
													//borderColor: 'white',
													'&.Mui-focused fieldset': {
														borderColor: '#235A7B',
													},
													'&:hover fieldset': {
														borderColor: '#235A7B',
													},
												},
												'& .MuiOutlinedInput-input': {
													color: 'grey',
												},
												'& .MuiSvgIcon-root': {
													color: 'grey',
												},
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SvgIcon color="action" fontSize="small">
															<SearchIcon />
														</SvgIcon>
													</InputAdornment>
												),
											}}
											placeholder="Recherche d'utilisateur..."
											variant="outlined"
											onChange={handleUserSearchFilterChanged}
										/>
									</Box>
									<CTable
										selectedDataIds={selectedDataIds}
										setSelectedDataIds={setSelectedDataIds}
										selectable={true}
										data={filteredUserList.length > 0 ? filteredUserList : userList}
										columns={[
											{ name: 'Nom', field: 'name' },
											/*{ name: 'Prénom' },*/ { name: 'Courriel', field: 'email' },
											{
												name: 'Status',
												field: 'dictation_status',
												renderCell: (params: any, field: any) => {
													return getUserDictationStatus(field);
												},
											},
										]}
									/>
									<Card sx={{ backgroundColor: 'white', mt: 2 }}>
										<CardHeader sx={{ color: 'black' }} title="Envoyé à:" />
										<Divider />
										<CardContent>
											{selectedDataIds.length <= 0 ? (
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<Typography sx={{ color: 'grey' }} variant="subtitle2">
														{'Selectionne un ou plusieurs déstinataire(s).'}
													</Typography>
												</Box>
											) : (
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														flexWrap: 'wrap',
													}}
												>
													{selectedDataIds &&
														selectedDataIds.map((el: string, idx: number) => (
															<Chip
																sx={{
																	'& .MuiChip-deleteIcon': {
																		color: 'grey',
																	},
																	'&:hover': {
																		'& .MuiChip-deleteIcon': {
																			color: 'red',
																		},
																	},
																	display: 'flex',
																	mr: 1,
																	mb: 1,
																}}
																label={
																	<Box
																		sx={{
																			display: 'flex',
																			flexDirection: 'row',
																			alignItems: 'center',
																			justifyContent: 'center',
																		}}
																	>
																		<Typography sx={{ color: 'black' }} variant="body2">
																			{`${getUserNameById(el)}`}
																		</Typography>
																	</Box>
																}
																variant="outlined"
																onDelete={() => {
																	setSelectedDataIds(selectedDataIds.filter((x: string) => x !== el));
																}}
															/>
														))}
												</Box>
											)}
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</CardContent>
						<Divider />
						{/*<CardActions
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							
						</CardActions>*/}
					</Card>
				</Container>
			)}
			<Container>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						gap: '10px',
					}}
				>
					<Button onClick={navigateBackToNotification} variant="outlined">
						Annuler
					</Button>

					<Button onClick={handleSaveNotification} variant="contained" color="primary">
						{params.notificationId !== 'new' ? 'Modifier' : 'Ajouter'}
					</Button>
				</Box>
			</Container>
		</Box>
	);
};

export default CreateNotification;
