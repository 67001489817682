import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ConfirmModalContextProvider from "./components/providers/ConfirmModalContext";

const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <ConfirmModalContextProvider>
      <App />
       </ConfirmModalContextProvider>
    </QueryClientProvider>
  </CookiesProvider>
);

/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  , document.getElementById('root')
);*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
