import React, { useEffect, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useUserDetails } from './providers/UserDetailsProvider';
import request from '../helpers/myAxios';
import FullScreenLoader from './FullScreenLoader';
import { sessionClear } from '../helpers/session';
import useRequest from './hooks/useRequest';
import { useNotify } from './providers/NotifyProvider';
import { AxiosResponse } from 'axios';
const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
	const { userDetails, saveUser } = useUserDetails();
	const [cookies] = useCookies(['logged_in']);
	const navigate = useNavigate();
	const notify = useNotify();
	const { doRequest, errors, loading } = useRequest();
	useEffect(() => {
		notify.error(errors);
	}, [errors]);

	const location = useLocation();

	const getMe = async () => {
		await doRequest({
			url: '/user/me',
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				if (res) saveUser(res.user);
			},
		});
	};

	useEffect(() => {
		if (!userDetails) {
			getMe();
		}
	}, [userDetails]);

	if (loading) {
		return <FullScreenLoader />;
	}

	return (cookies.logged_in || userDetails) &&
		allowedRoles.includes(userDetails?.role as string) ? (
		<Outlet />
	) : cookies.logged_in && userDetails ? (
		<Navigate to="/unauthorized" state={{ from: location }} replace />
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	);
};

export default RequireUser;
