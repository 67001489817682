import React, { useEffect } from 'react';
import {
	Box,
	Backdrop,
	CircularProgress,
	Grid,
	Typography,
	IconButton,
	Container,
	Card,
	CardHeader,
	CardContent,
	Divider,
	List,
	ListItemButton,
	Pagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AxiosResponse, AxiosError } from 'axios';
import request from '../helpers/myAxios';
import { useParams } from 'react-router-dom';
import AccountProfile from './admin/AccountProfile';
import AccountProfileDetails from './admin/AccountProfileDetails';
import { useNotify } from './providers/NotifyProvider';
import { IAudioRecordingBook, IAudioRecordingText } from '../data/IAudioRecording';
import AudioRecordingTextPlayer from '../components/views/TextRecordView/AudioRecordingTextPlayer';
import FolderIcon from '@mui/icons-material/InboxOutlined';
import useRequest from './hooks/useRequest';
import { useConfirmationModalContext } from './providers/ConfirmModalContext';
interface LoadedUserDetails {
	audioRecordingBook: IAudioRecordingBook | null;
}
const UserAudio = () => {
	const params = useParams();
	const notify = useNotify();
	const sessionId = params.sessionId;

	const [loadedUserDetails, setLoadedUserDetails] = React.useState<LoadedUserDetails>({
		audioRecordingBook: null,
	});
	//const [isLoading, setIsLoading] = React.useState(false);
	const [forceReload, setForceReload] = React.useState(false);
	const [pageCount, setPageCount] = React.useState(1);
	const [page, setPage] = React.useState(1);
	const [limit, setLimit] = React.useState(20);
	const modalContext = useConfirmationModalContext();
	const { doRequest, errors, loading } = useRequest();
	const handleChange = (event: any, value: any) => {
		setPage(value);
	};

	React.useEffect(() => {
		let completedAudio: IAudioRecordingText[] = [];
		if (
			loadedUserDetails.audioRecordingBook != null &&
			loadedUserDetails.audioRecordingBook.audioRecordingTexts.length > 0
		) {
			completedAudio = loadedUserDetails.audioRecordingBook.audioRecordingTexts.filter(
				(el) => el.completed === true
			);
		}

		setPageCount(Math.ceil(completedAudio.length / limit));
	}, [loadedUserDetails]);

	useEffect(() => {
		if (errors) notify.error(errors);
	}, [errors]);

	const handleLoadUserInformattions = async () => {
		await doRequest({
			url: `/user/${sessionId}`,
			method: 'get',
			body: null,
			onSuccess: (res: any) => {
				if (res) {
					setLoadedUserDetails({
						...res.user,
						audioRecordingBook: res.audioRecordingBook,
					});
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};

	const handleDeleteAudio = async (fileName: string) => {
		const result = await modalContext.showConfirmation(
			'Confirmation',

			<p>
				Voulez-vous vraiment supprimer l'enregistrement: <strong>{fileName}</strong> ?
			</p>,

			'Oui',
			'Non'
		);
		if (result) {
			await doRequest({
				url: `/audiorecording/file/${sessionId}/${fileName}`,
				method: 'delete',
				onSuccess: (res: any) => {
					if (res) {
						setLoadedUserDetails({
							...loadedUserDetails,
							audioRecordingBook: res.audioRecordingBook,
						});
					} else {
						notify.error('Erreur lors de la Requête au serveur.');
					}
				},
			});
		}
	};

	const handleUserInformattionsChange = async (userInfo: any) => {
		await doRequest({
			url: `/user/${sessionId}`,
			method: 'put',
			body: userInfo,
			onSuccess: (res: any) => {
				if (res) {
					setLoadedUserDetails({
						...res.user,
					});
				} else {
					notify.error('Erreur lors de la Requête au serveur.');
				}
			},
		});
	};
	const handleUserDetailsChange = (name: any, value: any) => {
		setLoadedUserDetails({ ...loadedUserDetails, [name]: value });
	};
	React.useEffect(() => {
		handleLoadUserInformattions();
	}, [sessionId, forceReload]);
	let completedAudio: IAudioRecordingText[] = [];
	if (
		loadedUserDetails.audioRecordingBook != null &&
		loadedUserDetails.audioRecordingBook.audioRecordingTexts.length > 0
	) {
		completedAudio = loadedUserDetails.audioRecordingBook.audioRecordingTexts.filter(
			(el) => el.completed === true
		);
	}
	return (
		<Box
			component="main"
			sx={{
				backgroundColor: '#F9FAFC',
				flexGrow: 1,
				py: 8,
			}}
		>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
				//onClick={handleClose}
			>
				<Box sx={{ position: 'relative', display: 'inline-flex' }}>
					<CircularProgress variant="determinate" size={30} />
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="caption" component="div" color="text.secondary">
							{'chargement...'}
						</Typography>
					</Box>
				</Box>
			</Backdrop>
			<Container maxWidth={false} sx={{ mb: 3 }}>
				<Typography color="black" sx={{ mb: 3 }} variant="h4">
					Profile
				</Typography>
				<Grid container spacing={3}>
					<Grid item lg={4} md={6} xs={12}>
						<AccountProfile user={loadedUserDetails} />
					</Grid>
					<Grid item lg={8} md={6} xs={12}>
						<AccountProfileDetails
							setUserDetails={handleUserDetailsChange}
							user={loadedUserDetails}
							handleUserInfoChange={handleUserInformattionsChange}
						/>
					</Grid>
				</Grid>
			</Container>

			<Container maxWidth={false}>
				<Card
					sx={{
						backgroundColor: 'white',
					}}
				>
					<CardHeader
						sx={{
							color: 'black',
							'& .MuiCardHeader-subheader': {
								color: 'grey',
							},
						}}
						subheader="Ce tableau affiche seulement les enregistrements complétés"
						title="Liste des enregistrements à vérifier"
					/>
					<Divider />
					<CardContent
						sx={{
							width: '100%',
							minHeight: '30rem',
							maxHeight: '40rem',
							overflow: 'auto',
						}}
					>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								width: '100%',
								height: '100%',
							}}
						>
							{completedAudio && completedAudio.length > 0 ? (
								<List sx={{ width: '100%' }}>
									{loadedUserDetails &&
										completedAudio.slice((page - 1) * limit, page * limit).map((data, idx) => {
											return (
												<ListItemButton sx={{ width: '100%' }} key={data.audioFilename} dense>
													<Box
														sx={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
															width: '100%',
															mr: 1,
														}}
													>
														<AudioRecordingTextPlayer
															disablePlaying={false}
															recordingSessionId={sessionId}
															isSelected={false}
															data={data}
															showText={true}
															idx={idx}
															onPlay={() => {}}
															onStop={() => {}}
														/>
														<IconButton
															onClick={() => {
																handleDeleteAudio(data.audioFilename);
															}}
															disabled={data.deleted ? true : false}
														>
															<DeleteIcon
																color={'error'}
																sx={{ color: data.deleted ? 'grey' : '' }}
															/>
														</IconButton>
													</Box>
												</ListItemButton>
											);
										})}
								</List>
							) : (
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										minHeight: '25rem',
										maxHeight: '35rem',
									}}
								>
									<FolderIcon sx={{ color: 'grey', fontSize: '50px' }} />
									<Typography color="grey" variant="h4">
										Dossier vide
									</Typography>
									<Typography color="grey" gutterBottom variant="body2">
										{'Aucune donnée à afficher pour le moment'}
									</Typography>
								</Box>
							)}
						</Box>
					</CardContent>
				</Card>
				{completedAudio.length > 0 && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							pt: 3,
						}}
					>
						<Pagination
							sx={{
								'& ul > li:first-child > button': {
									color: 'black',
								},
								'& ul > li:last-child > button': {
									color: 'black',
								},
								'& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)': {
									backgroundColor: 'transparent',
									color: 'grey',
								},
							}}
							color="primary"
							count={pageCount}
							page={page}
							onChange={handleChange}
						/>
					</Box>
				)}
			</Container>
		</Box>
	);
};

export default UserAudio;
