import React, { Dispatch, SetStateAction } from 'react';
import { IAudioRecordingBook, IAudioRecordingText } from '../../data/IAudioRecording';

interface AudioRecordingSession {
	audioRecordingBook: IAudioRecordingBook | null;
	currentPosition: number;
}

type AudioRecordingSessionContextProvider = {
	audioRecordingSession: AudioRecordingSession;
	setAudioRecordingSession: Dispatch<SetStateAction<AudioRecordingSession>>;
	getProgression: () => number;
	getCurrentAudioRecordingText: () => IAudioRecordingText | null;
	getCurrentAudioRecordingTextContent: () => string;
	nextAudioRecordingText: () => void;
	previousAudioRecordingText: () => void;
	getTotalAudioDuration: () => number;
	getTotalAudioDurationText: () => string;
	resetRecordingSession: () => void;
};

const defaultValue: AudioRecordingSessionContextProvider = {
	audioRecordingSession: { audioRecordingBook: null, currentPosition: 0 },
	setAudioRecordingSession: () => { },
	getProgression: () => 0,
	getCurrentAudioRecordingText: () => null,
	getCurrentAudioRecordingTextContent: () => '',
	nextAudioRecordingText: () => { },
	previousAudioRecordingText: () => { },
	getTotalAudioDuration: () => 0,
	getTotalAudioDurationText: () => '',
	resetRecordingSession: () => { },
};

const AudioRecordingSessionContext =
	React.createContext<AudioRecordingSessionContextProvider>(defaultValue);

export const AudioRecordingBookProvider = (props: any) => {
	const [audioRecordingSession, setAudioRecordingSession] = React.useState<AudioRecordingSession>({
		audioRecordingBook: null,
		currentPosition: 0,
	});

	const resetRecordingSession = () => {
		setAudioRecordingSession({
			audioRecordingBook: null,
			currentPosition: 0,
		});
	};

	const getProgression = () => {
		if (audioRecordingSession.audioRecordingBook?.audioRecordingTexts) {
			let completeCount = 0;
			audioRecordingSession.audioRecordingBook?.audioRecordingTexts.forEach((item) => {
				if (item.completed) completeCount++;
			});
			return Math.floor(
				(completeCount * 100) / audioRecordingSession.audioRecordingBook?.audioRecordingTexts.length
			);
		}
		return 0;
	};

	//return total duration in minutes
	const getTotalAudioDuration = () => {
		if (audioRecordingSession.audioRecordingBook?.audioRecordingTexts) {
			let totalDuration = 0;
			audioRecordingSession.audioRecordingBook?.audioRecordingTexts.forEach((item) => {
				if (item.completed) totalDuration += item && item.duration ? item.duration : 0;
			});
			return totalDuration / 60;
		}
		return 0;
	};

	const getTotalAudioDurationText = () => {
		if (audioRecordingSession.audioRecordingBook?.audioRecordingTexts) {
			let totalDuration = 0;
			audioRecordingSession.audioRecordingBook?.audioRecordingTexts.forEach((item) => {
				if (item.completed) totalDuration += item && item.duration ? item.duration : 0;
			});
			return Math.floor(totalDuration / 60).toString().padStart(2, '0') + ':' + Math.floor(totalDuration % 60).toString().padStart(2, '0');
		}
		return '00:00';
	};

	const getCurrentAudioRecordingText = (): IAudioRecordingText | null => {
		if (
			audioRecordingSession.audioRecordingBook &&
			audioRecordingSession.audioRecordingBook.audioRecordingTexts.length >
			audioRecordingSession.currentPosition
		) {
			return audioRecordingSession.audioRecordingBook.audioRecordingTexts[
				audioRecordingSession.currentPosition
			];
		}
		return null;
	};

	const getCurrentAudioRecordingTextContent = (): string => {
		if (
			audioRecordingSession.audioRecordingBook &&
			audioRecordingSession.audioRecordingBook.audioRecordingTexts.length >
			audioRecordingSession.currentPosition
		) {
			let text =
				audioRecordingSession.audioRecordingBook.audioRecordingTexts[
					audioRecordingSession.currentPosition
				].text;
			audioRecordingSession.audioRecordingBook.textReplacements.forEach((replacement) => {
				text = text.replaceAll(
					replacement.oldText,
					'<strong>' + replacement.newText.toUpperCase() + '</strong>'
				);
			});
			return text;
		}
		return '';
	};

	const nextAudioRecordingText = () => {
		setAudioRecordingSession((oldValue) => {
			return {
				...oldValue,
				currentPosition: oldValue.currentPosition + 1,
			};
		});
	};

	const previousAudioRecordingText = () => {
		setAudioRecordingSession((oldValue) => {
			return {
				...oldValue,
				currentPosition: oldValue.currentPosition - 1,
			};
		});
	};

	return (
		<AudioRecordingSessionContext.Provider
			value={{
				audioRecordingSession,
				setAudioRecordingSession,
				getProgression,
				getCurrentAudioRecordingText,
				getCurrentAudioRecordingTextContent,
				nextAudioRecordingText,
				previousAudioRecordingText,
				getTotalAudioDuration,
				getTotalAudioDurationText,
				resetRecordingSession,
			}}
		>
			{props.children}
		</AudioRecordingSessionContext.Provider>
	);
};

export const useAudioRecordingSession = () => {
	return React.useContext(AudioRecordingSessionContext);
};
