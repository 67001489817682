import { useEffect, useState } from "react";

interface IPlayerState {
    playing: boolean,
    url: string,
    audio: HTMLAudioElement | null,
    audioPosition: number | null,
    audioDuration: number | null,
}

const usePlayer = () => {
    const [state, setState] = useState<IPlayerState>({ playing: false, url: '', audio: null, audioPosition: null, audioDuration: null });
    const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>();

    const _startTimer: () => void = () => {
        const interval = setInterval(() => {
            setState((oldState) => { return { ...oldState, audioPosition: getCurrentTime(), audioDuration: getDuration() } });
        }, 200);
        setTimerInterval(interval);
    };

    const _stopTimer: () => void = () => {
        if (timerInterval)
            clearInterval(timerInterval);
    };

    const playPause = () => {
        setState((oldState) => {
            return { ...oldState, playing: !state.playing }
        });
    }

    const getDuration = (): number | null => {
        if (state?.audio?.duration && state.audio.duration !== NaN && state.audio.duration !== Infinity)
            return state.audio.duration;
        return null;
    }

    const getCurrentTime = (): number | null => {
        if (state?.audio?.currentTime && state.audio.currentTime !== NaN && state.audio.currentTime !== Infinity)
            return state.audio.currentTime;
        return null;
    }

    const setUrl = (url: string) => {
        setState(oldState => {
            let newAudio = oldState.audio;
            if (url) {
                if (!newAudio)
                    newAudio = new Audio(url);
                else
                    newAudio.src = url;
            }
            else {
                if (newAudio)
                    newAudio.pause();
                newAudio = null;
            }
            return { ...oldState, audio: newAudio, playing: url ? true : false, url }
        });
    }

    useEffect(() => {
        if (state.audio) {
            state.playing ? state.audio.play() : state.audio.pause();

            if (state.playing)
                _startTimer();
            else
                _stopTimer();
        }
        return () => {
            if (state.audio)
                state.audio.pause();

            _stopTimer();
        }
    }, [state.playing, state.audio]);

    useEffect(() => {
        if (state.audio) {
            const audio = state.audio;
            audio.addEventListener('ended', () => setState((oldState) => { return { ...oldState, playing: false, audioPosition: getCurrentTime(), audioDuration: getDuration() } }));
            return () => {
                audio.removeEventListener('ended', () => setState((oldState) => { return { ...oldState, playing: false, audioPosition: getCurrentTime(), audioDuration: getDuration() } }));
            };
        }
    }, [state.audio]);

    return { canPlay: state.audio ? true : false, playing: state.playing, playPause, audioPosition: state.audioPosition, audioDuration: state.audioDuration, setUrl, url: state.url };
};

export default usePlayer;