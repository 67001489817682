import axios from 'axios';
import config from '../config';

var instance = axios.create({
	baseURL: config.api.URL,
	timeout: 10000,
	withCredentials: true,
});

instance.interceptors.request.use(
	(config) => {
		if (config && config.headers !== undefined) {
			const token = localStorage.getItem('token');
			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
				//config.headers['x-access-token'] = token; // for Node.js Express back-end
			}
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config;

		if (originalConfig && originalConfig.url !== '/auth/login' && err.response) {
			// Access Token was expired
			if (err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;

				try {
					const rs = await instance.get('/auth/refreshtoken');

					const { access_token } = rs.data;
					localStorage.removeItem('token');
					localStorage.setItem('token', access_token);

					return instance(originalConfig);
				} catch (_error) {
					return Promise.reject(_error);
				}
			}
		}

		return Promise.reject(err);
	}
);

const request = () => {
	return instance;
};

export default request;
