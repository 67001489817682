import React from 'react';
import { Box, Typography } from '@mui/material';
const Slider = (props: any) => {
	return (
		<Box
			sx={{
				overflow: 'hidden',
				height: 'auto',
				backgroundColor: '#EDEAE6',
				mt: 2,
				border: '2px solid #EDEAE6',
				padding: ' 0.2em 0',
				fontSize: '1.5em',
				fontFamily: 'Baskerville',
			}}
		>
			<Typography
				sx={{
					margin: 0,
					animation: 'move 30s linear infinite',
					animationDelay: '1s',
					'@keyframes move': {
						from: { marginLeft: '100%', width: ' 100%' },
						to: { marginLeft: ' -50%', width: '100%' },
					},
					color: 'black'
				}}
				variant="subtitle2"
			>
				{props.text}
			</Typography>
		</Box>
	);
};

export default Slider;
