import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons/lib';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Button } from '../globalStyles';
import { useMediaQuery, useTheme } from '@mui/material';
import {
	Nav,
	NavbarContainer,
	NavLogo,
	//NavIcon,
	NavImage,
	MobileIcon,
	NavMenu,
	NavItem,
	NavItemBtn,
	NavItema,
	NavBtnLink,
} from './Navbar.element';
import logo from '../../../images/logo_plus.png';
const Navbar = () => {
	const [click, setClick] = useState(false);
	const [button, setButton] = useState(true);
	const theme = useTheme();
	const isMatch = useMediaQuery(theme.breakpoints.down('md'));
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);
	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		} else {
			setButton(true);
		}
	};

	useEffect(() => {
		showButton();
	}, []);

	window.addEventListener('resize', showButton);
	return (
		<IconContext.Provider value={{ color: '#fff' }}>
			<Nav>
				<NavbarContainer>
					<NavLogo to="/" onClick={closeMobileMenu}>
						<NavImage src={logo} />
						{isMatch ? 'Concours - Imagem' : 'Dicte et cours la chance de gagner jusqu’à 1 000 $ !'}
					</NavLogo>
					<MobileIcon onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</MobileIcon>
					<NavMenu onClick={handleClick} click={click}>
						<NavItem>
							<NavItema
								target="_blank"
								href="https://www.imagemsoft.com/logiciels/"
								onClick={closeMobileMenu}
							>
								Nos produits
							</NavItema>
						</NavItem>
						<NavItemBtn>
							{button ? (
								<NavBtnLink to="/login">
									<Button>Connexion</Button>
								</NavBtnLink>
							) : (
								<NavBtnLink to="/login">
									<Button /*onClick={closeMobileMenu}*/>Connexion</Button>
								</NavBtnLink>
							)}
						</NavItemBtn>
					</NavMenu>
				</NavbarContainer>
			</Nav>
		</IconContext.Provider>
	);
};

export default Navbar;
