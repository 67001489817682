import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import request from '../../helpers/myAxios';
import { useNotify } from '../providers/NotifyProvider';
const ResetPassword = () => {
	const { resetToken } = useParams<{ resetToken: string }>();
	const navigate = useNavigate();
	const notify = useNotify();
	const [isLoading, setIsloading] = useState(false);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const handleRessetPassword = () => {
		try {
			setIsloading(true);
			const req = request();
			if (password === '' && password !== confirmPassword) return;
			req
				.patch(`auth/resetpassword/${resetToken!}`, { password: password })
				.then((res: AxiosResponse) => {
					if (res.status === 200) {
						navigate('/login');
						notify.success('Mot de passe modifié avec succès.');
						setIsloading(false);
						//setIsSuccess(true);
					}
				})
				.catch((err: any) => {
					if (err.response.status === 403) {
						notify.error('Votre lien a expiré.');
					} else {
						notify.error('Impossible de modifier votre mot de passe. Veuillez réessayer plustard.');
						setIsloading(false);
					}
				});
		} catch (error) { }
	};
	return (
		<Container
			maxWidth={false}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				//backgroundImage: `url(${background})`,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Typography
					component="h2"
					sx={{
						fontSize: 20,
						width: '100%',
						textAlign: 'center',
						mb: '1rem',
						color: 'white',
					}}
				>
					Modifier votre mot de passe
				</Typography>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					maxWidth="27rem"
					width="100%"
					sx={{
						backgroundColor: '#235A7B',
						p: { xs: '1rem', sm: '2rem' },
						borderRadius: 2,
					}}
				>
					<TextField
						sx={{ mb: 5 }}
						id="pass"
						label={'Nouveau mot de passe'}
						type="password"
						size="small"
						fullWidth
						required
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<TextField
						sx={{ mb: 5 }}
						id="confirm_pass"
						label={'Confirmer le mot de passe'}
						type="password"
						size="small"
						fullWidth
						required
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					/>
					<Button onClick={handleRessetPassword} fullWidth variant="contained" color="primary">
						{'Modifier le mot de passe'}
					</Button>
				</Box>
			</Box>
		</Container>
	);
};

export default ResetPassword;
