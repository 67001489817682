import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import unAutorized from '../../images/20943844.png';
import { useNavigate } from 'react-router-dom';
const UnAuthorized = () => {
	const navigate = useNavigate();
	return (
		<Box
			component="main"
			sx={{
				backgroundColor: 'white',
				display: 'flex',
				alignItems: 'center',
				flexGrow: 1,
				minHeight: '100%',
			}}
		>
			<Container maxWidth="md">
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography variant="h2" component="h1" sx={{ color: '#21A651', fontWeight: 500 }}>
						401 | Accès limité
					</Typography>
					<Box sx={{ textAlign: 'center' }}>
						<img
							src={unAutorized}
							style={{
								marginTop: 50,
								display: 'inline-block',
								maxWidth: '100%',
								width: 560,
							}}
							alt="vectorjuice"
						/>
					</Box>
					<Button
						component="a"
						startIcon={<ArrowBackIcon fontSize="small" />}
						sx={{ mt: 3 }}
						variant="contained"
						onClick={() => {
							navigate('/textrecorder');
						}}
					>
						Retour en arrière
					</Button>
				</Box>
			</Container>
		</Box>
	);
};

export default UnAuthorized;
